import React,{useState,useEffect} from 'react';
import * as utils from '../../functions/util.js';
import { withRouter } from "react-router";
const ChannelTile = (props) => {
  console.log(props)
    const { classes } = props;

  const [sub, setSub] = useState(props.subscribed);

  const subscribeChnanel = ()=>{
    utils.subscribe(props.id, props.title, props.description, props.icon)
  }

  const unsubscribeChnanel = (id)=>{
    utils.unsubscribe(props.id)
  }

 const toggleSub =() =>{
    setSub(!sub)
  }

 const subHandle = ()=>{

    !sub? subscribeChnanel():unsubscribeChnanel()
    toggleSub();
  }
  const handleClick=()=>{

   props.history.push(`/Channel?id=${props.id}`);
  }
  useEffect(() => {

   }, []);

    return (
        <div class="channel-container" id={props.id} >
          <div class="channel-thumbnail" onClick={handleClick}>
            <img style={{width: '124px',height: '124px', display: 'block' }} src={props.icon}/>
          </div>
          <div class="channel-text" onClick={handleClick}>
            <h3 class="channel-title">{props.title}</h3>
            {props.showInfo &&
              <h5>{props.videos} Videos • {props.playlist} Playlists • {props.subscribers} Subscribers • Updated&nbsp;{props.updated}</h5>
            }
            <p>{props.description}</p>
          </div>
          <div class="channel-button">
           {props.showButton && <button class="flex-item" onClick={subHandle}>{sub?'Unsubscribe':'Subscribe'}</button>}
          </div>
        </div>
    )
}

export default withRouter(ChannelTile);