import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { Cache } from 'aws-amplify';
import './Channel.css';
import Wrap from '../../functions/Wrap.js';
import * as utils from '../../functions/util.js';
import getUserProfile from '../../functions/getUserProfile.js';
import { Grid} from '@material-ui/core';


import { refresh } from '../../action/refresh'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import compose from 'recompose/compose';
import Avatar from '@material-ui/core/Avatar'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//col channel-no-padding m-0

const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
const mapStateToProps = state => ({
  store: state.store
})


const mapDispatchToProps = dispatch => bindActionCreators({

  refresh: () => dispatch(refresh),
}, dispatch)
const styles = {
  on: {
    color: 'none',
    cursor: 'pointer'
  },
  off: {
    color: '#E20074',
    cursor: 'pointer'
  }
}


function ChannelHeader(props) {
 console.log(props)
  const { classes } = props;
 const [owner, setOwner] = React.useState({});
  const [sub, setSub] = useState(false);



  const subscribeChnanel = () => {
    utils.subscribe(props.id, props.title, props.description, props.icon).then(function() {

    });

  }

  const unsubscribeChnanel = (id) => {
    utils.unsubscribe(props.id).then(function() {

    });
  }

  const toggleSub = () => {
    setSub(!sub)
  }

  const subHandle = () => {
    sub ? subscribeChnanel() : unsubscribeChnanel()
    toggleSub();
  }

  useEffect(() => {
    const userInfo = Cache.getItem('userInfo')
    const myID = userInfo.id
    console.log('channel header')
    console.log(myID)
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
    utils.getUserChannel(myID).then(function(result) {
      let data = result.data.searchUsers.items[0].subscriptions.items
      console.log(props.id)
      //console.log(data)
      data = data.filter(item=> item.channel!=null)
      //console.log(data)
      data = data.filter(function(item) {
        return (item.channel.id == props.id);
      });
       console.log(data)
      data.length > 0 ? setSub(false) : setSub(true);
    });
    /*
    getUserProfile().then(function(result) {
      let data = result.data.getUserProfile.subscriptions.items
      console.log(props.id)
      console.log(data)
      data = data.filter(function(item) {
        return (item.channelId == props.id);
      });
       console.log(data)
      data.length > 0 ? setSub(false) : setSub(true);
      
    });
    */
  
    utils.getUserDetailByID2(props.author).then(function(result) {
      console.log( result)
      let user = result.data.searchUsers.items[0]
     let obj ={};
    // obj.name = user.givenName + ' ' + user.familyName
     //obj.photo = user.profilePhotoURL
     //obj.email = user.email
    // obj.name = user.givenName + ' ' + user.familyName
     //obj.photo = user.profilePhotoURL
     //obj.email = user.email
     console.log(obj)
     setOwner(obj)
    });
    //console.log(props)
  }, [props.id]);


  return (
    <div style={{width: '100%'}}>
      <div
      class="m-0"
      style={{
        backgroundImage: 'url('+props.image +')',
        height:'267px', width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}>
        
        
        <div class="channel-banner-footer p-2">
          <div class="d-flex flex-row flex-wrap">
            <div class="pl-4 align-self-center">
              <h1 class="SprintSansWeb-Light drop-shadow">{props.title}</h1>
            </div>
            <div class="pl-4 align-self-center">

           {props.showButton &&   <button class="channel-subscribe-button drop-shadow" onClick={()=>{subHandle()}} >{!sub?'Unsubscribe':'Subscribe'}</button>}
            </div>
            <div class="pl-4 align-self-center">
              <span>Created {utils.formatDate(props.createdAt)}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row align-items-end flex-wrap flex-md-nowrap"
        style={{
          borderBottom: 'solid 6px #E20074',
          backgroundColor: '#373737',
          width: '100%',
        }}>
        <div class="py-4 pl-4">
       
          <img     src={`https://${server[2]}/fit-in/220x124/public/channel/${props.id}/icon.png`}  />
         
        </div>
        
        
        <div class="pb-4 pl-4">
          <p class="channel-description">{props.description}</p>
          <p class="channel-details">
             {/* {props.videos.length>0 && <span>{props.videos.items.length} Videos</span>}*/}
              {props.playlist && <span> {props.playlist.items.length} Playlist</span>}
              <span>{props.subscribers || 0} Subscribers</span>
              <span>Last update {utils.formatDate(props.createdAt)}</span></p>
              <Grid container alignContent="center" alignItems ='center' direction='row' style={{marginTop:10}} ><Avatar alt="" src={`${process.env.REACT_APP_CLOUDFRONT_URL}${owner.photo}`} />&nbsp;{owner.name} &nbsp;<a href={`mailto:${owner.email}`}><MailOutlineIcon/></a>
              </Grid>
        </div>
        {/*
         <div class="py-4 pl-4">
          <img class="channel-icon" src={props.icon} />{props.author}
        </div>
        */}
      </div>
    </div>
  )
}

//export default  withStyles(styles)(ChannelHeader);

export default compose(
  withStyles(styles, {
    name: 'ChannelHeader',
  }),
  connect(null, mapDispatchToProps),
)(ChannelHeader);
