/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import VideoCard from '../parts/VideoCard.js';
import PropTypes from 'prop-types';
import EditVideoitem from '../parts/videopage/EditVideoitem.js';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Age from '../functions/Age.js';
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as utils from '../functions/util.js';
import RemoveIcon from '@material-ui/icons/Remove';
import queryString from 'query-string'
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SortDateAlpha from '../parts/widgets/SortDateAlpha.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';
const CssTextField = withStyles({
  root: {
    heigth: 33,
    width: 300,

    padding: '10px 20px 10px 10px',
    '& .MuiInputBase-input': {
      fontColor: '#000',
    },
    '& label.Mui-focused': {
      //color: 'green',
    },
    '& .MuiInput-underline:after': {
      // borderBottomColor: 'green',
    },
    '& .MuiInputLabel-outlined': {
      // borderBottomColor: 'green',
      fontColor: "#000",
      paddingLeft: 5
    },
    '& .MuiOutlinedInput-input': {
      // borderBottomColor: 'green',
      fontColor: "#000",
      padding: 10,
      paddingLeft: 5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontColor: "#000",
        borderRadius: 4,
        borderColor: '#E20076',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      },
      '&:hover fieldset': {
        borderColor: '#E20076',
        // backgroundColor: '#000'
        //borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        color: '#ccc',
        borderColor: '#E20076',
        fontColor: "#000",
        //backgroundColor: '#000'
        //borderColor: 'green',
      },
    },
  },
})(TextField);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));

const styles = {
  form: {
    margin: 30,
    color: '#ffce0a'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
    // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 300,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
async function fetchData(searchQuery, paramKey, param) {
  const searchResult = await API.graphql(
    graphqlOperation(searchQuery, { paramKey: param })
  );
  return searchResult;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function authorVideos(props) {
  // console.log(props)
  const [loadCount, setLoadCount] = React.useState(0);
  const userInfo = Cache.getItem('userInfo')
  const myID = userInfo.id
  let author = []
  const [value, setValue] = React.useState(0);
  const [Videos, setVideos] = useState([]);
  const [processingVideos, setProcessingVideos] = useState([]);
  const [archiveVideos, setArchiveVideos] = useState([]);
  const [originailArray, setOriginailArray] = useState([]);
  const [finishLoading, setFinish] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [vidID, setVidID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [authors, setAuthors] = useState(myID);
  const [authorList, setAuthorList] = useState([]);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [showActive, setShowActive] = React.useState(true);
  const [associatedList, setAssociatedList] = useState({playlist:[],channel:[]});
  let data, func


  const copyVideoID = (id) => {
    console.log('copy ' + id)
    console.log()
    navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(content)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }

  const removeVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id, associatedList.playlist,  associatedList.channel, 'DELETE').then(result => {
        setOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }


  const removeVideoDiaglog = (id) => {
   
    utils.checkMediaPlaylistChannel(id).then(result => {
      setOpen(true);
      console.log(result)
      let res= JSON.parse(result.data.checkForVideoInPlaylistChannel);
      console.log(res)
      setAssociatedList(res)
    })
    .catch(err => console.log(err));
    setVidID(id)
  }
  const archiveDialog = (id) => {
    setArchiveOpen(true);
    setVidID(id)
  }
  const archiveVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id,associatedList.playlist, associatedList.channel, "ARCHIVE").then(result => {
        setArchiveOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }
  const restoreVideo = (id) => {
    console.log(id)
    utils.RestoreVideoByID(id).then(result => {

        setVideos(Videos.filter(video => video.id != id)); // remove from display

      })
      .catch(err => console.log(err));
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAuthorChange = (event, newValue) => {
    if (event.target.value != 'All') {
      setAuthors(event.target.value);
      filterVideoByAuthor(event.target.value)
    }
    else {
      setVideos(originailArray)
    }
  };

  const filterVideoByAuthor = (val) => {
    console.log(originailArray)
    let result = originailArray.filter(v => v.primaryAuthorID == val);
    setVideos(result)
    setRefresh(!refresh)
  }

  const sortAlpha = (arr) => {
    arr.sort((a, b) => a.video_title.localeCompare(b.video_title))
    setVideos(arr)
    setRefresh(!refresh)
  }

  const sortDate = (arr) => {
    arr.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
    //  console.log(Videos)
    setVideos(arr)
    setRefresh(!refresh)
  }

  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleEdit = (id) => {
    props.history.push('/editVideo?v=' + id)
  }

  const handleSearch = (keyword) => {
    let filter = 'video_title'
    console.log(originailArray)
    let newVid = originailArray.filter(function(obj) {
      return obj[filter].toLowerCase().includes(keyword.toLowerCase());
    });
    //console.log(newVid)
    setVideos(newVid)
  }
  const handleShowActive = () => {
    //setAnchorEl(null);
    setShowActive(!showActive)
  };
  
  const handleClose = () => {
    //setAnchorEl(null);
    setOpen(false)
  };
  const handleArchiveDialogClose = () => {
    setArchiveOpen(false);
  };
  const addVideo = () => {
    props.history.push('/editVideo')
  }
  const getVideos = () => {

    setVideos([])
    setOriginailArray([])
    setFinish(false)
    let tmp = []
    utils.searchVideoByOwner().then(function(result) {
      console.log(result)
      let VideosArray = [];


      data = result.data.searchVideos.items
       console.log(data)
      let playlistItem = data

      for (var value of playlistItem) {

        var obj = {};
        obj.id = value.id;
        obj.audience = value.audience
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.updatedAt = value.updatedAt;
        obj.poster_image_loc = value.thumbnail
        obj.videoURL = value.videoURL
        obj.allowTrainerDownload = value.allowTrainerDownload
        // console.log(utils.formatDuration(value.videoDuration))
        obj.duration = utils.formatDuration(value.videoDuration)
        obj.views = value.videoViews
        obj.expirationDate = value.expirationDate;
        obj.captions = value.captions
        obj.video = value.videoURL
        obj.videoState = value.videoState
        VideosArray.push(obj)
      }
      setOriginailArray(VideosArray)
      tmp = VideosArray
      VideosArray = VideosArray.filter(v => v.videoState == "ACTIVE");
      console.log(tmp.filter(v => v.videoState != "ACTIVE" && v.videoState != "ARCHIVED" && v.videoState != "undefined"))
      setProcessingVideos(tmp.filter(v => v.videoState != "ACTIVE" && v.videoState != "ARCHIVED" && v.videoState != "undefined"))
      setArchiveVideos(tmp.filter(v => v.videoState == "ARCHIVED"))
      sortDate(VideosArray)
      setFinish(true)
      setLoadCount(loadCount + 1)

      setRefresh(!refresh)
    });
  }
  const getSecondaryVideo = () => {
    setVideos([])
    setOriginailArray([])
    setFinish(false)
    utils.searchVideoBySecondaryAuthor().then(function(result) {
       console.log(result)
      let VideosArray = [];

      let numloaded = 0;
      data = result.data.searchVideos.items
      // console.log(data)
      let playlistItem = data

      for (var value of playlistItem) {
        console.log(value)
        var obj = {};
        obj.id = value.id;
        obj.audience = value.audience
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.updatedAt = value.updatedAt || '2019-12-13T18:43:18.419Z';
        obj.poster_image_loc = value.thumbnail
        obj.primaryAuthorID = value.primaryAuthor
        obj.captions = value.captions
        obj.video = value.videoURL
        obj.videoState = value.videoState
        obj.primaryAuthor = value.primaryAuthor

        // console.log(utils.formatDuration(value.videoDuration))
        obj.duration = utils.formatDuration(value.videoDuration)
        obj.views = value.videoViews
        VideosArray.push(obj)

      }

      console.log(VideosArray)
      VideosArray = VideosArray.filter(e => e.primaryAuthorID !== myID)
      setOriginailArray(VideosArray)
      console.log(originailArray)
      const primaryAuthors = [...new Set(VideosArray.map(item => item.primaryAuthorID))];
      console.log(primaryAuthors)
      let o
      author.length = 0;
      let i=0;
      for (let value of primaryAuthors) {
       
        //console.log(value)
       
          //console.log(value)
          utils.getUserDetailByID2(value).then(function(result){
             o = {}
            console.log(result)
            const person =   result.data.searchUsers.items[0]
            if(person!=null){
             
             let name = person.givenName + ' ' + person.familyName 
              o.label =name
              o.value = value
               VideosArray.filter(element => element.primaryAuthorID == value).forEach(element => element.primaryAuthor = utils.truncate(name,22));
              author.push(o)
            }
          
                 i++;
                 
                 if(i==primaryAuthors.length){
                   console.log(VideosArray)
                    author.push({ label: 'Show All', value: 'All' })
                    setAuthorList(author)
                    
                    if (VideosArray.length > 0) sortDate(VideosArray)
                    setVideos(VideosArray)
                    setFinish(true)
                    setRefresh(!refresh)
                 } 
                
                 
        });
        
     
     
      }
      if(primaryAuthors.length==0){
                   setFinish(true)
                   setRefresh(!refresh)
                 }
     
    });
  }

  useEffect(() => {


    // console.log(data)
    if (value == 0) {
      setTimeout(function() {
        getVideos();
        setFinish(true)
      }.bind(this), 2000);

    }
    if (value == 1) {
      getVideos();
      console.log(originailArray)
      let videosArray = originailArray.filter(e => e.videoState == "ARCHIVED")
      console.log(videosArray)
      setVideos(videosArray)
    }
    else if (value == 2) {

      getSecondaryVideo();
    }
  }, [value]);

  if (finishLoading) {
    return (
      <div class="thumbnailContentBlock">
         <Grid container sm ={12} style={{backgroundColor:"#333",height:50}}>
            <Grid item sm={5}>
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab  label="My Videos" {...a11yProps(0)} />
                <Tab   disabled={!finishLoading} label="Archive" {...a11yProps(1)} />
                <Tab  disabled={!finishLoading} label="Backup Authors" {...a11yProps(2)} />
               
              </Tabs>
            </Grid>
            
            <Grid container sm={7} direction={'row'}>
            
              <Grid item>
                <Button
                size={'small'}
                variant="contained"
                color="secondary"
                onClick={()=>addVideo()}>Add New Video</Button>
                
                </Grid>
                <Grid item style={{margin:-10}}>
                    <SortDateAlpha sortAlpha={()=>sortAlpha(Videos)} sortDate = {()=>sortDate(Videos)}/>
                </Grid>
                <Grid>
                 <CssTextField
                       onChange={(e) => handleSearch(e.target.value)} 
                          style={{ marginTop:-3, marginLeft:10 }}  
                          label="Search videos"
                          variant="outlined"
                          id="custom-css-outlined-input"
                          InputProps = {
                                        {
                                            endAdornment: (
                                                <InputAdornment>
                                          <IconButton>
                                            <SearchIcon style={{color:"#E20076"}}/>
                                          </IconButton>
                                        </InputAdornment>
                                            )
                                        }
                            }
                />
                </Grid>
              </Grid>
          
        
        </Grid>
          <TabPanel value={value} index={0} style={{backgroundColor:'black',color:'white'}}>
          {processingVideos.length>0 &&
          <div>
            <Typography variant="h6" gutterBottom>Scheduled or Processing</Typography>
          <Grid container direction='row'>

            {processingVideos.map(video =>
                <Grid item  alignItems="center" >
                        
                  <VideoCard
                    audience={video.audience}
                    activity = {video.createdAt}
                    video =  {video.videoURL}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                     imageStyle={{filter: 'grayscale(100%)'}}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    captions={video.captions}
                    video = {video.video}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Video ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
          </div>
          }
           <Typography variant="h6" gutterBottom>Active</Typography>
          <Grid container direction='row'>

            {Videos.map(video =>
                <Grid item  alignItems="center" >
                        
                  <VideoCard
                    audience={video.audience}
                    activity = {video.createdAt}
                    video =  {video.videoURL}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                    allowTrainerDownload ={video.allowTrainerDownload}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    captions={video.captions}
                    video = {video.video}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Video ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                       {name:'Archive', func: archiveDialog},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
          </TabPanel>
             <TabPanel value={value} index={1} style={{backgroundColor:'black',color:'white'}}>
           {/*<Typography variant="h5" gutterBottom>My Videos</Typography>*/}
          
      
          <Grid container direction='row'>
          {/*setVideos(Videos.filter(e => e.videoState == "ARCHIVED"))*/}
            {archiveVideos.map(video =>
                <Grid item  alignItems="center" >
                        
                  <VideoCard
                    audience={video.audience}
                    activity = {video.createdAt}
                    video =  {video.videoURL}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                    imageStyle={{filter: 'grayscale(100%)'}}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    captions={video.captions}
                    video = {video.video}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Video ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                     {name:'Restore', func:restoreVideo},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
          </TabPanel>
          
           <TabPanel value={value} index={2} style={{backgroundColor:'black',color:'white'}}>
           <Typography variant="h5" gutterBottom></Typography>
         
         
           {authors.length>1 &&
              <FormControl variant="outlined" style={{width:300}} >
                          <InputLabel id="demo-simple-select-outlined-label">Sort by primary author</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={authors}
                            onChange={handleAuthorChange}
                            label="Select"
                          >
                              {authorList.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          
                          </Select>
            </FormControl>
           }
           <SortDateAlpha sortAlpha={()=>sortAlpha(Videos)} sortDate = {()=>sortDate(Videos)}/>
         <FormControl component="fieldset">
  <FormLabel component="legend"></FormLabel>
  <RadioGroup aria-label="a" name="ative" value={showActive} onChange={handleShowActive} row>
    <FormControlLabel value={true} control={<Radio />} label="Active Content" />
    <FormControlLabel value={false} control={<Radio />} label="Archived Content" />
   
  </RadioGroup>
</FormControl>
          <Grid container xs={12} sm={12}  direction='row'>
           
            {Videos.map(video =>
                <div>
                {(video.videoState =="ACTIVE" && showActive) &&
               <Grid item  xs={3} sm={3} alignItems="center" >
                  <div  style={{backgroundColor:'#fff',color:'#E20074',padding:'3px',width:222}}><PersonIcon/>{video.primaryAuthor}</div>
                  <VideoCard
                    activity = {video.createdAt}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                     captions={video.captions}
                    video = {video.video}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Video ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                    
                       {name:'Archive', func: archiveDialog},
                      ]}
                  />
                 </Grid>
                }
                 {(video.videoState =="ARCHIVED" && !showActive) &&
                 <Grid item  xs={3} sm={3} alignItems="center" >
                  <div  style={{backgroundColor:'#fff',color:'#E20074',padding:'3px',width:222}}><PersonIcon/>{video.primaryAuthor}</div>
                  <VideoCard
                    activity = {video.createdAt}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                     captions={video.captions}
                    video = {video.video}
                     imageStyle={{filter: 'grayscale(100%)'}}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Video ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                      {name:'Restore', func:restoreVideo},
                     
                      ]}
                  />
                  </Grid>
                }
                
                    </div>
            )}
      
          </Grid>
          </TabPanel>
          
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Delete Video"}</DialogTitle>
              <DialogContent>
                {(associatedList.playlist.length == 0 && associatedList.channel.length ==0  ) &&
                  <DialogContentText id="alert-dialog-slide-description">
                  Deleting Videos is permanent! Please confirm this is what you want to do.
                  </DialogContentText>
                 }
                 {(associatedList.playlist.length >0 || associatedList.channel.length >0  )&&
                  <DialogContentText id="alert-dialog-slide-description">
                   Your video/audio is included in {associatedList.playlist.length} playlists and {associatedList.channel.length} channels. Archiving/Deleting this content will remove any reference to the media from playlists and channels. This operation cannot be undone. Do you wish to continue?
                  </DialogContentText>
                 }
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes delete
                </Button>
                <Button onClick={handleDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
                 <Dialog
              open={archiveOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleArchiveDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Archive Video"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Are you sure you want to archive this video?
                  Existing references within embedded players may show a deactivated message.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archiveVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes archive
                </Button>
                <Button onClick={handleArchiveDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
           
           
        </div>
    )
  }
  else return null;
}

export default withRouter(authorVideos);
