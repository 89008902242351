import React,{useEffect} from 'react';
import Footer from '../parts/Footer';
import HorizontalPlaylist from '../parts/HorizontalPlaylist';
//import FeatureVideos from '../parts/FeatureVideos';
import FeaturedVideos from '../parts/FeaturedVideos';
import { connect } from 'react-redux'
import {Cache } from 'aws-amplify';


const mapStateToProps = state => ({
  isLogged: state.isLogged,
  isMenuOpen: state.isMenuOpen,
})

function Home(props){

  useEffect(() => {
    
   }, []);
  if(props.isLogged){
   
    return(
      <div>
        <FeaturedVideos />
        <div class="thumbnailWrapper">
         {/* <HorizontalPlaylist title = "Recommended for you" />*/}
          <HorizontalPlaylist title = "Recently Added" func='recent'/>
          <HorizontalPlaylist title = "Trending" func="trending" />
        <HorizontalPlaylist title = "Recommended" func="recommendations" />
        </div>
       
    
      </div>
      )
      
  }
    else{
      return(null)
    }
};


export default connect(mapStateToProps, null)(Home);