import React ,{useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    color:'#ccc',
    width: 250,
    height: 330,
    overflow: 'auto',
     backgroundColor:'#272727;',
    border: '1px solid #ccc',
  },
  button: {
       border: '1px solid #ccc',
      color:'#ccc',
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  
  let rightArray = []
  let centerArray = []
  if(props.selectedAudiences!=null)rightArray =props.selectedAudiences
  if(props.prescribedAudience!=null)centerArray =props.prescribedAudience
  let leftArray = props.audiences;
  
   leftArray =  leftArray .filter( function( el ) {
      return !rightArray.includes( el );
    }).filter( function( el ) {
        return !centerArray.includes( el );
    })
 
  const [left, setLeft] = React.useState(leftArray);
  const [center, setCenter] = React.useState(centerArray);
  const [right, setRight] = React.useState(rightArray);

  const leftChecked = intersection(checked, left);
  const centerChecked = intersection(checked, center);
  const rightChecked = intersection(checked, right);
   
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  
  const handleCheckedRight = () => {
     setChecked(not(checked, centerChecked));
     setCenter(not(center, centerChecked));
    setRight( right.concat(centerChecked));
    
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(centerChecked));
    setCenter(not(center, centerChecked));
    setChecked(not(checked, centerChecked));
   
  };

   const handleCheckedCenterFromLeft = () => {
    setChecked(not(checked, leftChecked));
    setLeft(not(left, leftChecked));
    setCenter( center.concat(leftChecked));
   
  };
   const handleCheckedCenterFromRight = () => {
    setCenter(center.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
   
  };
  const setParam = () => {
    props.onChange(center,right)
  };
 
  useEffect(() => {
    // action on update of movies
   
   
     setParam()
}, [center,right]);
  const customList = items => (
    
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
         
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
             
               <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                
              </ListItemIcon>
            
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={3} justify="left" alignItems="center" className={classes.root}>
      <Grid item>
        Elective
        {customList(left)}
        </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
       
        
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleCheckedCenterFromLeft}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            aria-label="move selected left"
          >
            &lt;
          </Button>
       
        </Grid>
      </Grid>
      <Grid item>
        Prescribed Audiences
        {customList(center)}
      </Grid>
        <Grid item>
        <Grid container direction="column" alignItems="center">
         
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedCenterFromRight}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        
        </Grid>
      </Grid>
        <Grid item>
          Required Audiences
          {customList(right)}
          </Grid>
    </Grid>
  );
}
