const menuReducer = (state = false, action) => {
    switch(action.type){
        case 'TOGGLE_MENU':
            return !state;
         case 'HIDE_MENU':
            return state= false;
        case 'SHOW_MENU':
            return state = true;
       
        default:
            return state;
    }
    
};

export default menuReducer;
