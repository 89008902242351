const profileReducer = (state = true, action) => {
    switch(action.type){
        case 'UPDATE_PROFILE_PIC':
            return !state;
        default:
            return state;
    }
};

export default profileReducer;
