import React, { useEffect,Fragment,useState } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import getUserProfile from '../functions/getUserProfile.js';
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';
import PlaylistCheck from '@material-ui/icons/PlaylistAddCheck';
import Share from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import PlaylistThumb from './PlaylistThumb';

import './Playlist/PlaylistItem.css';
import Wrap from '../functions/Wrap.js';
import PlaylistItem from './Playlist/PlaylistItem.js';
import SortDateAlpha from './widgets/SortDateAlpha.js';
import NewPlaylist from './buttons/NewPlaylist.js';
import * as utils from '../functions/util.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

 var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isFirefox = typeof InstallTrigger !== 'undefined';
const style = {
  root: {
    color: '#fff',
  },
  PlaylistThumb: {
    color: '#fff',
  }

}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function Playlists(props) {

  const { classes } = props;
  const [Videos, setVideos] = useState([]);
  const [refreshToggle, setRefresh] = useState(false);
  const [finishLoading, setFinish] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [pid, setPid] = useState();
  let data

    const copyPlaylistID=(id)=>{
      navigator.clipboard.writeText(id)
   if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      window.clipboardData.setData('Text', id);
      navigator.clipboard.writeText(id)

      document.execCommand('copy');
    }

  }
      const handleDialogClose = () => {
    setOpen(false);

  };
    const handleDelete =()=>{
      removePlaylist(Videos.id)
  }
      const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
    const removePlaylistDiaglog = (id) => {
      console.log(id)
    setOpen(true);
    setPid(id)
  };
 const handleEdit =(id)=>{
    props.history.push('/editPlaylist?p=' + id)
  }
  const sortAlpha=()=>{

    Videos.sort((a, b) => a.video_title.localeCompare(b.video_title))
    setVideos(Videos)
    setRefresh(!refreshToggle)
  }

  const sortDate=()=>{
    Videos.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
  //  console.log(Videos)
    setVideos(Videos)
    setRefresh(!refreshToggle)
  }
  const removePlaylist =(id)=>{
    setVideos( Videos.filter(video => video.id != pid) );// remove from display
    utils.deletePlaylist(pid)
      setOpen(false)
  }
  const getVideoAdded=(item)=> {
        return <PlaylistItem
              id={item.id}
              firstVideo={item.firstVideo}
              thumbnail={item.poster_image_loc}
              title={item.video_title}
              description={item.video_desc}
              date={utils.formatDate(item.createdAt)}
              like={true}
              favorite={true}
              videos ={item.videos}
             redirect = {props.redirect}
             refresh={refreshToggle}
             haveMenu={true}
              removeMe = {removePlaylist}
              videoArray={item.videoArray}
               haveMenu={true}
               videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Delete', func:removePlaylistDiaglog},
                       {name:'Copy Playlist ID', func:copyPlaylistID},
                      ]}
            />
  }
    useEffect(() => {
      getUserProfile("").then(function(result) {
        console.log(result)
        data =result.data.getUserProfile.playlist.items

        let VideosArray = [];
        let playlistItem = data

        for (var value of playlistItem) {
          console.log(value)
          var obj = {};
           obj.id = value.id ;
          value.title ? obj.video_title = value.title : obj.video_title ='';
          value.description ? obj.video_desc = value.description : obj.video_desc = '';
          value.createdAt ? obj.createdAt = value.createdAt : obj.createdAt = '';
         // console.log(typeof(value.thumbnail))
         console.log(value.videos.items)
         console.log(value.videoArray)
         obj.videoArray = value.videoArray;
         if(value.videos.items.length>0  ){
             if(value.videoArray) obj.firstVideo = value.videoArray[0]
             obj.poster_image_loc = value.videos.items[0].thumbnail;
             obj.videos = value.videos.items
             
         }
         else if(value.audios.items.length>0) {
          if(value.videoArray) obj.firstVideo = value.videoArray[0]
          obj.poster_image_loc = value.audios.items[0].thumbnail;
          obj.videos = value.audios.items
         }
         else{
             obj.videos=[];
         }
          VideosArray.push(obj)
        }
         setVideos(VideosArray)
       setFinish(true)
      });
   }, []);
    if(finishLoading){
  return (

    <Wrap>

          <div class="playlist-controls-wrapper flex-item">
            <SortDateAlpha sortAlpha={sortAlpha} sortDate = {sortDate}/>
            <NewPlaylist redirect={props.redirect}/>
          </div>
        {Videos.map(item =>getVideoAdded(item))}
         <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete Playlist"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Deleting Playlist is permanent! Please confirm this is what you want to do.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}  variant="contained"
              size="medium"
              color="secondary">
            Yes delete
          </Button>
          <Button onClick={handleDialogClose}  variant="contained"
              size="medium"
              color="secondary">
           Cancel
          </Button>
        </DialogActions>
      </Dialog>
        </Wrap>
  );
    }
   else return null;
}

Playlists.propTypes = {
  classes: PropTypes.object.isRequired,
};
//export default  withStyles(style)(Playlists);
export default withRouter(Playlists);
