import * as React from "react";

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 29.618 37.022">
    <path id="Path_421" data-name="Path 421" d="M22.511,2,33.618,13.107V35.32a3.7,3.7,0,0,1-3.7,3.7H7.7A3.7,3.7,0,0,1,4,35.32V5.7A3.7,3.7,0,0,1,7.7,2H22.511m7.4,33.32V14.958H20.66V5.7H7.7V35.32H29.916m-3.7-3.7-4.628-3.147v3.147H11.4V22.362H21.586v3.147l4.628-3.147Z" transform="translate(-4 -2)" fill="#de1173"/>
  </svg>
  );
}

export default Svg;
