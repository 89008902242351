const playlist = [
  {
          "id": "v2345",
          "video_title": "Sprint Drive",
          "video_desc": "Coaching session between Supervisor and consultant",
          "primary_author": "Gordon Highland",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/just_coach_it_sprint_drive.jpg",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/just_coach_it_sprint_drive.mp4",
            "duration": "2:33",
            "views": "5",
          "keywords": [
            "Sprint",
            "Coach",
            "Drive",
            "Connect",
            "Behaviours"
          ],
          "createdAt": "2019-07-18T05:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "Some Channel"
          }
        },
        {
          "id": "v1234",
          "video_title": "Sprint Complete Benefits",
          "video_desc": "Sprint Complete is an all-in-one solution for backup and device protection, but without the right pitch, our customers might not know the true benefits of this service.",
          "primary_author": "Taylor Schlobohm",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/sprint_complete_role_play.jpg",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/sprint_complete_role_play.mp4",
            "duration": "2:33",
            "views": "45",
          "keywords": [
            "Sprint",
            "Complete",
            "Benefits"
          ],
          "createdAt": "2019-05-07T20:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "Care Corner"
          }
        },
        {
          "id": "v5678",
          "video_title": "Sprint Remote Support",
          "video_desc": "Why Work at Sprint ? Overview of Sprint remote support",
          "primary_author": "Richard McCrosKey",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/remote_support.jpg",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/remote_support_ios.mp4",
            "duration": "2:33",
            "views": "15",
          "keywords": [
            "Sprint University",
            "Remote Support"
          ],
          "createdAt": "2019-05-07T20:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "Care Corner"
          }
        },
        {
          "id": "v3456",
          "video_title": "Sprint Secure WiFi",
          "video_desc": "SBE Bite sized",
          "primary_author": "Mark Clark",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/sprint_video_image.png",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/2-sprint-secure-wifi.mp4",
            "duration": "2:33",
            "views": "15",
          "keywords": [
            "SBE"
          ],
          "createdAt": "2019-05-07T20:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "News"
          }
        },
        {
          "id": "v4567",
          "video_title": "Why Work at Sprint",
          "video_desc": "Why Work at Sprint ?",
          "primary_author": "Colin Ridge",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/why_work_sprint.jpg",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/why_work_sprint_emedia.mp4",
            "duration": "2:33",
            "views": "15",
          "keywords": [
            "Sprint University",
            "Why Work at Sprint"
          ],
          "createdAt": "2019-07-18T17:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "Sales Excellence"
          }
        },{
          "id": "v9678",
          "video_title": "Sprint Remote Support",
          "video_desc": "Why Work at Sprint ? Overview of Sprint remote support",
          "primary_author": "Richard McCrosKey",
          "poster_image_loc": "https://s3.amazonaws.com/clips-sprint-dev/remote_support.jpg",
          "video_loc": "https://s3.amazonaws.com/clips-sprint-dev/remote_support_ios.mp4",
            "duration": "2:33",
            "views": "15",
          "keywords": [
            "Sprint University",
            "Remote Support"
          ],
          "createdAt": "2019-05-07T20:00:07.731Z",
          "owner": "18233e54-a5d4-43c4-8030-157f77568426",
          "channel": {
            "channel_title": "Care Corner"
          }
        }
];

export default playlist;
