import React, { useState, useEffect } from 'react';
import '../style/VideoCard.css';
import Wrap from '../functions/Wrap.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import ShareVideo from '../parts/videoShare.js';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


function PodcastCard(props) {
  //console.log(props.channel)
  const video = props;
  const id = props.id;
  const date = new Date(props.updatedAt).toDateString();
//console.log(date)
  //const ActivityDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const thisClass = props.type!='static'? 'vcard':'vcardStatic'
  const [cAnchorEl, setcAnchorEl] = React.useState(null);
  const [copen, setcOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const[thumb, setThumb]= React.useState();
  const [menuitem, setMenu] = React.useState(props.scheduledArchived?props.videoMenu.filter(menu => menu.name != "Archive"):props.videoMenu);
 // if(props.scheduledArchived) setMenu(props.videoMenu.filter(menu => menu.name == "Archive"))
 
  const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
  
  
 //console.log(fitThumbURL)
  const handleWatch = () => {
  
    props.history.push('/Podcast?id=' + id)
 
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
   const handleChannelMenuClick = event => {
    setcAnchorEl(event.currentTarget);
  };
   const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  
   const handleChannelClose= () => {
    setcAnchorEl(null);
    setcOpen(false)
  };
  
   const handleShareOpen= () => {
    setAnchorEl(null);
    setShareOpen(true)
  };
  
   const gotoChannel = (id) => {
    if( props.type!='static'){
     props.history.push('/Channel?id=' +id)
    }
  }
  const openShare=(id)=>{
      setShareOpen(true)
  }
  
   const closeShare=(id)=>{
      setShareOpen(false)
  }
  useEffect(() => {
    console.log(video)
    const extension = video.image.split('.')
    setThumb(`https://${server[2]}/fit-in/220x124/public/podcast/${id}/artwork.${extension[extension.length-1]}`)
  
    
   }, []);
 
 const videoMenu = ()=> {
   return(
     
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      
       {menuitem.map(menu =>
       
          
                    <StyledMenuItem onClick={()=>{
                      setAnchorEl(null);
                        menu.func(props.id);
                        
                    }}>
                       <ListItemText primary={menu.name} />
                    </StyledMenuItem>
               
             
        )}
      
        
          <StyledMenuItem onClick={()=>handleWatch()}>
            <ListItemText primary={"Share"} />
          
        </StyledMenuItem>
       
    
       
      </StyledMenu>
     )
 }

 
 const channelMenu = ()=> {
   return(
     
      <StyledMenu
        id="customized-menu"
        anchorEl={cAnchorEl}
        keepMounted
        open={Boolean(cAnchorEl)}
        onClose={handleChannelClose}
      >
       {props.channel.items.map(menu =>
        <StyledMenuItem onClick={()=>{
           gotoChannel(menu.channel.id)
            setcAnchorEl(null);
        }}>
          <ListItemText primary={menu.channel.title} />
        </StyledMenuItem>
        )}
       
      </StyledMenu>
     )
 }

  return (
     <div class={thisClass} id="49mwdxLb">
  <div class="topHover"></div>
  <div class="vcardThumb" onClick={handleWatch}>
    <LazyLoadImage src={thumb} alt="video thumbnail" alt={props.title}/>
    <div class="videoDuration">{props.duration}</div>
  </div>
  <div class="vcardMetadataWrapper">
    <div class="videoProgressBar videoProgressBarNone"><div class="videoProgress videoProgressNone"></div></div>
    <div class="vcardMetadata">
    {props.type!='static' &&
      <div class="vcardMoreButton" onClick={handleClick}>
        <svg style={{width:24+'px',height:24+'px'}} viewBox="0 0 18 24">
        <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
    </svg>
      </div>
    }
      <h1 class="videoTitle">
      {props.title}
      </h1>
      {/*console.log(props.channel)*/}
    {props.channel!==undefined &&
    <div>
      {props.channel.items.length>0 &&
      <div class="channelRow">
        <div class="videoChannel" onClick={()=>gotoChannel(props.channel.items[0].channel.id)}>
          <span class="channelTitle">{props.channel.items[0].channel.title} </span><span>{'  '}</span>
          <span class="channelIcon">
            <svg style={{width:16, height:16, verticalAlign:'top',leftPadding:3 }} viewBox="0 0 24 19">
               <path d="M2.5,4.5H21.5C22.34,4.5 23,5.15 23,6V17.5C23,18.35 22.34,19 21.5,19H2.5C1.65,19 1,18.35 1,17.5V6C1,5.15 1.65,4.5 2.5,4.5M9.71,8.5V15L15.42,11.7L9.71,8.5M17.25,21H6.65C6.35,21 6.15,20.8 6.15,20.5C6.15,20.2 6.35,20 6.65,20H17.35C17.65,20 17.85,20.2 17.85,20.5C17.85,20.8 17.55,21 17.25,21Z" />
            </svg>
          </span>
        </div>     
        {props.channel.items.length>1 &&
        <span class="moreChannels" onClick={handleChannelMenuClick}>
          <svg style={{width:24,height:24}} viewBox="0 0 24 20">
              <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
          </svg>
        </span>
        }
      </div>   
        
      }
      </div>
       }
      <span class="videoViewsAge">
       {!props.noDate  &&<span> {video.views||'0'} Plays • </span>}
         {date}</span>
    </div>
    <ShareVideo open={shareOpen} close={closeShare} id={id} />
  </div>
  {props.videoMenu!=null &&
   videoMenu()
  }
  
  {props.channel!==undefined &&
   channelMenu()
  }
  
 
  
</div>
  )
  
}

export default withRouter(PodcastCard);
