import * as React from "react";

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46.25 37">
    <path id="Path_367" data-name="Path 367" d="M43.625,4A4.625,4.625,0,0,1,48.25,8.625v27.75A4.625,4.625,0,0,1,43.625,41h-37A4.625,4.625,0,0,1,2,36.375V8.625A4.625,4.625,0,0,1,6.625,4h37m0,32.375V8.625h-37v27.75h37M11.25,17.875h4.625V22.5H11.25V17.875m0,9.25h18.5V31.75H11.25V27.125m23.125,0H39V31.75H34.375V27.125M20.5,17.875H39V22.5H20.5Z" transform="translate(-2 -4)" fill="#de1173"/>
  </svg>
  );
}

export default Svg;
