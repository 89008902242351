import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { withRouter } from "react-router";
import VideoCard from '../VideoCard.js';
import Age from '../../functions/Age.js';

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',

  },

  gridListTile: {
    height: 450,
    border: '1px solid #E20074',
    display: 'block',


  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  vidContainer: {
    display: 'flex',
    padding: 10,
  }
}));


function CollectionBox(props) {
  console.log(props)
  const classes = useStyles();
  let collection = props.obj.video.filter(video => video !== null)
  console.log(collection)

  const handleCollection = (id) => {
    if (props.type != 'static') {
      props.history.push('/CollectionPage?id=' + id)
    }
  }
  const copyVideoID = (id) => {
    navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(id)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }
  useEffect(() => {




  }, []);

  return (


    <div className={classes.gridListTile} >
     
          <GridListTile key={props.obj.info.bannerGraphic} onClick={()=>handleCollection(props.obj.info.id)} >
           
            <img src={props.obj.info.heroGraphic} style={{cursor: "pointer"}} alt={props.obj.title} />
            <GridListTileBar
            actionPosition={"left"}
              actionIcon= {<IconButton>
                 <img src={props.obj.info.iconGraphic}/>
                </IconButton>}
              title={props.obj.info.title}
            />
          </GridListTile>
          
          {collection.length>0 &&
          <Grid container spacing={10} className={classes.vidContainer} sm={12} >
         
         
             <Grid item sm={6}>   
                  <VideoCard
                    activity = {collection[0].createdAt}
                    image = {collection[0].thumbnail}
                    title = {collection[0].title}
                    views = {collection[0].videoViews}
                    age = {Age(collection[0].createdAt)}
                    duration = {collection[0].videoDuration}
                    allowTrainerDownload={collection[0].allowTrainerDownload}
                    //channel = {video.channel.channel_title}
                    id = {collection[0].id||0}
                    videoMenu={[ 
                         {name:'Copy Video ID', func:copyVideoID},
                      ]}
                  />
                  
                  </Grid>
            
           
         
         {collection.length>1 &&
             <Grid item sm={6}>
                  <VideoCard
                    activity = {collection[1].createdAt}
                    image = {collection[1].thumbnail}
                    title = {collection[1].title}
                    views = {collection[1].videoViews}
                    age = {Age(collection[1].createdAt)}
                    duration = {collection[1].videoDuration}
                     allowTrainerDownload={collection[1].allowTrainerDownload}
                    //channel = {video.channel.channel_title}
                    id = {collection[1].id||0}
                    videoMenu={[ 
                      
                      ]}
                  />
                  </Grid>
         }
                  
                  
         
            <Grid item sm={6}></Grid>
          </Grid>
          }
         
     </div>


  );


}

export default withRouter(CollectionBox);
