import * as React from "react";

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 44.223 39.31">
    <path id="Path_422" data-name="Path 422" d="M7.914,4A4.866,4.866,0,0,0,3,8.914V38.4A4.866,4.866,0,0,0,7.914,43.31h34.4A5.038,5.038,0,0,0,47.223,38.4V8.914a4.752,4.752,0,0,0-1.5-3.464A4.728,4.728,0,0,0,42.31,4H7.914M6.685,7.685H43.538V39.624H6.685V7.685m6.142,8.6a2.356,2.356,0,0,0-2.457,2.457v9.827a2.356,2.356,0,0,0,2.457,2.457H20.2a2.491,2.491,0,0,0,1.744-.688,2.28,2.28,0,0,0,.712-1.769V26.112H18.97V27.34H14.056V19.97H18.97V21.2h3.685V18.741a2.28,2.28,0,0,0-.712-1.769,2.491,2.491,0,0,0-1.744-.688H12.827m17.2,0a2.491,2.491,0,0,0-1.744.688,2.28,2.28,0,0,0-.712,1.769v9.827a2.28,2.28,0,0,0,.712,1.769,2.491,2.491,0,0,0,1.744.688H37.4a2.356,2.356,0,0,0,2.457-2.457V26.112H36.167V27.34H31.254V19.97h4.914V21.2h3.685V18.741A2.356,2.356,0,0,0,37.4,16.284Z" transform="translate(-3 -4)" fill="#de1173"/>
  </svg>
  );
}

export default Svg;
