import * as React from "react";

function SvgRecommend(props) {
  return (
    <svg viewBox="-2 -4 24 24" width={24} height={24}   {...props}>
    <path id="Subtraction_1" data-name="Subtraction 1" d="M17,17h0l-3.4-3.4H1.7A1.7,1.7,0,0,1,0,11.9V1.7A1.7,1.7,0,0,1,1.7,0H15.3a1.7,1.7,0,0,1,1.691,1.7L17,17ZM6.587,3.4A2.313,2.313,0,0,0,4.25,5.738c0,1.6,1.443,2.913,3.627,4.894l.007.007L8.5,11.2l.616-.557.013-.012c2.18-1.981,3.621-3.289,3.621-4.893A2.312,2.312,0,0,0,10.413,3.4,2.544,2.544,0,0,0,8.5,4.289,2.546,2.546,0,0,0,6.587,3.4Z"  fill="#acacac"/>
    </svg>
  );
}

export default SvgRecommend;