import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

// ROUTER
import { ConnectedRouter } from 'connected-react-router';

// THEME
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import muiTheme from './theme/muiTheme';

// REDUX
import { Provider } from 'react-redux';
import configureStore, { history } from './store';


// App
//import App from './pages/App';
import Gateway from './pages/Gateway'

// Amplify
import Amplify, { Auth, Hub, Analytics, AmazonPersonalizeProvider, Cache } from 'aws-amplify';
import awsmobile from './aws-exports';

//css - eric
import './index.css';


// const oauth = {
//     domain: process.env.REACT_APP_HOSTED_UI,
//     "scope": [
//         "phone",
//         "email",
//         "openid",
//         "profile",
//         "aws.cognito.signin.user.admin"
//     ],

//     redirectSignIn: "http://localhost:3000/",
//     redirectSignOut: process.env.REACT_APP_CALLBACK_URL,
//     responseType: "code"
// };
// Update Cognito Settings
awsmobile.oauth.domain = process.env.REACT_APP_HOSTED_UI;
//awsmobile.oauth.domain = "auth.test.clips.t-mobile.com"
awsmobile.oauth.redirectSignIn = process.env.REACT_APP_SIGN_IN_URL;
//awsmobile.oauth.redirectSignIn = "http://localhost:3000/";
awsmobile.oauth.redirectSignOut = process.env.REACT_APP_CALLBACK_URL;
console.log("AWS Mobile"+awsmobile)
Amplify.configure(awsmobile);

//Record a custom event.
Analytics.record({ name: 'video-usage' });

const store = configureStore();

const render = Component => {
    ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={muiTheme}>
              <Component />
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    );
};

/// Render once
render(Gateway);
/**
// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept('./pages/App', () => {
        render(App);
    });
}
 */
