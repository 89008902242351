import React from 'react';
import { withRouter } from "react-router";

function PlayerKeywords(props) {
    console.log(props)
    const handleClick = (e) => {
        let keyword = (e.target.textContent)
        props.history.push("Search?keyword=" + keyword)
    }
    return (
        <ul class="player-keywords">  
        {props.data.map(item =>  
          <li onClick={handleClick} style={{cursor:'pointer'}}>{item}</li>  
            
            )}  
        </ul>
    )
}

export default withRouter(PlayerKeywords);
