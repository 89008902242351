import React, { Component } from 'react';
import './FeaturedVideos.css';
import Age from '../functions/Age.js';
//import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple'; // hmm - I can't seem to get this to work.
import * as Queries from '../graphql/queries.js'
import { Link } from 'react-router-dom';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as utils from '../functions/util.js';
import { Cache } from 'aws-amplify';
import getUserProfile from '../functions/getUserProfile.js';
import Grid from '@material-ui/core/Grid';
import Carousel from './carousel.js'

 let temp=[]
 
class FeaturedVideos extends Component {
  constructor(props) {
    super(props);
    this.attempts = 1;
    this.state = {
      playlist: []
    };
  }
  componentDidMount() {
    
    const profile = Cache.getItem('userInfo')
    console.log(profile)
    // Added a temporary hack to get the users default featured playlist.
    let groupName = profile.groupName
    if (profile == null) {

     this.getFeatureVideo('Default', true)
    }
    else {
      if (groupName == "Not Selected" || groupName == "Business_Indirect" || groupName == "Consumer_Indirect" || groupName == "Vended_care"  || groupName == "Telesales" ) {
        //groupName = process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP;
         this.getFeatureVideo('Default', true)
      }
      else  this.getFeatureVideo(groupName, false)
    }
    
    

  }
 
  componentWillUnmount(){
        this.setState({
      playlist: []
    })
 }
 

  
  async getFeatureVideo(groupName,useDefault) {
    temp=[]
    utils.getFeatureVideo(groupName, useDefault).then(function(result) {
     
      let playlistItem = JSON.parse(result).videos
      
     
      console.log(playlistItem)
      for (var value of playlistItem) {
       //console.log(value);
        var obj = {};
        obj.poster_image_loc = value.thumbnail;
        obj.id = value.id;
        obj.video_title = value.title;
        obj.video_desc = decodeURIComponent(value.description);
        obj.createdAt = value.createdAt;
     
        obj.channel = {}
        if (value.channel != null) {
          obj.channel.channel_title = value.channel.title;
        }
        temp.push(obj)
      }
  
    console.log(temp)
    this.setState({
      playlist: temp
      // playlist: [ ...this.state.playlist, ...temp ]
    })
    }.bind(this));
    /*
    console.log(groupName)
    const searchFeatureVideo = `query GetFeaturedPlaylists {
        searchPlaylists(filter:{
          audience:{
            matchPhrase :"${groupName}"
                }
          featuredPlaylist:{
            eq:true
           }
             useDefaultPlaylist:{
            eq:${useDefault}
           }
        })
            {items
            {
            id
            title
                  videos{
                    items{
                      title
                      description
                      createdAt
                      videoViews
                      videoId
                      active
                      startDate
                      expiresDate
                      videoDuration
                      featureGraphicURL
                    }
                  }
                }
              }
            }`;


    const searchResult = await API.graphql(
      graphqlOperation(searchFeatureVideo, {})
    );

    console.log(searchResult)
    if(searchResult.data.searchPlaylists.items.length==0) this.getFeatureVideo('Default', true)
    else this.processResult(searchResult)
      */
  }



  processResult(searchResult) {
    let temp = [];
     this.setState({
      playlist: []
    })
    console.log(searchResult)
    if (searchResult.data.searchPlaylists.items.length > 0) {
      let playlistItem = searchResult.data.searchPlaylists.items[0].videos.items

      console.log(playlistItem)
      for (var value of playlistItem) {
        console.log(value);
        var obj = {};
        obj.poster_image_loc = value.featureGraphicURL;
        obj.id = value.videoId;
        obj.video_title = value.title;
        obj.video_desc = decodeURIComponent(value.description);
        obj.createdAt = value.createdAt;
        obj.views = value.views;
        obj.channel = {}
        if (value.channel != null) {
          obj.channel.channel_title = value.channel.title;
        }
        temp.push(obj)
      }
    }
    else {
      let defaultGroup = process.env.REACT_APP_SPRINT_EMPLOYEES_GROUP;
      this.getFeatureVideo(defaultGroup)
    }
    this.setState({
      playlist: temp
      // playlist: [ ...this.state.playlist, ...temp ]
    })
    //console.log(this.state.playslist)
  }
  render() {
    let playlist = this.state.playlist

    return <React.Fragment>
        <div style={{maxWidth: '1133px'}}>
        <Carousel playlist = {playlist}/>
        {/*
          <Grid container direction='row' sm={12} style={{width: '100%'}}>
              {playlist.slice(0,2).map(feature => (
                    <Grid item sm={6} style={{paddingLeft: '6px'}}>
                    <Link className="link" to={"/watch?v=" + feature.id}>
                      <div index={feature.id+'Feature'} className='feature-video-card'>
                        <img className='featured-video-card-thumb' src={feature.poster_image_loc} alt={feature.video_title} />
                        <div className='feature-video-card-overlay'>
                          <div className='feature-video-card-text'>
                            <h1 className='drop-shadow-text'>{feature.video_title}</h1>
                            <span className='feature-video-channel drop-shadow-text'>{feature.channel.channel_title}</span>
                            {/*<span className='feature-video-details drop-shadow-text'>{feature.views}&nbsp;Views • {Age(feature.createdAt)}</span>
                            <span className='feature-video-duration drop-shadow-text'>{feature.duration}</span>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </Grid>
                ))}
          </Grid>
          */}
        </div>
      </React.Fragment>
  }
}

export default FeaturedVideos;
