import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { Cache } from 'aws-amplify';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import UserProfileCard from '../parts/UserProfileCard'
import './Subscriptions/ChannelTile.css';
import ChannelTile from './Subscriptions/ChannelTile.js';
import Wrap from '../functions/Wrap.js';
import SortDateAlpha from './widgets/SortDateAlpha.js';
import { Link } from 'react-router-dom';
import getUserProfile from '../functions/getUserProfile.js';
import * as utils from '../functions/util.js';

const style = {
  root: {
    color: '#fff',
  },
  card: {
    width: 200,
    maxWidth: 400,
    margin: 10,
    borderRadius: "0%",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  textField: {
    maxWidth: 600,
  },
  yellowDivider: {
    backgroundColor: '#E20074',
    height: '2px',
    display: 'flex',
    minWidth: '100%'
  },
}

function Subscriptions(props) {
  const [playlist, setVideos] = useState([]);
  let data
  const [refreshToggle, setRefresh] = useState(false);


  const sortAlpha = () => {

    playlist.sort((a, b) => a.video_title.localeCompare(b.video_title))
    setVideos(playlist)
    setRefresh(!refreshToggle)
  }

  const sortDate = () => {
    console.log(playlist)
    playlist.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    setVideos(playlist)
    setRefresh(!refreshToggle)
  }
  useEffect(() => {
    const userInfo = Cache.getItem('userInfo')
    utils.getUserChannel(userInfo.id).then(function(result) {
      console.log(result)
      data = result.data.searchUsers.items[0].subscriptions.items;
      data = data.filter(item=> item.channel!=null)

      /*
                const filteredArr = data.reduce((acc, current) => {
                  const x = acc.find(item => item.channelId === current.channelId);
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, []);
                */

      let VideosArray = [];
      let playlistItem = data
      const channel = data.filter(v => v.channel != null);
      const myRole = userInfo.groupName;
      console.log(playlistItem)
      console.log(channel)
      for (var value of channel) {
        var obj = {};
        let test = undefined;
        if (value.channel.requiredAudience != null) {
          test = value.channel.requiredAudience.find(x => x == myRole)
        }
        console.log(value.requiredAudience + ' ' + myRole)
        obj.required = test
        obj.poster_image_loc = value.channel.iconImage;
        obj.id = value.channel.id;
        console.log()
        obj.video_title = value.channel.title;
        obj.video_desc = value.channel.description;
        obj.createdAt = value.channel.createdAt
        VideosArray.push(obj)

      }
      console.log(VideosArray)
      setVideos(VideosArray)
    });
  }, []);

  return (
    <div className='channels-container'>
      <SortDateAlpha sortAlpha={sortAlpha} sortDate = {sortDate}/>
      {playlist.map(subscription =>
       
        <Wrap>
          <ChannelTile 
           
            id = {subscription.id}
            icon = {subscription.poster_image_loc}
            title = {subscription.video_title}
            description = {subscription.video_desc}
            refresh={refreshToggle}
            updated="date"
            videos="count"
            playlists="count"
            subscribers="count"
            subscribed ={true}
             showButton = {subscription.required===undefined?true:false}
            />
          <div class="channel-divider"></div>
        </Wrap>
      )}
      
    </div>
  );
}

export default Subscriptions;
