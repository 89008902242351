import React, { Fragment,useState, useEffect } from "react";

import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string'
import  {  Cache } from 'aws-amplify';
import * as utils from '../functions/util.js';
import PodcastHeader from '../parts/Channels/PodcastHeader.js';
import ChannelFeature from '../parts/Channels/ChannelFeature.js';
import ChannelPlaylists from '../parts/Channels/ChannelPlaylists.js';
import PodcastEpisodes from '../parts/Channels/PodcastEpisodes.js';

import Footer from '../parts/Footer.js';

const style = {}

function Channel(props) {
  

  const { classes } = props;
  const [finishLoading, setFinish] = useState(false);
   const [thisChannel, setChannel] = useState();
   const[showButton , setShowButton]=useState(true)
 let test = undefined
  useEffect(() => {
     // console.log('channel use effect')
      setFinish(false)
    const profile = Cache.getItem('userInfo')
    const myRole =  profile.groupName;
    let test = undefined
    utils.getPodcast( queryString.parse(props.location.search).id).then(function(result) { 
     console.log(result)
     setChannel(result.data.searchPodcastShowss.items[0]);
     document.title = `T-Mobile Clips / ${result.data.searchPodcastShowss.items[0].title}`
  
    // console.log(test)
  
     setFinish(true)
    // console.log(result.data.searchChannels.items[0])
    })
    
    return () => {
      document.title = "T-Mobile Clips"
    };
  }, [queryString.parse(props.location.search).id]);

 
 if (finishLoading) {
  return (
      <div style={{
        display:'flex', 
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        alignItems: 'flex-start',
        maxWidth: '1150px',
        width: '100%',
        padding: '8px',
        minHeight: '100%',
      }}>
     
        <PodcastHeader title={thisChannel.title} 
                       id ={queryString.parse(props.location.search).id}
                       description={thisChannel.description} 
                       videos={thisChannel.videos}
                       playlist={thisChannel.playlist}
                       subscribers = {thisChannel.subscribers}
                       createdAt = {thisChannel.createdAt}
                       bannerArt = {thisChannel.bannerArt}
                       art = {thisChannel.art}
                       key={thisChannel.subscribed}
                       showButton = {showButton }
                       podcaster= {[thisChannel.podcaster, thisChannel.backupPodcaster[0]]}
                      />
        {/*             
        { thisChannel.featureVideo && <ChannelFeature videos={thisChannel.featureVideo}/> }
        { thisChannel.playlists.items.length>0 && <ChannelPlaylists playlist= {thisChannel.playlists.items}/> }
          {thisChannel.audios.items.length>0 && <ChannelVideos videos={thisChannel.audios.items} redirect={props.history.push}/> }
         */}
         {console.log(thisChannel.audios.items)}
         {(thisChannel.audios.items.length>0 ||thisChannel.videos.items.length>0)&& <PodcastEpisodes audios={thisChannel.audios} videos={thisChannel.videos} redirect={props.history.push}/> }
       
      </div>
  );
 }
 else return null;
}

export default (Channel);