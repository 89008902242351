import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
//import { push } from 'connected-react-router'
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from "@material-ui/core";
import PropTypes from 'prop-types';

import History from './History';
import Favorites from './Favorite/Favorites';
import Playlists from './Playlists';
import Subscriptions from './Subscriptions';
import Likes from './Like/Likes.js';
import UserProfileCard from '../parts/UserProfileCard'
import { HashRouter, withRouter } from "react-router-dom";

const style = {
  root: {
    marginRight: '12px',
    padding: '0px',
  },
  tabs: {
    minWidth: '20px',
    '&:*': {
      padding: '0px',
      margin: '0px',
    },
  },
  tab:{
    marginTop: '10px',
    minHeight: '20px',
    padding: '0px',
    color:'white',
    marginRight: '16px',
    marginLeft: '4px',
    minWidth: '20px',
    '&:*': {
      padding: '0px',
      margin: '0px',
    },
    fontSize: '20px',
    fontWeight: '100',
    textTransform: 'none',
    //borderBottom: '4px solid #e6e6e6',
    //marginBottom: '10px',
    '&:hover': {
      color: 'white',
      opacity: 1,
      //borderBottom: '4px solid #E20074',
      textDecoration: 'none',
    },
    '&$selected': {
      color: 'white',
      //borderBottom: '4px solid #E20074',
    },
    '&:focus': {
      color: 'white',
      //borderBottom: '4px solid #E20074',
    },
  },
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UserPageNavigator(props) {
 console.log(props)
 let value = 0;
 
function handleChange(event, newValue) {
  props.history.push("/" + newValue)
  }

  const { classes } = props;
  return (
     <HashRouter>
       <div className="App" style={{width: '100%'}}>
         <Route
           path="/"
           render={({ location }) => (
             <Fragment>
               <Tabs 
                value={props.tab}
                onChange={handleChange}
                className={classes.tabs} 
                fullWith='false'>
                   <Tab className={classes.tab} value="Favorites" label="Favorites"  component={Link} to="/Favorites" />
                   <Tab className={classes.tab} value="Likes" label="Likes" component={Link}  to="/Likes" />
                   <Tab className={classes.tab} value="History" label="History"  component={Link} to="/History"/>
                   <Tab className={classes.tab} value="Playlists"label="Playlists" component={Link} to="/Playlists" />
                   <Tab className={classes.tab} value="Subscriptions" label="Subscriptions" component={Link} to="/Subscriptions" />
               </Tabs>
               
               <Switch>
                 <Route path="/History" render={() => <History  redirect = {props.history.push}/>} />} />
                 <Route path="/Likes" render={() => <Likes redirect = {props.history.push}/>} />
                 <Route path="/Favorites" render={() => <Favorites redirect = {props.history.push}/>} />
                 <Route path="/Playlists" render={() => <Playlists redirect={props.history.push}/>} />
                 <Route path="/Subscriptions" render={() => <Subscriptions/>} />
               </Switch>
              
             </Fragment>
           )}
         />
       </div>
     </HashRouter>
  );
}

UserPageNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default  withRouter(withStyles(style)(UserPageNavigator));
