import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';


async function getChannel() {

  const search = `query SearchAllChannels(

  $filter: SearchableChannelFilterInput

  $sort: SearchableChannelSortInput

  $limit: Int

  $nextToken: String

) {

  searchChannels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {

    items {
      id
      title
      description
      primaryAuthor
      thumbnail
      iconImage
      totalDuration
      includeInClipsSearch
      subscribers
      keywords
      createdAt
      requiredAudience
      prescribedAudience
      channelStatus
      groupsCanAccess
       featureVideo{
        title
        description
        thumbnail
        videoURL
        captionsURL
        createdAt
      }
       videos{
         items{
          video{
          id
          title
          thumbnail
          videoDuration
          createdAt
          videoViews
            }
          }
       }
       playlists{
        items {
          id
          title
          description
          createdAt
          videos{
            items{
            thumbnail
              id
              title
              description
            }
          }
        }
      }
    }
    nextToken
  }
}    
`;

  const searchResult = await API.graphql(
    graphqlOperation(search)
  );
  console.log(searchResult)
  return searchResult
}

export default getChannel;
