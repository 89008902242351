import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    color:'#fff',
    useNextVariants: true,
    fontFamily: "Sprint Sans Web",
     button: {
      textTransform: 'none'
    },
  
  },
  palette: {
    primary: {
      light: '#272727',
      main: '#373737',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#63ccff',
      main: '#E20074',
      dark: '#272727',
      contrastText: '#fff',
    },
    error: {
     light: '#E20074',
     main: '#ff0000',
     dark: '#E20074',
     contrastText: '#fff',
   },
   action: {
    active: '#E20074',
    activeOpacity: 1,
   
  },
  },
  spacing: 4,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    a:{
      color:"#E20074",
    },
  MuiButton: {
    // Name of the rule
    root:{
      borderRadius:'0px'
    },

    containedPrimary:{
      padding: '8px 30px',
      fontSize: 16,
      color: 'white',
      backgroundColor: '#373737',
      border: '2px solid #E20074',
      borderRadius: 45,
      margin:10,
      '&:hover': {
          backgroundColor: '#E20074',
          color: 'black',
          border: '2px solid #E20074',
          cursor: 'pointer',
      },
       '&:disabled':{
        backgroundColor: '#555',
        border: '2px solid #555',
        color: 'black',
        '&:hover': {
          cursor: 'notAllow'
        }
      },
    },

     containedSecondary:{
      padding: '8px 30px',
      fontSize: 16,
      color: 'white',
      backgroundColor:'#E20074',
      border: '2px solid #E20074',
      borderRadius:0,
      margin:10,
      '&:hover': {
          backgroundColor: '#E20074',
          color: 'black',
          border: '2px solid #E20074',
          cursor: 'pointer',
      },
       '&:disabled':{
        backgroundColor: '#555',
        border: '2px solid #555',
        color: 'black',
        '&:hover': {
          cursor: 'notAllow'
        }
      },
    },


    text: {
      // Some CSS
      background: '#272727',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    a:{
      textColor:'#E20074',
    }
  },
  MuiTypography:{
    colorTextSecondary:{
      color: 'white',
    }
  },
    MuiSwitch: {
       track:{
        backgroundColor:'#fff'
        },
    },
   MuiDialog: {
       paper:{
         border: '1px solid #E20074',
         backgroundColor:'black',
         color:'white',
         overflow:'hidden'
        },
        text:{
          color:'white'  
        }
    },
    MuiDialogContentText:{
         color:'white'
    },
     MuiInputBase: {
      root:{
        color:'#fff',
         backgroundColor:'#272727;',
      },
      input:{
        color:'#fff',
      }
    },
     MuiListItem: {
      gutters:{
       padding:0,
      },

    },
    MuiSelect: {
      root:{
         backgroundColor:'#272727;',
      },
      icon:{
        color:'#E20074',
      },
    },
     MuiCheckbox:{
      root:{
        color:'#E20074'
      }
    },
    MuiRadio:{
       root:{
        color:'inherit'
      }
    },
    MuiFormHelperText:{
      root:{
        color:'#ccc'
      }
    },
  MuiFormLabel:{
      root:{
        color:'inherit'
      }
    },
     MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: 'black',
          color: 'white',
          width: '100%',
          height: '100%',
        },
      },
    },
    MuiTablePagination:{
       root:{
        color:'#ccc'
      }
    }

  },
});

export default theme;
