const refreshReducer = (state = false, action) => {
    switch(action.type){
        case 'TOGGLE_REFRESH':
            return !state;
       
        default:
            return state;
    }
    
};

export default refreshReducer;