import React, { useState } from 'react';
import dateSelected from '../../images/icons/sort/DateAlpha/dateSelected.svg';
import date from '../../images/icons/sort/DateAlpha/date.svg';
import alphaSelected from '../../images/icons/sort/DateAlpha/alphaSelected.svg';
import alpha from '../../images/icons/sort/DateAlpha/alpha.svg';
import './Sort.css';

const SortDateAlpha = (props) => {
  const [sort, setSort] = useState('date');
  function handleClick(sortBy) {
    setSort(sortBy);
    console.log('change to', sort);
    const widget = document.getElementById("sortDateAlpha");
    if(sortBy === "date"){
      props.sortDate()
      widget.getElementsByTagName('img')[0].src = dateSelected;
      widget.getElementsByTagName('img')[1].src = alpha;
    } 
    else {
      props.sortAlpha()
      widget.getElementsByTagName('img')[0].src = date;
      widget.getElementsByTagName('img')[1].src = alphaSelected;
    }
  }
  return (
    <div class="sort-wrapper" id="sortDateAlpha">
      <span class="sort"></span>
      <img 
        id="dateImg"
        src={dateSelected}
        alt="Sort by Date"
        height="33px"
        width="45px" 
        class="sort-date-alpha"
        onClick={() => handleClick("date")}
      />
      <img 
        id="alphaImg"
        src={alpha}
        alt="Sort by Date"
        height="33px"
        width="45px" 
        class="sort-date-alpha"
        onClick={() => handleClick("alpha")}
      />
    </div>
  )
}

export default SortDateAlpha;