import React, { useState, useEffect } from 'react';
import VideoCard from '../VideoCard.js';
import './VideoItem.css';
import Typography from '@material-ui/core/Typography';
import Age from '../../functions/Age.js';
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as utils from '../../functions/util.js';
import getTrendingVideos from '../../functions/getTrendingVideos.js';
import getRecentVideos from '../../functions/getRecentVideos.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom'
var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const copyVideoID = (id) => {
 console.log('new ' +id )
  navigator.clipboard.writeText(id)
  if (isChrome || isFirefox) navigator.clipboard.writeText(id)
  else if (isIE) {
    navigator.clipboard.writeText(id)

    window.clipboardData.setData('Text', id);
    document.execCommand('copy');
  }

}

async function fetchData(searchQuery, paramKey, param) {
  const searchResult = await API.graphql(
    graphqlOperation(searchQuery, { paramKey: param })
  );
  return searchResult;
}



function Search(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  const userInfo = Cache.getItem('userInfo')
  console.log(userInfo)
  const myID = userInfo.id

  const [vidID, setVidID] = useState(null);
  const [Videos, setVideos] = useState();
  const [finishLoading, setFinish] = useState();
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [associatedList, setAssociatedList] = useState({playlist:[],channel:[]});
  let data, func, page
  let VideosArray = [];

  page = props.page
  let videoMenu = []


  useEffect(() => {
    //console.log('use effect ')
    console.log(props.page)
    switch (props.page) {
      case "trending":
        func = utils.getTrending;
        break;
      case "recent":
        func = getRecentVideos;
        break;
      case "recommended":
        func = utils.getRecommendation;
        break;
      default:
    }
    //console.log(data)
    func().then(function(result) {
      console.log(result)
      switch (props.page) {
        case "trending":
          //console.log(result)
          data = JSON.parse(result)
          break;
        case "recent":

          data = result.data.searchVideos.items
          break;
        case "recommended":
          data = JSON.parse(result)
          break;
        default:
      }
      console.log(data)

      let playlistItem = data
      let numloaded = 0;
      console.log(playlistItem)
      for (var value of playlistItem) {

        var obj = {};
        obj.id = value.id;
        obj.audience = value.allowPublicAccess
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.poster_image_loc = value.thumbnail
        obj.views = value.videoViews
        obj.channel = value.channels
        obj.duration = utils.formatDuration(value.videoDuration)
        obj.expirationDate = value.expirationDate
        obj.video = value.videoURL
        obj.captions = value.captions
        console.log(value.captions)
        VideosArray.push(obj)

      }
      setVideos(VideosArray)
      // setVideos(VideosArray.filter(video => video.expirationDate == null))
      setFinish(true)
    });
  }, [props.page]);


  const removeVideoDiaglog = (id) => {
    utils.checkMediaPlaylistChannel(id).then(result => {
      setOpen(true);
      console.log(result)
      let res= JSON.parse(result.data.checkForVideoInPlaylistChannel);
      console.log(res)
      setAssociatedList(res)
    })
   
    setVidID(id)
  }
  const archiveDialog = (id) => {
    setArchiveOpen(true);
    setVidID(id)
  }
  const removeVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id, associatedList.playlist,  associatedList.channel, 'DELETE').then(result => {
        setOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }

  const archiveVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id,associatedList.playlist, associatedList.channel, "ARCHIVE").then(result => {
        setArchiveOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }

  const handleClose = () => {
    //setAnchorEl(null);
    setOpen(false)
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleArchiveDialogClose = () => {
    setArchiveOpen(false);
  };
  const handleEdit = (id) => {
    props.history.push('/editVideo?v=' + id)
  }
  if (userInfo.role === "Administrator") {
    videoMenu.push({ name: 'Copy Video ID', func: copyVideoID }, { name: 'Archive', func: archiveDialog }, { name: 'Delete', func: removeVideoDiaglog }, )
  }
  else {
    videoMenu.push({ name: 'Copy Video ID', func: copyVideoID }, )
  }
  if (finishLoading) { { console.log('loading') } { console.log(Videos) }
    return (
      <div class="thumbnailContentBlock">
          <Typography variant="h5" gutterBottom>{props.title}</Typography>
          <div class='videoThumbRow' style={{marginLeft: '0'}}>
            {Videos.map(video =>
              <VideoCard
                audience={video.audience}
                activity = {video.createdAt}
                image = {video.poster_image_loc}
                title = {video.video_title}
                views = {video.views||0}
                age = {Age(video.createdAt)}
                duration = {video.videoDuration}
                id={video.id}
               channel ={video.channel}
                  videoMenu={videoMenu}
                  video={video.video}
                
                  captions={video.captions}
                  scheduledArchived = {video.expirationDate==null?false:true}
              />
                )}
          </div>
          
             <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Delete Video"}</DialogTitle>
              <DialogContent>
              {(associatedList.playlist.length == 0 && associatedList.channel.length ==0  ) &&
                  <DialogContentText id="alert-dialog-slide-description">
                  Deleting Videos is permanent! Please confirm this is what you want to do.
                  </DialogContentText>
                 }
                 {(associatedList.playlist.length >0 || associatedList.channel.length >0  )&&
                  <DialogContentText id="alert-dialog-slide-description">
                   Your video/audio is included in {associatedList.playlist.length} playlists and {associatedList.channel.length} channels. Archiving/Deleting this content will remove any reference to the media from playlists and channels. This operation cannot be undone. Do you wish to continue?
                  </DialogContentText>
                 }
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes delete
                </Button>
                <Button onClick={handleDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
             <Dialog
              open={archiveOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleArchiveDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Archive Video"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Are you sure you want to archive this video?
                  Existing references within embedded players may show a deactivated message.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archiveVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes archive
                </Button>
                <Button onClick={handleArchiveDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
        </div>
    )
  }
  else return null;
}

export default withRouter(Search);
