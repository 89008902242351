import React, { useState, useEffect, Component } from 'react';

import Amplify, { API, graphqlOperation, Auth, Hub, Logger, Cache } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Route, Switch } from 'react-router' // react-router v4/v5;
import { HashRouter } from 'react-router-dom'
import styled, { cx } from 'react-emotion';

// import Counter from '../parts/Counter';
import Header from '../parts/Header';
import Navigator from '../parts/Navigator';
import Home from './Home';
import Player from './Player';

import UserFavorites from './UserFavorites'
import UserHistory from './UserHistory'
import UserLikes from './UserLikes'
import UserSubscriptions from './UserSubscriptions'
import UserPlaylists from './UserPlaylists'
import Recommended from './Recommended'
import ErrorNotFound from './ErrorNotFound'
import Footer from '../parts/Footer.js';
import Channel from './Channel.js';
import Podcast from './Podcast.js';
import Dashboard from './Dashboard.js';
import Collection from './Collection.js';
import CollectionPage from './CollectionPage.js';
import Reporting from './Reporting.js';
import Videopage from '../parts/videopage/Videopage.js';
import Search from '../parts/VideosSearch.js';
import Splash from './Splash.js';
import editVideo from '../admin/editVideo.js'
import editPlaylist from '../admin/editPlaylist.js'
import editAudio from '../admin/editAudio.js'
import authorVideos from '../admin/authorVideos.js'
import authorPlaylist from '../admin/authorPlaylist.js'
import editChannel from '../admin/editChannel.js'
import editPodcast from '../admin/editPodcast.js'
import authorChannel from '../admin/authorChannel.js'
import authorAudio from '../admin/authorAudio.js'
import authorPodcast from '../admin/authorPodcast.js'
import * as Queries from '../graphql/queries.js'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactResizeDetector from 'react-resize-detector';
import * as utils from '../functions/util.js';
//eric
//import '../index.css';

//REDUX
import { connect } from 'react-redux'
import { logIn, logOut } from '../action/login'
// Amplify.Logger.LOG_LEVEL = 'DEBUG';
const mapStateToProps = state => ({
  isLogged: state.isLogged,
})
const mapDispatchToProps = dispatch => ({
  logIn: () => dispatch(logIn()),
  logOut: () => dispatch(logOut()),
})

const useStyles = makeStyles(theme => ({
  App: {
    backgroundColor: 'black',
    display: 'flex',
    // commented out the minHeight because it was adding white space above the
    // main content area on large monitors.
      //minHeight: '100vh',
      height: '100%',
    alignItems: 'flex-start',
    flex: '1 0 auto',
  },

  navigator: {
    position: 'relative',
    //minHeight: '100vh',
    minHeight:'10000px',
    alignItems: 'flex-start',
  },
  appContent: {
      //minHeight: '100vh',
      height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
        main: {
          flex: 1,
        },
        primaryAppBar: {
          zIndex: 1150,
        },
        secondaryAppBar: {
          top: 64,
          /*zIndex: 1140,*/
        },
   },
   header:{
      zIndex: 1,
   },
     mainContent: {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#000',
          color: '#fff',
            //minHeight: '100%',
          minWidth: '100%', //eric
          alignItems: 'flex-start',
           zIndex:0,

        },
        maincontainer: {
          width: '100%', //eric
          margin: 10,
          zIndex:0,
        },

}));
function App(props){
  //console.log(props)
 
  
  let loc =''
  const classes = useStyles();
  const [navHeight, setNavHeight] = React.useState();
  const storedPath = localStorage.getItem("path")
  if(storedPath!=''){
    window.location.href = storedPath
    localStorage.removeItem('path')
    localStorage.setItem("path", '');
  }
  useEffect(() => {
    const statedata = {}
    const title = "T-Mobile Clips"
   loc = document.location.href.split('#/')
  //  console.log(loc)
    const url = process.env.REACT_APP_HOME + loc[1]
    //console.log(url)
    console.log(props)
   // window.history.replaceState(statedata, title, url);
   }, [document.location.href]);

  const onResize = (width, height) => {
     setNavHeight(height)
  }

    return(
     <HashRouter>
     <div className={classes.App} style={{height: '100%',}}>
    <CssBaseline/>
    <div className={classes.appContent} style={{height: '100%',}}>

      <Header  className={classes.header} />
      <main className={classes.mainContent} style={{height: '100%',}}>
         <Navigator class='nav' height={navHeight} />
          <div  className={classes.maincontainer}>
  <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
              <Switch>
                <Route exact path="/" title="home" component={Home}/>
                <Route path="/Favorites" component={UserFavorites} />
                <Route path="/History" component={UserHistory} />
                <Route path="/Likes" component={UserLikes} />
                <Route path="/Subscriptions" component={UserSubscriptions} />
                <Route path="/Playlists" component={UserPlaylists} />
                <Route path="/Watch" component={Player} />
                <Route path="/Channel" component={Channel} />
                <Route path="/Podcast" component={Podcast} />
                <Route path="/Search" component={Search} />
                <Route path="/Recent" render={(props) => <Videopage {...props} page={"recent"} title={"Recently Added Videos"} />} />
                <Route path="/Trending" render={(props) => <Videopage {...props} page={"trending"} title={"Trending Videos"} />} />
                <Route path="/Recommended" render={(props) => <Videopage {...props} page={"recommended"} title={"Recommended Videos"} />} />
                <Route path="/Collection" component={Collection} />
                <Route path="/CollectionPage" component={CollectionPage} />
                <Route path="/editVideo" component={editVideo}  />
                <Route path="/authorVideos" component={authorVideos} />
                <Route path="/editplaylist" component={editPlaylist} />
                <Route path="/authorPlaylist" component={authorPlaylist} />
                <Route path="/editChannel" component={editChannel} />
                <Route path="/authorChannel" component={authorChannel} />
                <Route path="/authorAudio" component={authorAudio} />
                <Route path="/editAudio" component={editAudio} />
                <Route path="/authorPodcast" component={authorPodcast} />
                <Route path="/editPodcast" component={editPodcast} />
                 <Route path="/analyticDashboard"component={Dashboard} />
                 <Route path="/reporting"component={Reporting} />

                <Route path="*" component={ErrorNotFound} />

              </Switch>
         <Footer />
        </div>
      </main>
    </div>
  </div>
 </HashRouter>
      )
};


export default App;
