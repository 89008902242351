import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';

 const styles = {
    unlike:{
       color:'none',
       cursor:'pointer', 
       "&:hover": {
         textDecoration:'underline'
        },
    },
    like:{
      color:'#E20074',
       cursor:'pointer',
        "&:hover": {
         textDecoration:'underline'
        },
    }
  }
  
function More(props) {
  const { classes } = props;
 console.log(props)
  return (
      <Grid container onClick={props.menuFunc} alignItems="center" style={{flexWrap: 'nowrap', marginLeft: '1em'}} className={classes.unlike}>
        <MoreVert /> More
      </Grid>


  );
}



export default withStyles(styles)(More);
