import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import './Channel.css';
import Wrap from '../../functions/Wrap.js';

//col channel-no-padding m-0

function ChannelFeature(props){
  console.log(props)
  return (
      <div id="channel-feature" name="content-row" class="content-row content-row flex-grow-1">              
        <div class="channel-feature-component d-flex flex-row max-w flex-wrap flex-lg-nowrap">                
          <div class="channel-feature-thumb">
            <img class="" src={props.videosthumbnail} alt="" style={{maxWidth: '414px'}} />
            <div class="channel-feature-video-duration">3:02</div>
          </div>
          <div class="mb-md-4 ml-1 mt-md-4 pl-2 pl-sm-0" style={{maxWidth: '800px'}}>
            <h1 class="channel-feature-title SprintSansWeb-Light">{props.videos.title}</h1>
            <span class="channel-feature-details dot-spacer">1.5K Views</span>
            <span class="channel-feature-details">2 Months ago</span>
            <p class="channel-feature-description">{props.videos.description}</p>
          </div>
        </div>              
      </div>
  )
}

export default ChannelFeature;