import React, { useState, useEffect,Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { Cache} from 'aws-amplify';
import './Channel.css';
import Wrap from '../../functions/Wrap.js';
import * as utils from '../../functions/util.js';
import getUserProfile from '../../functions/getUserProfile.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { refresh } from '../../action/refresh'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//col channel-no-padding m-0


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const mapStateToProps = state => ({
  store: state.store
})
const mapDispatchToProps = dispatch => bindActionCreators({

 refresh: () => dispatch(refresh),
}, dispatch)
 const styles = {
    on:{
       color:'none',
       cursor:'pointer'
    },
    off:{
      color:'#E20074',
       cursor:'pointer'
    }
  }
  
 
function PodcastHeader(props){
  console.log(props)
  const { classes } = props;

  const [sub, setSub] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [podcasters, setPodcasters] = React.useState([]);
  const [ready, setReady] = React.useState(false);

  const writeToCLipboard = (expr) => {
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 71
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isFirefox = typeof InstallTrigger !== 'undefined';let content 
    switch (expr) {
      case 'id':
        content = props.id
        break;
      case 'share':
        content = document.location.href
        break;
    }
     console.log(content) 
     navigator.clipboard.writeText(content)
    if (isChrome || isFirefox){
      navigator.clipboard.writeText(content)
      //console.log(content)
    } 
    else if (isIE) {
      navigator.clipboard.writeText(content)
        window.clipboardData.setData('Text', content);
       // navigator.clipboard.writeText(content)
        document.execCommand('copy');
    }
}

  const subscribeChnanel = ()=>{
    utils.subscribeToPodcast(props).then(function(){
    });
  }

  const unsubscribeChnanel = (id)=>{
    utils.unsubscribeToPodcast(props.id).then(function(){
    });
  }
 
  const handleClick = event => {
    console.log(event)
    setAnchorEl(event.currentTarget);
  };

 const toggleSub =() =>{
    setSub(!sub)
  }

 const subHandle = ()=>{
    sub? subscribeChnanel():unsubscribeChnanel()
    toggleSub();
  }
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  const videoMenu = ()=> {
    return(
      
       <StyledMenu
         id="customized-menu"
         anchorEl={anchorEl}
         keepMounted
         open={Boolean(anchorEl)}
         onClose={handleClose}
       >
           <StyledMenuItem onClick={()=>subHandle()}>
             <ListItemText primary={!sub?'Unfollow':'Follow'} />
         </StyledMenuItem>  
           <StyledMenuItem onClick={()=>writeToCLipboard('id')}>
             <ListItemText primary={"Copy Podcast ID"} />
         </StyledMenuItem>
           <StyledMenuItem onClick={()=>writeToCLipboard('share')}>
             <ListItemText primary={"Share Show"} />
         </StyledMenuItem>
     
        
       </StyledMenu>
      )
  }
  useEffect(() => {
    let tmp = []
    let num = 0;
    
    for( const val of props.podcaster){
      utils.getUserDetailByID2(val).then(result => {
        num++
         if(result.data.searchUsers.items.length>0){
          const name = result.data.searchUsers.items[0].givenName + ' ' + result.data.searchUsers.items[0].familyName + (num==1?' , ':" ")
          console.log(name)
          tmp.push(name)
         }
         console.log(num)
         if (num==2) {  
          setPodcasters(tmp)
          setReady(true)
        }
      })
      console.log(podcasters)
     
   }
  console.log('here')
  
   console.log(podcasters)
  
   }, []);


  return (
  
    <div style={{width: '100%',display:'contents'}}>
       
      <div
      
        style={{
          width: '100%', display: 'InlineFlex'
        
        }}>
          <div style={{display:'InlineBlock'}}>
        <div   style={{
         // backgroundImage: 'url('+props.bannerArt +')',
         marginBottom: 10,
         marginRight: 10,
          float:'left',
          display: "inlineFlex"
        }}>
          <img class="channel-icon" src={props.art} />
        </div>
        <div style={{display:'InlineBlock', marginLeft:10}}>
         <h1 class="SprintSansWeb-Light drop-shadow">{props.title}</h1>
          
          <p class="channel-description">{props.description}</p>
          <p class="channel-description" style={{color:'#E20074'}}>
                {podcasters.map((podcaster, i) => <span key={i}>{console.log('here')}{podcaster}</span>)}
          </p>
         
        </div>
        </div>
        
      </div>
      <div style={{display:'Block', float:'left', borderBottom: 'solid 1px #E20074', marginBottom:10, width: '100%', display: 'InlineFlex'}}>
        <p  style={{display:'Block', float:'left',  width: '100%'  }}>
              <span> {props.showButton &&   <button   class="podcast-subscribe-button drop-shadow" onClick={()=>{subHandle()}} >{!sub?'Unfollow':'Follow'}</button>}</span>
              <span style={{cursor:'pointer'}} onClick={handleClick}><MoreVertIcon  /></span>
        </p>
        <p  style={{display:'Block', float:'left',   }}>
           <h4 class="SprintSansWeb-Light drop-shadow">Episodes</h4>
        </p>
        < p style={{display:'Block', float:'right',   }}>
          <span>{props.subscribers || 0} Subscribers</span>
              <span>Last update {utils.formatDate(props.createdAt)}</span>
        </p>
        </div>
        {videoMenu()}
    </div>
      
  )
  
}

//export default  withStyles(styles)(ChannelHeader);

export default compose(
  withStyles(styles, {
    name: 'ChannelHeader',
  }),
  connect(null, mapDispatchToProps),
)(PodcastHeader);