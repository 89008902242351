import React from 'react';
import './NewPlaylist.css';

const NewPlaylist = (props) =>
{
    const handelClick=()=>{
        props.redirect('/editPlaylist')
    }
    return (
        <button class="new-playlist" onClick={handelClick}>Create new</button>
        )
}

export default NewPlaylist;
