import React, { useState , useEffect } from 'react';
//import FavoriteItem from './FavoriteItem.js';
//import './FavoriteItem.css';

import VideoCard from '../VideoCard.js';
import Age from '../../functions/Age.js';
//import Videos from '../../sampleData/playlist.js';
import { Link } from 'react-router-dom';
import getUserProfile from '../../functions/getUserProfile.js';
import * as utils from '../../functions/util.js';

 var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isFirefox = typeof InstallTrigger !== 'undefined';

const copyVideoID=(id)=>{
  navigator.clipboard.writeText(id)
   if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(id)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }

function Favorites(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  console.log(props)
  const [Videos, setVideos] = useState([]);
  let data
  const [finishLoading, setFinish] = useState(false);

  useEffect(() => {
     utils.getFavorite().then(function(result) {
          console.log(result)
          data =result.data.searchFavouritess.items

          let VideosArray = [];
          let playlistItem = data
           playlistItem.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          let numloaded = 0;
          console.log(data)
          for (var value of playlistItem) {
            var obj = {};
            obj.id = value.videoId;
            obj.video_title = value.title;
            obj.video_desc = value.description;
            obj.createdAt = value.createdAt;
            obj.poster_image_loc  = value.thumbnail
            obj.channel = {}
           obj.duration = utils.formatDuration(value.videoDuration)
           const date = new Date(value.createdAt)
           obj.createdAt  = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
            VideosArray.push(obj)


          }
         setVideos(VideosArray)
            setFinish(true)
        });
   }, []);

  //const Videos = null;
    return (
      <div class="thumbnailContentBlock">
          <div class='videoThumbRow' style={{marginLeft: '0'}}>
            {Videos.map(video =>
            <div>
              <div class="activity-date">{video.createdAt}</div>
              <VideoCard
                activity = {video.createdAt}
                image = {video.poster_image_loc}
                title = {video.video_title}
                views = {video.views}
                age = {Age(video.createdAt)}
                duration = {video.videoDuration}
               // {/*channel = {video.channel.channel_title}*/}
                id = {video.id}
                redirect = {props.redirect}
                noDate={true}
                  videoMenu={[
                      {name:'Copy Video ID', func:copyVideoID},
                      ]}
              />
             </div>
                )}
          </div>
        </div>
    )
}

export default Favorites;
