import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Avatar, TextField, Button, MenuItem } from '@material-ui/core';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateProfilePic } from '../action/profile'
//import profile from '../sampleData/profile.js';
import Wrap from '../functions/Wrap.js';
import Snackbar from '@material-ui/core/Snackbar';
import SnackMessage from './SnackMessage';
import Amplify, { Auth, API, graphqlOperation, Cache, Storage } from 'aws-amplify';
import * as utils from '../functions/util.js';
import { DropzoneArea } from 'material-ui-dropzone'
//eric
import { makeStyles } from '@material-ui/core/styles'; //not sure this is needed 
import Tooltip from '@material-ui/core/Tooltip';
import './buttons/buttonStyles.css';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Uploader from '../parts/Uploader.js'
import CopyIcon from '../images/iconJS/Copy.js';

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const roles = [{
    value: 'Consumer_Retail_Sales',
    label: 'Consumer Retail Sales',
  },
  {
    value: 'Consumer_Telesales',
    label: 'Consumer Telesales',
  },
  {
    value: 'Business_Sales',
    label: 'Business Sales',
  },
  {
    value: 'Consumer_Indirect_Sales',
    label: 'Consumer Indirect Sales',
  },
  {
    value: 'Customer_Care_Sprint',
    label: 'Customer Care Sprint',
  },
  {
    value: 'Customer_Care_Vendor',
    label: 'Customer Care Vendor',
  },
  {
    value: 'Network',
    label: 'Network',
  },
  {
    value: 'IT',
    label: 'IT',
  },
  {
    value: 'HR',
    label: 'HR',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Other_Sprint',
    label: 'Other Sprint',
  },
  {
    value: 'Other_Non-Sprint',
    label: 'Other Non-Sprint',
  },


];
const styles = {
  font: {
    color: '#ccc',
    marginTop: 20,
    //paddingRight: 5,
    fontSize: 16,
  },
  profileCard: {
    backgroundColor: '#272727',
    //margin: '10px',
    padding: '10px',
    marginTop: '40px',
    borderColor: '#fff 1px solid',
    boxSizing: 'border-box',
    width: '100%',
    //width: '1130px', // force width of container - need to add media query
  },
  textField: {
    width: '100%',
  },
  textFieldInput: {
    width: '90%',
    backgroundColor: 'black',
    padding: '10px',
    border: '1px solid rgba(226, 0, 116, 0.5)',
  },
  content: {
    position: 'relative',
    top: '-50px',
  },
  alignRight: {
    textAlign: 'right',
    width: '80%'
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
    border: 'white solid 3px',
  },
  yellowDivider: {
    backgroundColor: '#E20074',
    height: '2px',
    display: 'flex',
    minWidth: '100%',
    marginTop: '12px',
    marginBottom: '12px',
  },
  menu: {
    width: 200,
  },
  paperRoot: {
    margin: 10,
    // backgroundColor:'#272727'
  },
  paperRootEdit: {
    margin: 10,
    backgroundColor: '#ccc'
  },
  dropzone: {
    color: "#E20074",
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box',

  },
};

const mapStateToProps = state => ({
  isLogged: state.isLogged,
  isMenuOpen: state.isMenuOpen,
  routes: state.router,
  store: state.store
})


const mapDispatchToProps = dispatch => bindActionCreators({
  updateProfilePic: () => dispatch(updateProfilePic),
}, dispatch)

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    backgroundColor: '#272727;',
  },
  dropzone: {
    color: "#E20074",
    fontSize: "12px",
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
}));
const userProfile = Cache.getItem('userProfile')
//console.log(userProfile)
const signOut = () => {
  Auth.signOut({ global: true })
    .then(data => console.log(data))
    .catch(err => console.log(err));
}
const action = (
  <Button size="small" onClick={signOut}>
    Log out
  </Button>
);

//eric
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function UserProfileCard(props) {
  //console.log(props)
  const muiclasses = useStyles();
  const profile = Cache.getItem('userInfo')
  // console.log(profile)
  //profile.biography = "add you bio"
  //  console.log(profile)
  const { classes } = props;
  // const profilePhto = `${process.env.REACT_APP_CLOUDFRONT_URL}${profile.profilePhotoURL}`
  const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
  // const photoPath = profile.profilePhotoURL.split('.')
  // const lastIndex = profile.profilePhotoURL.lastIndexOf('.');

  //const path=  profile.profilePhotoURL.substr(0, lastIndex) + '.jpg' 
  const profilePhoto = `https://${server[2]}/fit-in/100x100/filters:format(jpeg)/public/${profile.profilePhotoURL}`
  console.log(profilePhoto)
  const [photoURL, setPhotoURL] = React.useState(profilePhoto);
  const [myprofile, setValues] = React.useState({
    bio: profile.biography,
    interest: profile.interests,
    myRole: profile.groupName,
    email: profile.email,
  });
  const myOriginalRole = profile.groupName
  const [init, setInit] = React.useState(true)
  const [showSaveButton, showSave] = React.useState(false)
  const [showMessage, showMsg] = React.useState(false)
  const [DialogOpen, setDialogOpen] = React.useState(false)
  const [editable, setEditable] = React.useState(false)
  const [isVendor, setIsVendor] = React.useState(false)
  console.log('UserProfileCard')
  useEffect(() => {
    console.log('profile card use effect')
    setInitProfile()

  }, []);
  
  function handleCopyID (id) {
      //console.log('copy id')
      navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(id)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }
  }

  function setInitProfile() {
    const profile = Cache.getItem('userInfo')
    console.log(profile)
    if (profile.employeeType == 'N') setIsVendor(true)

    if (profile.biography == '' || profile.biography === null || profile.biography === 'null' || profile.biography === 'undefined') profile.biography = "Add your biography"
    if (profile.interests[0] == '' || profile.interests === null || profile.interests[0] === 'null' || profile.interests[0] == "undefined") profile.interests = "Add your Interest"

    setValues({ ...myprofile,
      id: profile.id,
      bio: profile.biography || '',
      interest: profile.interests,
      myRole: profile.groupName,
      email: profile.email,
    })

  }
  async function saveProfile() {
    let mut

    if (!isVendor) {
      mut = `mutation UpdateUser {
        updateUser(input:{
          id:"` + profile.id + `"
          biography:"` + myprofile.bio + `"
          groupName:"` + myprofile.myRole + `"
          interests:"` + myprofile.interest + `"
        })
        {
          id
          biography
          groupName
          interests
        }}`
    }
    else {
      mut = `mutation UpdateUser {
        updateUser(input:{
          id:"` + profile.id + `"
          biography:"` + myprofile.bio + `"
          email:"` + myprofile.email + `"
          interests:"` + myprofile.interest + `"
        })
        {
          id
          biography
          groupName
          interests
        }}`

    }
    console.log(mut)
    const updateUser = await API.graphql(
      graphqlOperation(mut, { "biography": myprofile.bio })
    );
    let newprofile = profile
    newprofile.interests = myprofile.interest
    newprofile.biography = myprofile.bio
    newprofile.email = myprofile.email
    Cache.setItem('userInfo', newprofile);
    // Cache.setItem('userInfo', 'interests', profile.interest);
    if (myprofile.myRole != myOriginalRole) {
      // utils.updateGroup(myOriginalRole, myprofile.myRole)
      //showMsg(true);
    }
    setEditable(false);
    //setValues({ ...myprofile, editable: false })
    showSave(false)
    setInit(false)
  }

  function handleCancel() {

    setInitProfile()
    setEditable(false);
    // setValues({ ...myprofile, editable: false })
  }

  function handleEdit() {
    setEditable(true);
    // setValues({ ...myprofile, editable: true })
  }

  const handleUpdatePhoto = () => {
    setDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const handleSave = (file) => {
    let user = Cache.getItem('userInfo')
    let finalPhoto
    let hasPhoto = false;
    console.log(file)
    let extension = file[0].name.split('.')
    extension = extension[1].toLowerCase()
    const tmp = user.email.split('@')
    const emailRoot = tmp[1].split('.')
    let filename = "profile_images/" + tmp[0] + '_' + emailRoot[0] + "." + extension
    filename = filename.replace(/ /g, "_");
    console.log(filename)
    Storage.put(filename,
        file[0], {
          progressCallback(progress) {
            // setPosterCompleted((progress.loaded/progress.total)*100);
          },
        })
      .then(result => {
        utils.updateProfilePhoto(filename).then(result => {
          finalPhoto = process.env.REACT_APP_CLOUDFRONT_URL + result.data.updateUser.profilePhotoURL
          // console.log(finalPhoto)

          setPhotoURL(finalPhoto)

          props.updateProfilePic();
          // window.location.reload(true)
        })
      })
      .catch(err => console.log(err));

    setDialogOpen(false)
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    showMsg(false)
  }

  const handleChange = name => event => {
    showSave(true)

    console.log('name ' + event.target.name + ' event ' + event.target.value)
    if (event.target.name == 'email') {
      showSave(false)
      if (utils.validateEmail(event.target.value)) {
        showSave(true)
      }
      else {
        showSave(false)
      }
    }
    const { name, value } = event.target
    setValues({ ...myprofile, [name]: value })

  };

  let thumbJSX =
    <Paper  className={classes.profileCard} square={true}>
        <Snackbar
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <SnackMessage
            action= {action}
            onClose={handleClose}
            variant="info"
            message="You have changed your group, please log out and log back in to see the changes"
          />
        </Snackbar>
        <div className={classes.content}>
        <Grid container direction='row' alignItems="flex-end" sm={6}>
         <Grid item>
           <Avatar  src={`${photoURL}`}  className={classes.bigAvatar} alt="Profile Photo" />
        </Grid>
         <Grid item onClick={handleUpdatePhoto} style={{color: "#ccc", cursor: "pointer"}}> Update Photo</Grid>
          </Grid>
          <Typography  className={classes.font} variant="h2" component="h2" style={{fontSize: '30px', fontWeight: "100", }}>
            {profile.givenName} {profile.familyName}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
            
              <Typography  className={classes.font}  component="p">
                {/*{profile.jobTitle} | */}{profile.email}
              </Typography>
            
          
            </Grid>
            <Grid container xs={6} className={classes.font} direction='column'>
              <div > Clip User ID: {myprofile.id} <span style={{cursor: "pointer"}} onClick={()=>{handleCopyID(myprofile.id)}}><CopyIcon  /></span></div>
              <div>Department: {myprofile.myRole}</div>
            </Grid>
            <Grid container xs={6} spacing={6}  >
             
               
                 {/*
                 {editable && isVendor &&
                  <Typography className={classes.font}>
                    {myprofile.myRole}
                  </Typography>
                }
               
                {editable && !isVendor &&
                  <TextField
                    id="standard-select--native"
                    select
                    label=""
                    name='myRole'
                    //className={classes.textField}
                    style={{color:'white', backgroundColor:'#272727', border:'solid 1px #E20074'}}
                    value={myprofile.myRole}
                    onChange={handleChange('myRole')}
                    InputProps={{
                      //className: classes.textFieldInput
                    }}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {roles.map(option => (
                      <option 
                        key={option.value} 
                        value={option.value}
                        style={{color:'white', backgroundColor:'black', fontFamily: 'sans-serif'}}
                      >
                        {option.label}
                        
                      </option>
                    ))}
                  </TextField>
                }
                */}
            </Grid>
          </Grid>
          <div className={classes.yellowDivider}></div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <span style={{maxWidth: '150px'}}>{'Tell us something about yourself.'}</span>
                  </React.Fragment>
                }
              >
                <Typography  className={classes.font}  variant="h3" component="h3" style={{fontSize: '24px', fontWeight: '100', display: 'inline-block'}} >
                  About Me
                </Typography>
              </HtmlTooltip>
                {!editable &&
                  <Typography  className={classes.font}>
                    {myprofile.bio}
                  </Typography>
                }
                {editable &&
                  <TextField
                    id="standard-name"
                    name='bio'
                    onChange={handleChange()}
                    className={classes.textField}
                    value={myprofile.bio}
                    InputProps={{
                       className: classes.textFieldInput
                    }}
                    margin="normal"
                  />
                }
            </Grid>
            <Grid item xs={6}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Interests</Typography>
                    <span style={{maxWidth: '150px'}}>{'Adding interests helps us recommend videos for you.'}</span>
                  </React.Fragment>
                }
              >
                <Typography className={classes.font} variant="h3" component="h3" style={{fontSize: '24px', fontWeight: '100', display: 'inline-block'}}>
                  Interests
                </Typography>
              </HtmlTooltip>
              {!editable &&
                <Typography className={classes.font}>
                  {myprofile.interest}
                </Typography>
              }
              {editable &&
                <TextField
                  id="standard-name"
                  name = 'interest'
                  onChange={handleChange()}
                  className={classes.textField}
                  value={myprofile.interest}
                  InputProps={{
                    className: classes.textFieldInput
                  }}
                  margin="normal"
                />
              }
            </Grid>
          </Grid>
      </div>
    
      <div className={classes.alignRight}>
        {!editable &&
          <Tooltip title="Edit your profile" aria-label="edit your profile">
            <Button 
             color="primary" 
            variant="contained" 
             size="small" 
              disabled ={editable } 
              //className={classes.button}
              //className={`classes.button ${'form-edit-button'}`}
              className={'buttonPrimary'}
              onClick={handleEdit}>
              Edit bio
            </Button>
          </Tooltip>
        }
       
       <span>
       {showSaveButton && editable&&
          <Tooltip title="Save changes to your profile" aria-label="save changes to your profile">
            <Button 
            color="primary" 
            variant="contained" 
            size="small" 
            //className={classes.button}
            className={'buttonPrimary'}
            onClick={saveProfile}> 
            Save changes
            </Button>
          </Tooltip>
        }
        {editable &&
          <Tooltip title="Cancel changes to profile" aria-label="cancel changes to profile">
            <Button 
            color="primary" 
            variant="contained" 
            size="small" 
            //className={classes.button}
            className={'buttonCancel'}
            onClick={handleCancel}>
            Cancel
            </Button>
          </Tooltip>
        }
          </span>
          
         
        </div>
       <Dialog open={DialogOpen} className={muiclasses.modal} onClose={ handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update profile photo</DialogTitle>
        <DialogContent>
        {/*
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
          */}
          
        <Uploader type="image" func={handleSave}  filesize={1073741274 } />
        {/*
        <DropzoneArea  
                dropzoneText="Drag your image in here"
                acceptedFiles={['.gif','.png','.jpg']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={3000000}
                onChange={handleSave.bind(this)}
                  dropzoneClass={muiclasses.dropzone}
                />
                */}
        </DialogContent>
        {/*
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
        */}
      </Dialog>
                
    </Paper>;


  return (
    <Wrap>{thumbJSX}</Wrap>
  );
}

UserProfileCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default withStyles(styles)(UserProfileCard);

export default compose(
  withStyles(styles, {
    name: 'userProfileCard',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(UserProfileCard);
