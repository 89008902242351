import * as React from "react";

function SvgCopy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.818" height="16" viewBox="0 0 13.818 16">
  <path id="Path_1" data-name="Path 1" d="M14.364,15.545h-8V5.364h8m0-1.455h-8A1.455,1.455,0,0,0,4.909,5.364V15.545A1.455,1.455,0,0,0,6.364,17h8a1.455,1.455,0,0,0,1.455-1.455V5.364a1.455,1.455,0,0,0-1.455-1.455M12.182,1H3.455A1.455,1.455,0,0,0,2,2.455V12.636H3.455V2.455h8.727Z" transform="translate(-2 -1)" fill="#fff"/>
</svg>
  );
}

export default SvgCopy;