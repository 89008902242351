import React, { Component } from "react";
import videojs from "video.js";
import { connect } from 'react-redux'
import { videoEnded, videoStarted } from '../../action/video'
import { bindActionCreators } from 'redux'
import "./nuevo.min.js"
import "./videojs-tmo.css"
import queryString from 'query-string'

import hotkeys from "videojs-hotkeys";
import * as utils from '../../functions/util.js';

//import videoJsYoutube from "videojs-youtube";
//XAPI https://liveaspankaj.gitbooks.io/xapi-video-profile/content/statement_data_model.html
const mapStateToProps = state => ({

  routes: state.router,
  store: state.store
})
const mapDispatchToProps = dispatch => bindActionCreators({

  videoEnded: () => dispatch(videoEnded),
  videoStarted: () => dispatch(videoStarted),

}, dispatch)
let func
class VideoComponent extends React.Component {
  constructor(props) {
    super(props);
   func = props.toggleFunc
    console.log('%%%%%2ß')
    console.log(props.value)
    
    if(props.id.length==16){
      this.state = {
        id: "",
        currentTime: 0,
        maxPlayTime: 0,
        startTime: 0,
        startTimeISO: 0,
        endTime: 0,
        endTimeISO: 0,
        totalTimeToComplete: 0,
        firstPlayTime: "",
        paused: true,
        props: {},
        player: "",
        autoplay: true,
        hasSubtitle: typeof this.props.subtitle.length > 0 ? false : true,
      };
    }
      else{
      this.state = {
        id: "",
        currentTime: 0,
        maxPlayTime: 0,
        startTime: 0,
        startTimeISO: 0,
        endTime: 0,
        endTimeISO: 0,
        totalTimeToComplete: 0,
        firstPlayTime: "",
        paused: true,
        props: {},
        player: "",
        autoplay: true,
        hasSubtitle: typeof this.props.captions.length > 0 ? false : true,
      };
    }

    //  this.setState({obj: obj})
    // XAPI
    // https://liveaspankaj.gitbooks.io/xapi-video-profile/content/templates.html
    this.xapi = {
      actor: {
        mbox: "mailto:jlh@example.com",
        name: "Video User",
        objectType: "Agent"
      },
      verb: {
        id: "http://adlnet.gov/expapi/verbs/initialized",
        display: {
          "en-US": "initialized"
        }
      },
      timestamp: "2018-08-07T15:27:52.365Z",
      object: {
        definition: {
          type: "https://w3id.org/xapi/video/activity-type/video",
          name: {
            "en-US": "Ocean Life"
          },
          description: {
            "en-US": "A short video clip of ocean lifeforms, used by videojs.com as part of their demo."
          }
        },
        id: "http://vjs.zencdn.net/v/oceans.mp4",
        objectType: "Activity"
      },
      context: {
        contextActivities: {
          category: [{
            id: "https://w3id.org/xapi/video"
          }]
        },
        extensions: {
          "https://w3id.org/xapi/video/extensions/volume": 1
        },
        registration: "96094a33-cc66-4d9a-8810-a0850ae2a4e1"
      },
      id: "86c5b148-4462-4da9-a13f-b570c27fa049"
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id }, function() {});
   console.log(this.props)
   console.log("***")
    console.log(this.props)
    let transcriptIcon = '<span class=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="margin-top:3px"><path id="Path_1" data-name="Path 1" d="M4.778,3A1.772,1.772,0,0,0,3,4.778V17.222A1.772,1.772,0,0,0,4.778,19H17.222A1.772,1.772,0,0,0,19,17.222V4.778A1.772,1.772,0,0,0,17.222,3H4.778m0,1.778H17.222V17.222H4.778V4.778M6.556,6.556V8.333h8.889V6.556H6.556m0,3.556v1.778h8.889V10.111H6.556m0,3.556v1.778h6.222V13.667Z" transform="translate(-3 -3)" fill="#fff"/></svg></span><span class="vjs-control-text" aria-live="polite">Transcript</span>';
  
    this.setState({ props: this.props },
      function() {
        //console.log("state.props ready", this.props);
        this.player = videojs(
          //videojs(
          this.videoNode,
          this.state.props,
          function onPlayerReady() {
            // enable keyboard controls using videojs-hotkeys plugin
        
          
            this.player.hotkeys({
              volumeStep: 0.1,
              seekStep: 5,
              enableModifiersForNumbers: false
            });

            const isWindows = () => {

              let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
              
              return platform.indexOf('Win') > -1
              
              }
              
              let mouse = matchMedia('(pointer:fine)').matches;
              
              if(isWindows() || mouse){
              
              document.body.classList.add('no-touch');
              
             // LOG('add no-touch')
              
              }
           

            let obj = {}
            console.log(this.props)
            obj.id = this.props.id
            obj.type = (this.props.id.length == 12 || this.props.id.length == 13) ? 'video' : 'audio';
            obj.title = this.props.title
            obj.url = window.location.href
            obj.init = false;
            obj.played = false;
            obj.completed = false;
            obj.duration = parseInt(utils.hmsToSecondsOnly(this.props.duration)) || 0
            obj.timecode = 0;
            obj.pct = 0;
            obj.keywords = this.props.keywords
            obj.trackWatershed = true;
            obj.thumbnail = this.props.poster;
            obj.chapters = this.props.chapters;
            
            
           

            if(this.props.hasOwnProperty('transcript')){
              if(JSON.stringify(this.props.transcript) !== '{}'){
                if(this.props.transcript.url!='undefined'){
                  let button = videojs.getComponent('Button');
                  let transcriptButton = videojs.extend(button, {
                      constructor: function() {
                        button.apply(this, arguments);
                        this.controlText("Transcript");
                        this.addClass('vjs-icon-transcript');
                      },
                      handleClick: function() {
                        console.log(this.props)
                     func()
                      }
                  });
              //  configurations.transcript.default === true ? toggleTranscript() : false;
                  videojs.registerComponent('transcriptButton', transcriptButton);
                   this.player.getChild('controlBar').addChild('transcriptButton', {}, 12);
                    
                   let transcriptPlayerButton = document.querySelector('.vjs-icon-transcript');
                   transcriptPlayerButton.innerHTML = transcriptIcon ;
                }
              }
            }
            videojs(this.state.id).one(
              "ended",
              function() {
                // this .one event only happens one time.
                // send the message to the parent window that the video is complete.
                // target domain = '*' so this player can talk to any parent window that allows from the player's host domain.
                const message = 'complete';
                const targetDomain = '*';
                console.log('iframe:  sending message:  ' + message);
                window.parent.postMessage(message, targetDomain); //send the message and target URI

                //const now = new Date().toISOString();
                const now = new Date();
                this.setState({ endTime: now, endTimeISO: now.toISOString() },
                  function() {
                    console.log("state.endTime", this.state.endTime);
                    console.log("state.endTimeISO", this.state.endTimeISO);
                  }
                );
                let totalTimeToComplete =
                  (this.state.endTime.getTime() -
                    this.state.startTime.getTime()) /
                  1000;
                // ISO8601 Duration format - is it OK that this is in seconds?
                totalTimeToComplete = "PT" + totalTimeToComplete + "S";
                this.setState({ totalTimeToComplete: totalTimeToComplete },
                  function() {
                    console.log(
                      "state.totalTimeToComplete",
                      this.state.totalTimeToComplete
                    );
                  }
                );
                // XAPI - Complete verb requires duration.
                const XAPIDuration = this.player.duration();
                console.log(
                  "XAPIDuration",
                  XAPIDuration,
                  "Completed (ended)",
                  this.state.id,
                  now
                );
                obj.completed = true;
                obj.trackWatershed = true;
                obj.pct = 100;
                obj.timecode = Math.floor(this.player.currentTime())
                utils.sendEvent(obj)
              }.bind(this)
            );


            videojs(this.state.id).one(
              "play",
              function() {
                this.player.muted(false);
                this.props.videoStarted();
                // this .one event only happens one time.
                // XAPI Initiate - track Activity
                // XAPI Play - track Activity
                //const now = new Date().toISOString();
                // console.log(this)
                console.log(this.props)
                console.log(this.player.duration())
                this.player.currentTime(this.props.seek)

                //console.log('duration', events.duration);// check for timecode
                // if (checkVar(urlVars.t)) {if (events.duration > 0 && events.duration > Math.floor(urlVars.t)) {
                //      events.firstplay = true;events.timecode = urlVars.t;
                //      if (events.timecode > events.maxProgress) {events.maxProgress = events.timecode;}
                //console.log('timecode =', events.timecode);
                //        videojs('current_video').currentTime(events.timecode);}

                //   }
                const now = new Date();
                this.setState({ startTime: now, startTimeISO: now.toISOString() },
                  function() {
                    console.log("state.startTime", this.state.startTime);
                    console.log("state.startTimeISO", this.state.startTimeISO);
                  }
                );
                const createdAtDate = this.state.props.createdAt.slice(0, 10);
                const XAPIActivity =
                  "https://clips.sprint.com/xapi/activities/" +
                  this.state.id +
                  "-" +
                  createdAtDate;
                //console.log("First play event -", this.state.id, now);
                //console.log("XAPI Activity -", XAPIActivity);
                // console.log(this.props)
                console.log(this.state)



                obj.played = true;

                console.log(obj)
                utils.sendEvent(obj)
                  
                    //if(this.props.id.length ==12) utils.recordWatch(this.props.id, this.props.title, this.props.video_desc, this.props.poster, this.props.keywords)
                //else if(this.props.id.length ==16) utils.recordAudioListen(this.props.id, this.props.title, this.props.video_desc, this.props.poster, this.props.keywords)
              }.bind(this)

            );

            videojs(this.state.id).one(
              "loadedmetadata",
              function() {
                obj.init = true;
                console.log(obj)
                utils.sendEvent(obj)

              }.bind(this)

            );
            
            videojs(this.state.id).on(
              "firstplay",
              function() {
              
                 var tts= this.player.textTrackSettings;
                  tts.setValues({
                      "backgroundColor": "rgba(0,0,0,0.5)",
                      "backgroundOpacity": "0.5",
                  });
                  tts.updateDisplay();
              }.bind(this)
            );


            videojs(this.state.id).on(
              "pause",
              function() {
                this.setState(prevState => ({
                  paused: !prevState.paused
                }));
                // XAPI Pause - track Activity
                const currentTime = videojs(this.state.id).currentTime();
                console.log("pause event for", this.state.id, currentTime);
              }.bind(this)
            );

            videojs(this.state.id).on(
              "play",
              function() {
                this.setState(prevState => ({
                  paused: !prevState.paused
                }));
                this.props.videoStarted();
                //utils.recordWatch(this.props.id, this.props.title, this.props.video_desc, this.props.poster)
                // XAPI Play - track Activity
                console.log("play event for", this.state.id);
              }.bind(this)
            );

            videojs(this.state.id).on(
              "ended",
              function() {
                //console.log("ended event for", this.state.id)
                //console.log("video duration", XAPIDuration)
                this.props.videoEnded();
              }.bind(this)
            );

            let pctTracked = 0;
            var timeupdate = function timeupdate() {

              var percentsPlayedInterval = 10;
              var currentTime, duration, percent, percentPlayed, _i;

              currentTime = Math.round(videojs(this.state.id).currentTime());

              duration = Math.round(videojs(this.state.id).duration());
              percentPlayed = Math.round(currentTime / duration * 100);
              //console.log('percentPlayed',percentPlayed)
              if (percentPlayed % percentsPlayedInterval == 0) {
                if (pctTracked != percentPlayed) {
                  pctTracked = percentPlayed
                  obj.completed = false;
                  obj.timecode = currentTime
                  obj.pct = percentPlayed
                  obj.trackWatershed = false
                  console.log(obj)
                  if (percentPlayed != 100) utils.sendEvent(obj)
                }
              }

            }.bind(this); // end percent played

            videojs(this.state.id).on(
              "timeupdate",
              function() {
                timeupdate()

                //update the maxPlayTime if > currentTime
                // We can use maxPlayTime to bookmark user location.
                const currentTime = videojs(this.state.id).currentTime();
                this.setState({ currentTime: currentTime });
                //console.log("time", this.state.currentTime);
                //console.log("maxPlayTime", this.state.maxPlayTime);
                if (this.state.currentTime >= this.state.maxPlayTime) {
                  this.setState({ maxPlayTime: currentTime });
                }
              }.bind(this)
            );

            videojs(this.state.id).on(
              "seeked",
              function() {
                // XAPI Seek - Not sure we need to track this at this time.
                console.log("time", this.state.currentTime);
                console.log("seeked", this.state.id);
              }.bind(this)
            );
            
             const neuvoOption ={
                 shareMenu: false,
             }
            this.player.nuevo(neuvoOption)
             console.log(obj.chapters)
             if(obj.chapters !== undefined && obj.chapters[0] !== undefined && obj.chapters[0].file !== undefined){
           
            
            
           
            
         }
          }.bind(this)
        );
      }.bind(this)
    );
  }

  // destroy player on unmount
  componentWillUnmount() {
    // original code - I think I fixed this by binding (this) to the this.player = videojs(...)
    if (this.player) {
      // we need to send XAPI when the user terminates activity
      console.log("component - unmount xapi terminate event");
      //this.player.dispose();
    }
  }
  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player 
          style={{
            //maxWidth:this.props.width
            width: '100%',
            height: '',
            maxWidth: '854px',
            minWidth: '480px',
            paddingTop: '56%',
            display: 'flex',
            flex: '1',
          }}>
            {console.log(this.props.captions)}
          <video
            ref={node => (this.videoNode = node)}
            className="video-js vjs-16-9"
            id={this.props.vid}
            preload= 'auto'
            controls= ''
            playsinline= ''
            crossorigin= 'anonymous'
          >
      
        
           {this.props.captions?.map((caption) => (
             
                <track 
                
                src={caption.src+`?ie=`+ utils.generateID(6)}
                kind={caption.kind}
                srclng= {caption.language=='English'?'en':'es'}
                label={`${caption.label.charAt(0).toUpperCase()}${caption.language.slice(1)}`}
                default={(caption.language=='English'&&this.props.ccDefault?true:false)}
              >
               
              </track>
            


            ))}
            
        
       
            {this.props.chapters?.length>0 &&
              <track kind="chapters" src={this.props.chapters[0].src} srclang="en"/>
            
            }
              
          </video>
      
        </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoComponent);
