/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth, Storage, Cache } from 'aws-amplify';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { DropzoneArea } from 'material-ui-dropzone'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as utils from '../functions/util.js';
import TransferList from '../parts/transferList.js'
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import SnackMessage from '../parts/SnackMessage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import videoPlayer from '../parts/videoPlayer/VideoPlayer.jsx';
import queryString from 'query-string'
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import PodcastHeader from '../parts/Channels/PodcastHeader.js';
import Age from '../functions/Age.js';
import Backdrop from '@material-ui/core/Backdrop';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Fade from '@material-ui/core/Fade';
import VideoCard from '../parts/VideoCard.js';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import getChannel from '../functions/getChannel.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PlaylistItem from '../parts/Playlist/PlaylistItem.js';

const author = [], user = []

const styles = {
  form: {
    margin: 30,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth:800,


  },
  paper: {
      color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
   // boxShadow: shadows[5],
    padding: 20,
    minWidth:800,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  chip: {
    margin: 5,
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
  root: {
    backgroundColor: 'inherit'
  }
}
const CssTextField = withStyles({
  root: {

    '& label': {
      color: '#E20074',

    },
    '& label.Mui-focused': {
      color: '#E20074',
    },
    '& .MuiInput-underline:before': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiInput-underline:after': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#E20074',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E20074',
      },
    },
    
  },
  input: {
    color: '#fff',
  },
  OutlinedInput: {
    mulitiline: {
      height: 142,
    }
  }
})(TextField);
const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    backgroundColor: '#373737',
  },
  bar: {
    backgroundColor: '#83d042',
  },
})(LinearProgress);
const audiences =  ['Consumer_Retail_Sales',
  'Consumer_Telesales',
  'Business_Sales',
   "Business_Indirect_Sales",
  'Consumer_Indirect_Sales',
  'Customer_Care_Sprint',
  'Customer_Care_Vendor',
  'Network',
  'IT',
  'HR',
  'Finance',
  'Other_Sprint',
  'Other_Non-Sprint',
]


let numUpload, video
const today = new Date().toISOString();
const useStyles = makeStyles(theme => ({
  dropzone: {
     color:"#E20074",
     fontSize:"12px",
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
}));
let data, iconURL, thumbURL, channelID;
function editPodcast(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  const [Videos, setVideos] = useState([]);
  const { classes } = props;


  const vidObj = {};
  const [finishLoading, setFinish] = useState(false);
  const [enableCancel, setCancel] = useState(true);
  const [openSnack, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [uploadFinish, setUpload] = React.useState(true);
  const [channelCreated, setChannelCreated] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [editDetailMode, setEditDetailMode] = React.useState(false);
  const [instuction, setInstructionText] = React.useState("Podcast audio needs to follow Clips/Mobile standards. Optionally you can add artwork for each episode; Otherwise a placeholder image will be display. s");
  const [pageTitle, setPageTitle] = React.useState("Create a Podcast");
  const [chipData, setChipData] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [PlaylistModalOpen, setPlaylistModalOpen] = React.useState(false);
  const [playlist, setFoundPlaylist] = React.useState(null);
  const [videos, setFoundVideos] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [videoID, setVideoID] = React.useState('');
  const [playlistID, setPlaylistID] = React.useState('');
  const [bannerompleted, setBannerCompleted] = React.useState(0);
  const [iconcompleted, setIconCompleted] = React.useState(0);
  const [DialogOpen, setDialogOpen] = React.useState(false)
  const [completed, setCompleted] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [postercompleted, setPosterCompleted] = React.useState(0);
  const [subcompleted, setSubCompleted] = React.useState(0);
  const [fvideoURL, setVideo] = useState('');
  const [fthumbURL, setThumb] = useState('');
  const [fcaptionURL, setCaption] = useState('');

  const [values, setValues] = React.useState({
    id:"",
    title:"",
    description:"",
    thumbnail:"",
    primaryAuthor:"",
    secondaryAuthor:'',
    manager:"",
    iconImage:"",
    keywords:"",
    tags:'',
    podcastStatus:"deactivated",
    businessPartner: '',
    includeInClipsSearch:true,
    videos:{items:[]},
    playlists:{items:[]},
    featuredVideoTitle:'',
    featuredVideoDesc:'',
    prescribedAudience:[],
    requiredAudience: [],
    groupEmployee: true,
    groupVendedCare: false,
    groupConsumerIndirect: false,
    groupBusinessIndirect: false,
    groupTelesales: false,
  });

 const [newValues, setNewValues] = React.useState({});
 const muiclasses = useStyles();

  useEffect(() => {
    numUpload=0;
    const thisquery = queryString.parse(props.location.search)
    channelID = thisquery.id
    if (channelID == null) {
      const id = utils.generateID(7) //generate podcast ID
       setValues({ ...values, id: id });
       setEditMode(false)
       setEditDetailMode(true)
    }
    else {
      setEditMode(true)
      setChannelCreated(true)
      setPageTitle('Edit Podcast');
      setInstructionText('')
        //const channel = Cache.getItem('myChannel');
     utils.getPodcast(channelID).then(function(result) {
       console.log(result)

        let thisChannel=result.data.searchPodcastShowss.items[0];
        
        if(thisChannel.podcastStatus=="undefined")thisChannel.podcastStatus = "deactivated"
        console.log(thisChannel)
         thisChannel.groupsCanAccess.forEach(value => {
            thisChannel.groupEmployee = true
            if (value == "Vended_care") thisChannel.groupVendedCare = true;
            if (value == "Consumer_Indirect") thisChannel.groupConsumerIndirect = true;
            if (value == "Business_Indirect") thisChannel.groupBusinessIndirect = true;
            if (value == "Telesales") thisChannel.groupTelesales = true;
          })
          /*
          if(thisChannel.featureVideo!==null){
            thisChannel.featuredVideoTitle = thisChannel.featureVideo.title
            thisChannel.featuredVideoDesc = thisChannel.featureVideo.description
          }*/
         setValues(thisChannel)



         setFinish(true)
      });

    /*
      utils.getChannelbyID(channelID).then(value => {
        console.log(value)
        const channel = value.data.searchChannel

         if(channel.video == null ) channel.videos =[]
        setValues(channel)

      }, reason => { });
      */
    }

    // console.log(props)
    utils.listPodcastAuthors().then(function(result) {
      //  console.log(result)
        const getAuthor = result.data.SearchForUsers.items
        let obj
        author.length = 0;
        let num = 0;

        let authorList = getAuthor.filter(val => val.id.includes('Clips-User'))
      //  console.log(authorList)
        for (let value of authorList) {
          obj = {}
          num++;
          obj.label = value.givenName + ' ' + value.familyName
          obj.value = value.id
          author.push(obj)
        }
        author.sort((a, b) => a.label.localeCompare(b.label))
      if(!editMode) setFinish(true)
      /*
      utils.getAllUsers().then(function(result) {
        const getUser = result.data.searchUsers.items
        let obj
        for (let value of getUser) {
          obj = {}
          obj.label = value.givenName + ' ' + value.familyName
          obj.value = value.id
          user.push(obj)
        }
        
       // console.log(editMode)
       
        //console.log(user)
      })*/
      
      
    });
    console.log(values)
  }, []);

  const handleOwnerDelete = chipToDelete => () => {
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    setValues({ ...values, businessOwners: chipData });
  };

  const handleOwnerAdd = (val, label) => {
    let obj = { key: val, label: label }
    if ((chipData.findIndex(x => x.key === val)) === -1) chipData.push(obj)
    var tmp = []
    for (let value of chipData) {
      tmp.push(value.key)
    }
    console.log(tmp)
    setValues({ ...values, businessOwners: tmp });
    setChipData(chipData);
  };

  const handleCloseDialog=()=>{
    setDialogOpen(false)
  }

  const handleOpenDialog=()=>{
    setDialogOpen(true)
  }

  const removeFeatureVideo=()=>{
    setVideo('')
  }
  function validateForm() {
    console.log()
   

    if (values.title == '' || values.description == '' || values.tags == '' ) {
      setOpenError(true) 
      return false;
    }
    else return true
  }

  const handleVideoDrop = (files) => {
    if (files.length > 0) {
      const extension = files[0].name.split('.')
      const v = "vco/" + values.id + "/artwork." + extension[1]
      setUploading(true)
      let mimetype
      switch (extension[1]) {
        case 'jpg':
          mimetype = 'image/jpeg'
          break;
        case 'png':
          mimetype = 'image/png'
          break;
        case 'gif':
          mimetype = 'image/gif'
          break;
        default:
      }
      Storage.put(v, files[0], {
          contentType: mimetype,
          progressCallback(progress) {
            setCompleted((progress.loaded / progress.total) * 100);
          },
        })
        .then(result => {
          setUploading(false)
          setVideo(process.env.REACT_APP_CLOUDFRONT_URL + v)
          // setValues({ ...values,  featuredVideoURL:  process.env.REACT_APP_CLOUDFRONT_URL+v});
        })
        .catch(err => console.log(err));
    }
  }

  const handleThumbDrop = (files) => {
    if (files.length > 0) {
      setUploading(true)
      const extension = files[0].name.split('.')
      const plink = "vco/" + values.id + "/banner." + extension[1]
      let mimetype
      switch (extension[1]) {
        case 'jpg':
          mimetype = 'image/jpeg'
          break;
        case 'png':
          mimetype = 'image/png'
          break;
        case 'gif':
          mimetype = 'image/gif'
          break;
        default:
      }
      Storage.put(plink, files[0], {
          contentType: mimetype,
          progressCallback(progress) {
            setPosterCompleted((progress.loaded / progress.total) * 100);
          },
        })
        .then(result => {
          setUploading(false)
          setThumb(process.env.REACT_APP_CLOUDFRONT_URL + plink)
          // setValues({ ...values, featuredVideoThumb:  process.env.REACT_APP_CLOUDFRONT_URL+plink,});
        })
        .catch(err => console.log(err));
    }

  }

 

  const handleChange = name => event => {
    let val
  console.log(name + ' " ' + event.target.value)
   name == 'includeInClipsSearch' ||  name == 'groupVendedCare' || name == 'groupConsumerIndirect' || name == 'groupBusinessIndirect' || name == 'groupTelesales' ? val = event.target.checked : val = event.target.value
    if (name == 'groupsCanAccess' || name == 'keywords') {
      val = event.target.value.split(",")
    }

    if (name == 'owner') {
      handleOwnerAdd(val, event.currentTarget.getAttribute('name'));
      return;
    }
    setValues({ ...values, [name]: val });
    checkChanges(name, val)
  };

  function handlePrivacyChange(event) {
    let val = event.target.value
    setValues({ ...values, podcastStatus: val });
  }
  const setAudience = (val) => {
    console.log(val)
     setValues({ ...values, audienceList: val });
  }
  const checkChanges = (name, val) => {
    //console.log(name)
    // console.log(val)
    //console.log(values[name])
    if (val != values[name]) {
      if (editMode) {
        setNewValues({ ...newValues, [name]: val });
      }
    }
  }

  const handlePosterDrop = (files) => {
     if (files.length > 0) {
        let extension = files[0].name.split('.')
        extension = extension[extension.length - 1].toLowerCase()
        if (extension == 'jpeg') extension = 'jpg'
        let plink = thumbURL= "podcast/"+values.id + "/artwork." + extension
        //const filename = thumbURL = values.id + "/" + files[0].name
        Storage.put(plink, files[0], {
              contentType: files[0].type,
                  progressCallback(progress) {
                  setBannerCompleted((progress.loaded / progress.total) * 100);
                },
        })
          .then(result => { 
            if (editMode) utils.updatePodcastArt(values.id, 'art', process.env.REACT_APP_CLOUDFRONT_URL + plink)
            countUpload() })
          .catch(err => console.log(err));
     }
  }

  const handleIconDrop = (files) => {
     if (files.length > 0) {
        const extension = files[0].name.split('.')
      const plink =  iconURL="podcast/"+values.id + "/banner." + extension[1]
     // const filename = iconURL = values.id + "/" + files[0].name
      Storage.put(plink,
          files[0], { contentType: files[0].type,
            progressCallback(progress) {
            setIconCompleted((progress.loaded / progress.total) * 100);
          },
          })
        .then(result => { countUpload() })
        .catch(err => console.log(err));
     }
  }

  const countUpload=()=>{
    numUpload++;
     console.log(thumbURL)
    if (numUpload == 1) {
      setValues({ ...values, thumbnail: process.env.REACT_APP_CLOUDFRONT_URL + thumbURL, iconImage: process.env.REACT_APP_CLOUDFRONT_URL + iconURL })
      setUpload(false)
    }
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenError(false);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };
   const handlePlaylistModalClose = () => {
      setFoundPlaylist(null) //reset

    setPlaylistModalOpen(false);
  };
  
  const handleVideoChange = (e)=>{
     setVideoID(e.target.value)
  }
  const handleSearch = (e) => {
      if(videoID.length==12||videoID.length==13){
        utils.searchVideoByID(videoID).then(function(result) {
          //console.log(result)
          let VideosArray = [];
          let playlistItem = result.data.searchVideos.items[0]
            //console.log(playlistItem)
        
            var obj = {};
            obj.id = playlistItem.id;
            obj.video_title =playlistItem.title;
            obj.video_desc = playlistItem.description;
            obj.createdAt = playlistItem.createdAt;
            obj.poster_image_loc = playlistItem.thumbnail
            VideosArray.push(obj)
          
          setVideoID('')
          setFoundVideos(VideosArray)
          if (VideosArray.length == 0) { setErrorMsg('Video not found');
            setOpenError(true) }
        });
      }
      else{
          utils.searchAudioByID(videoID).then(function(result) {
           
          let playlistItem =  JSON.parse(result.data.searchForAudio)
         
          let VideosArray = [];
          var obj = {};
            obj.id =  playlistItem .id;
            obj.video_title =  playlistItem .title;
            obj.video_desc =  playlistItem .description;
            obj.createdAt =  playlistItem .createdAt;
            obj.poster_image_loc =  playlistItem .thumbnail
            VideosArray.push(obj)
  
          
          setFoundVideos(VideosArray)
          if (VideosArray.length == 0) { setErrorMsg('Video not found');
            setOpenError(true) }
        });
      }
  }
  
 const handlePlaylistChange =(e)=>{
    setPlaylistID(e.target.value)
 }
  const handlePlaylistSearch = (e) => {
      utils.searchPlaylistbyID(playlistID).then(function(result) {
        console.log(result)
        let playlistItem = result.data.searchPlaylists.items[0]
        setFoundPlaylist(playlistItem)
         });
  }


 const addVideoToPlaylist = () =>{
    
    values.videos.items.map(item => console.log(item))
    console.log(videos[0])
    if(values.videos.items.findIndex(x => x.videoId === videos[0].id) === -1 ){
      console.log(videos)
      let newObj = videos[0];
      if (!videos.videoId) {
        newObj["video"] = {
          id: videos[0].id,
          title: videos[0].video_title,
          description: videos[0].video_desc,
          thumbnail:videos[0].poster_image_loc,
        }
      }
      setValues({ ...values, videos:{ items: values.videos.items.concat(newObj)} }); //add
      if (videos[0].id.length ==12)   utils.addVideoToPodcast(newObj, values.id)
      else if (videos[0].id.length ==16)   utils.addAudioToPodcast(newObj, values.id)
      setFoundVideos([]) //reset
      handleModalClose(); //close
    }
    else {
      setErrorMsg('This video already exist in this channel');
      setOpenError(true)
    }
  }

  const removeEpisdoeFromPodcast = (id) => {
    console.log(values)
    if(id.length==12) setValues({ ...values, videos:{ items:values.videos.items.filter(video => video.videoId != id) }}); //remove
   else if(id.length==16)setValues({ ...values, audios:{ items:values.audios.items.filter(video => video.audioId != id) }}); //remove
    utils.removeEpisodeFromPodcast(id,channelID)
  }

  const getVideo = (video) => {
console.log(video)
return <Grid container direction='row' alignItems="center" >
                <VideoCard
                    image = {video.poster_image_loc||video.thumbnail}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    duration = {video.duration}
                    id = {video.id}
                    type={'static'}
                  />
                <Button color="secondary"
                   aria-label="add"
                    variant="contained"
                   onClick={addVideoToPlaylist} >
                        Add episode
                </Button>
              </Grid>
  }

  const addPlaylist = () =>{
    //console.log(values.playlists.items[0].id + ' ' +playlist.id)
     if(values.playlists.items.findIndex(x => x.id === playlist.id) === -1){
       console.log('add playlist')
        setValues({ ...values, playlists:{ items:values.playlists.items.concat(playlist) }}); //add
        utils.addPlaylistToChannel(playlist.id,channelID)
        setFoundPlaylist(null) //reset
        handlePlaylistModalClose(); //close
     }
    else {
      console.log('not save')
       //setErrorMsg('This playlist already exist in this channel');
      setFoundPlaylist(null) //reset
      handlePlaylistModalClose(); //close
    }
  }

  const removePlaylist =(id)=>{
    setValues({ ...values, playlists:{ items:values.playlists.items.filter(playlist => playlist.id != id) }}); //remove
    utils.removePlaylistFromChannel(id)
  }

  const getPlaylist = (playlist) =>{

    return(
      <PlaylistItem
                      id={playlist.id}
                      firstVideo={playlist.videoArray[0]}
                      thumbnail={playlist.videos.items[0].thumbnail}
                      title={playlist.title}
                      description={playlist.description}
                      date={utils.formatDate(playlist.createdAt)}
                      videos ={playlist.videos.items}
                      removeMe = {removePlaylist}
                      videoMenu={[
                      {name:'Remove from channel', func:removePlaylist},
                      ]}
                      haveMenu={true}
                    />
   )}

  const getVideoAdded = (video) => {
    //console.log(video.video.thumbnail)
    let v ={}
    if(video.hasOwnProperty('audio'))  v = video.audio
    else v=video
    return (

                <VideoCard
                    image = {v.poster_image_loc ||v.thumbnail}
                    title = {v.video_title||v.title}
                    views = {v.views}
                    age = {Age(v.createdAt)}
                    duration = {utils.formatDuration(v.videoDuration)}
                    id = {v.videoId||v.id||v.audioId}

                     videoMenu={[
                      {name:'Remove from podcast', func:removeEpisdoeFromPodcast},

                      ]}
                  />
            )
  }

  const handleSave = () => {
    setCancel(false)
    console.log(values)
    if(!editMode){
      setEditDetailMode(true)
      if (validateForm()) {
        utils.createPodcast(values,fvideoURL,fthumbURL,fcaptionURL).then(value => {
          setOpen(true); //if save succesfully, then show message
          setChannelCreated(true)
          setInstructionText('Add videos and playlist')
          props.history.push('/authorPodcast')
        }, reason => {
         // setOpenError(true)
          console.log(reason)
        });
      }
    }
    else{
      if (validateForm()) {
        utils.updatePodcast(values,fvideoURL,fthumbURL,fcaptionURL).then(value => {
          setOpen(true); //if save succesfully, then show message
          props.history.push('/authorPodcast')
        }, reason => {
         // setOpenError(true)
          console.log(reason)
        });
     }

    }
  }

  const handleExit =()=>{
     props.history.push('/authorPodcast')
  }

  if (finishLoading) {
    return (

      <div className={classes.form} style={{maxWidth: '1024px', margin: '12px',}} >

      <Typography variant="h4" gutterBottom>{pageTitle} </Typography>
      <Typography variant="p" gutterBottom>{instuction}</Typography>
      {editDetailMode &&
      <div>
      
      <Grid container sm={12} spacing={2}>
        <Grid item xs={12} sm={6}>
           <CssTextField
              id="filled-select-currency"
              select
              required
              fullWidth
              label="Primary Podcaster"

             value={values.podcaster}
              onChange={handleChange('podcaster')}
              SelectProps={{
                MenuProps: {

                },
              }}

              margin="normal"
             variant="outlined"
            >
              {author.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
             <CssTextField
                id="filled-select-currency"
                select
                fullWidth
                label="Backup Podcaster"

               value={values.backupPodcaster}
                onChange={handleChange('backupPodcaster')}
                SelectProps={{
                  MenuProps: {

                  },
                }}

                margin="normal"
                 variant="outlined" 
              >
                {author.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CssTextField>
          </Grid>
            
        </Grid>
        
       <Grid item xs={12} sm={12}>
          <CssTextField
            required
            id="title"
             fullWidth
            label="Podcast Title"
            placeholder='42 Characters or less is recommended'
            value={values.title}
            onChange={handleChange('title')}
             margin="normal"
             variant="outlined"/>
        </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <Typography variant="p" gutterBottom>Description*</Typography>
            <TextareaAutosize className={classes.descBox}
            placeholder="Providing a description is helpful for users to find and set expectaions for your content."
            aria-label="minimum height" rows={5} onChange={handleChange('description')} value={values.description} />
         </Grid>

           <Grid item xs={12} sm={6}>
            <Typography variant="p" gutterBottom>Tags*</Typography>
            <TextareaAutosize className={classes.descBox}
             placeholder="Enter tags seperated by a comma and space. Choose resuable tags that users are likely to search by. Tag similiar content with the same reusable tags.."
             aria-label="minimum height" rows={5}  onChange={handleChange('tags')}value={values.tags} />
           </Grid>


        </Grid>

         <Grid container spacing={6} xs={12} sm={12}>
         {/*
           <Grid item xs={12} sm={12}>
               <Typography variant="h5" gutterBottom>Images</Typography>
               <Typography variant="p" gutterBottom>For best results Banner images must be 1134x267 pixel JPG or PNG files. Icon images should be 160x160 pixels square - transparent background PNG.</Typography>
            </Grid>
            */}
          <Grid container item spacing={6} xs={12} sm={12}>
           <Grid item xs={12} sm={6}>

            <Typography variant="p" gutterBottom>Artwork*</Typography>
               <BorderLinearProgress variant="determinate" color="secondary" value={bannerompleted}/>
               <DropzoneArea
                dropzoneText="Drop artwork here"
                  acceptedFiles={['.gif','.png','.jpg']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={3000000}
                onChange={handlePosterDrop.bind(this)}
                dropzoneClass={classes.dropzone}/>
          </Grid>
            <Grid item xs={12} sm={6}>

            <Typography variant="p" gutterBottom>Optional banner artwork</Typography>
             <BorderLinearProgress variant="determinate" color="secondary" value={iconcompleted}/>
               <DropzoneArea
                dropzoneText="Drop banner artwork here"
                  acceptedFiles={['.gif','.png','.jpg']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={3000000}
                onChange={handleIconDrop.bind(this)}
                dropzoneClass={classes.dropzone}/>
          </Grid>
          </Grid>
          </Grid>

          {/*
          <Grid container spacing={6} sm={12}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" gutterBottom>Featured Video</Typography>
                 <Typography variant="p" gutterBottom>Optional Featured video displays prominently at the top of your channel page.</Typography>
                </Grid>
                 <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                    onClick= {()=>handleOpenDialog()}
                  >
                    {fvideoURL==''?"Add Video":"Change Video"}
                  </Button>

                    <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                    disabled={fvideoURL==''}
                    onClick= {()=>removeFeatureVideo()}
                  >
                   Remove
                  </Button>
                  <br/> <br/>
                  </Grid>
          </Grid>
         
         


         <Grid container sm={12} spacing={2}  >
                  <Grid item xs={12} sm={12}>
           <Typography variant="h5" gutterBottom>Audience Recommendations*</Typography>
            <Typography variant="p" gutterBottom>Add audiences to the right field for content recommendations</Typography>
            <Grid item sm={10}>
            <TransferList audiences={audiences} selectedAudiences={values.audienceList? values.audienceList.filter(group => group != "Administrators"):values.audienceList} onChange={setAudience}/>
            </Grid>
        </Grid>
        </Grid>
          */}
        
          <Grid container xs={12} sm={12}>
            <FormControlLabel
                  control={<Checkbox name="clipSearch" checked={values.includeInClipsSearch} onChange={handleChange('includeInClipsSearch')} />}
                  label="Include in Clips Search"
                />
          </Grid>
        
         <Grid container spacing={6} xs={12} sm={12}>
           
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" gutterBottom>Group Access</Typography>
                     <FormControlLabel
                    control={<Checkbox name="clipSearch" checked={values.groupEmployee}  />}
                    label="T-Mobile Employees"
                  />
                  {/*
                   <FormControlLabel
                    control={<Checkbox name="fedSearch" checked={values.groupVendedCare} onChange={handleChange('groupVendedCare')} />}
                    label="Vended Care"
                  />
                   <FormControlLabel
                    control={<Checkbox name="fedSearch" checked={values.groupConsumerIndirect} onChange={handleChange('groupConsumerIndirect')} />}
                    label="Consumer Indirect"
                  />
                   <FormControlLabel
                    control={<Checkbox name="fedSearch" checked={values.groupBusinessIndirect} onChange={handleChange('groupBusinessIndirect')} />}
                    label="Business Indirect"
                  />
                    <FormControlLabel
                    control={<Checkbox name="fedSearch" checked={values.groupTelesales} onChange={handleChange('groupTelesales')} />}
                    label="Telesales"
                  />
                  */}
          </Grid>
        </Grid>
        
        
          <Grid container xs={12} sm={12}>
              <Typography variant="h5" gutterBottom>Podcast Show Status*</Typography>
         </Grid>
      <Grid container spacing={3}>
              <Grid item sm={4}>
               <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <RadioGroup aria-label="position" name="position" value={values.podcastStatus} onChange={handlePrivacyChange} row>
            <FormControlLabel
              value="active"
              control={<Radio color="secondary" />}
              label="Active"
              labelPlacement="end"
            />
             <FormControlLabel
              value="deactivated"
              control={<Radio color="secondary" />}
              label="Deactivated"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>

          </Grid>
      </Grid>
  </div>
      }
      {editMode && !editDetailMode &&
         <div style={{
            display:'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignContent: 'stretch',
            alignItems: 'flex-start',
            maxWidth: '1150px',
            width: '100%',
            padding: '8px',
            minHeight: '100%',
          }}>
            <PodcastHeader title={values.title}
                            art= {values.art}
                           id ={values.id}
                           description={values.description}
                           videos={values.videos}
                           playlist={values.playlist}
                           subscribers = {values.subscribers}
                           createdAt = {values.createdAt}
                           image = {values.thumbnail}
                           showButton ={true}
                           podcaster= {[values.podcaster, values.backupPodcaster]}
                           icon = {values.iconImage}/>
                        


          </div>
      }
       <Divider variant="middle" />
      <div>

      {editMode && !editDetailMode &&
      <div>
      
     

       <Grid item xs={12} sm={12}>
       <Typography variant="h5" gutterBottom>Episodes</Typography>
          {channelCreated &&
               <Button
                variant="contained"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick= {()=>setModalOpen(true) }
              >
                Add new episode
              </Button>
              }
            <Grid container xs={12} sm={12}>
            {console.log(values.audios)}
             {/*values.audios.items.concat(values.videos.items).map(video => getVideoAdded(video))*/}
            </Grid>
            <hr/>
        </Grid>
        
        </div>
      }

       <Grid item xs={12} sm={12} justify="flex-end" >
       {!editDetailMode&&
           <div>
             <Button
                variant="contained"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick= {()=>setEditDetailMode(true)}
              >
                Edit podcast detail
              </Button>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick= {()=>handleExit()}
              >
                Finish editing podcast
              </Button>
            </div>
       }
      {editDetailMode&&
           <Button
              variant="contained"
              size="medium"
              color="primary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=>handleSave()}
              
            >
              Save and Continue
            </Button>
         }
      </Grid>
      </div>

       <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={10000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="success"
            message={"Your new podcast have been created. (" + values.id  +")" }
          />
      </Snackbar>
      <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="warning"
            message={"Something is wrong, make sure all the fields are filled out before save" }
          />
      </Snackbar>

         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={modalOpen}>
              <div className={classes.paper} >
                <h2 id="transition-modal-title">Add epsidode to podcast</h2>
                <Grid xs={12} container direction='row' spacing={10}>
                <Grid item xs={6} sm={4}>
                     <CssTextField
                        id="filled-business-partner-email"
                        fullWidth
                        label="Find episode by ID"
                        value={videoID}
                        onChange={handleVideoChange}
                          margin="normal"
                           variant="outlined">
                      </CssTextField>
                      
                         <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            className={classes.margin}
                            disabled={!(videoID.length==12||videoID.length==16)}
                            onClick= {handleSearch}
                          >Find</Button>
                          
                </Grid>
                <Grid item xs={6} sm={8}>
                     {videos.map(video => getVideo(video))}
                </Grid>
                </Grid>
              </div>
            </Fade>
          </Modal>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={PlaylistModalOpen}
            onClose={handlePlaylistModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={PlaylistModalOpen}>
              <div className={classes.paper} >
                <h2 id="transition-modal-title">Add Playlist to Channel</h2>
                <Grid xs={12} container direction='column' spacing={10}>
                {playlist==null &&
                  <Grid item xs={6} sm={4}>
                       <CssTextField
                          id="filled-business-partner-email"
                          fullWidth
                          label="Find playlist by ID"
                          value={playlistID}
                          onChange={handlePlaylistChange}
                            margin="normal"
                             variant="outlined">
                        </CssTextField>
                        
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            className={classes.margin}
                            disabled={!(playlistID.length==10)}
                            onClick= {handlePlaylistSearch}
                          >Find</Button>
                          
                  </Grid>
                }
                  {playlist!=null &&
                  <Grid container direction='row' xs={12} sm={12}>
                <Grid item xs={6} sm={12}>
                  {console.log(playlist.id)}
                  <PlaylistItem
                      id={playlist.id}
                     firstVideo={playlist.videoArray[0]}
                      thumbnail={playlist.videos.items[0].thumbnail}
                      title={playlist.title}
                      description={playlist.description}
                      date={utils.formatDate(playlist.createdAt)}
                      videos ={playlist.videos.items}
                      haveMenu={false}
                    />


                </Grid>
                <Grid item xs={6} sm={12}>
                         <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            className={classes.margin}
                            onClick= {()=> handlePlaylistModalClose() }
                          >
                            Cancel
                          </Button>
                         <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            className={classes.margin}
                            onClick= {()=> addPlaylist() }
                          >
                           Save
                          </Button>
                </Grid>
                </Grid>
                  }
                </Grid>

              </div>
            </Fade>
          </Modal>


         <Dialog open={DialogOpen} fullWidth={true}onClose={ handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Featured Video</DialogTitle>
          <DialogContent>
          {/*
            <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send updates
              occasionally.
            </DialogContentText>
            */}
            <CssTextField
            required
            id="title"
             fullWidth
            label="Video Title"
            placeholder=''
            value={values.featuredVideoTitle}
            onChange={handleChange('featuredVideoTitle')}
             margin="normal"
             variant="outlined"/>

          <BorderLinearProgress variant="determinate" color="secondary" value={completed}/>
            <DropzoneArea
            dropzoneText="Drag your video in here"
                acceptedFiles={['.mp4']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={250000000}
                onChange={handleVideoDrop.bind(this)}

                dropzoneClass={classes.dropzone}>
              </DropzoneArea>

            <BorderLinearProgress variant="determinate" color="secondary" value={postercompleted}/>
               <DropzoneArea
                dropzoneText="Drag your thumbnail image in here"
                  acceptedFiles={['.gif','.png','.jpg']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={500000}
                onChange={handleThumbDrop.bind(this)}
                dropzoneClass={classes.dropzone}/>

        

           <Typography variant="p" gutterBottom>Description</Typography>
            <TextareaAutosize className={classes.descBox}
            placeholder=""
            aria-label="minimum height" rows={5} onChange={handleChange('featuredVideoDesc')} value={values.featuredVideoDesc} />
            </DialogContent>
          <DialogActions>


              <Button
              variant="contained"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=> handleCloseDialog() }
            >
              Cancel
            </Button>
           <Button
              variant="contained"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=> handleCloseDialog() }
              
            >
             Save
            </Button>
          </DialogActions>

        </Dialog>

    </div>


    )
  }
  else return null;
}

export default withStyles(styles)(editPodcast);
