//https://reactjsexample.com/banner-anim-animation-component-for-react/

import BannerAnim from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import React from 'react';
import ReactDOM from 'react-dom';
import '../style/carousel.css';
import Age from '../functions/Age.js';
import {withRouter} from 'react-router-dom'

const { Element } = BannerAnim;
const BgElement = Element.BgElement;


function Carousel(props) {
    console.log(props.playlist)
    const gotoVideo=(id)=>{
      props.history.push('Watch?v='+id)
    }
const getVideo = (video) => {

    return   <Element key={video.id}
        name="1"
        prefixCls="banner-user-elem"
        onClick={()=>gotoVideo(video.id)}
        style={{cursor:'pointer'}}
      >
        <BgElement
          key={video.id}
          className="bg"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT_URL}vco/${video.id}/featureGraphic.jpg)`,
            backgroundSize: 'cover',

            pointer:'cursor',
          }}
        />
        <QueueAnim name="QueueAnim" className='info'>
          <h1 key="h1">{video.video_title}</h1>
          <h2 key="p"><span dangerouslySetInnerHTML={{__html: video.video_desc,}} /></h2>
          <p >Added {Age(video.createdAt)}</p>
        </QueueAnim>

      </Element>
  }

  return (
    <BannerAnim autoPlay={true} autoPlaySpeed={6000} prefixCls="banner-user">
        {props.playlist.map(item=>getVideo(item)
        )}

    </BannerAnim>
  );
}

export default withRouter(Carousel);