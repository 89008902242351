/* eslint-disable */
import React, { useState , useEffect } from 'react';
import EditVideoitem from '../parts/videopage/EditVideoitem.js';
import Typography from '@material-ui/core/Typography';
import Age from '../functions/Age.js';
import Amplify,{ Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as utils from '../functions/util.js'; 
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PodcastCard from '../parts/PodcastCard.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { refresh } from '../action/refresh'
import {withRouter} from 'react-router-dom'

const mapDispatchToProps = dispatch => bindActionCreators({
  refreshMenu: () => dispatch(refresh),
}, dispatch)

const styles = {
  form: {
    margin: 30,
    color: '#ffce0a'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
      color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
   // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 300,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
async function fetchData(searchQuery, paramKey, param) {
   const searchResult = await API.graphql(
        graphqlOperation(searchQuery, { paramKey: param })
      );
    return searchResult;
}

function authorPodcast(props) {
  const [Videos, setVideos] = useState([]);
  const [finishLoading, setFinish] = useState(false);
  let data, func
  console.log(props)

  const copyPodcastID = (id) => {

    navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(id)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }

  const removeVideo =(id)=>{
    setVideos( Videos.filter(video => video.id != id) );// remove from display
    utils.removePodcast(id)
     props.refreshMenu();
  }
  const addPodcast=()=>{
    props.history.push('/editPodcast')
  }
  const gotoPodcast =(id)=>{
     props.history.push('/Podcast?id='+id)
  }
   const handleEdit=(id)=>{
      props.history.push('/editPodcast?id='+id)
  }
  
 
  
  const getChannel=()=>{
    setFinish(false)
    utils.getPodcastByOwner().then(function(result) {
       console.log(result)
        data = result.data.searchPodcastShowss.items
          console.log(data)
          let VideosArray = [];
          let playlistItem = data
          let numloaded = 0;
          for (var value of playlistItem) {
            
            var obj = {};
            obj.id = value.id;
            obj.video_title = value.title;
            obj.video_desc = value.description;
            obj.createdAt = value.createdAt;
            obj.poster_image_loc = value.art
            obj.updatedAt = value.updatedAt
            VideosArray.push(obj)
            setVideos(VideosArray) 
           
          }
           setFinish(true)
        });
  }
  useEffect(() => {
   
    getChannel();
    setTimeout(function(){
         getChannel();
         props.refreshMenu();
    }.bind(this), 2000);
  
    
   }, []);
   
   if(finishLoading){
    return (
      <div class="thumbnailContentBlock">
         <Typography variant="h5" gutterBottom>My Podcasts</Typography>
         <Button
            variant="contained"
            color="primary"
            onClick={()=>addPodcast()}>Add Podcast Show</Button>
          <Grid container direction='row' sm={12}>
          {console.log(Videos)}
            {Videos.map(subscription =>
                <Grid item  alignItems="center" > 
                  <PodcastCard 
                    id = {subscription.id}
                    image = {subscription.poster_image_loc}
                    title = {subscription.video_title}
                    description = {subscription.video_desc}
                     removeMe = {removeVideo}
                     redirect = {props.history.push}
                    updatedAt={subscription.updatedAt}
                    videos="count"
                    playlists="count"
                    subscribers="count"
                      videoMenu={[ {name:'Edit', func:handleEdit},
                                   {name:'Delete', func:removeVideo},
                                   {name:'Copy Show ID', func:copyPodcastID},
                      ]}
                    />
                  </Grid>
            )}
                
          </Grid>
        </div>
    )
   }
   else return null;
}

export default connect(null, mapDispatchToProps)(withRouter(authorPodcast));