import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Schedule from '@material-ui/icons/Schedule';
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';
import PlaylistCheck from '@material-ui/icons/PlaylistAddCheck';
import Share from '@material-ui/icons/Share';

const styles = {
  card: {
    backgroundColor: 'transparent',
    borderRadius: '0px',
    margin: '10px'
  },
  contentArea: {
    marginLeft: '0px',
    marginTop: '-16px'
  },
  contentAreaOverlay: {
    width: '120px',
    height: '124px',
    position: 'absolute',
    top: '0px',
    left: '100px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  title: {
    color: '#fff'
  },
  info: {

    color: '#fff',
    fontSize: '10'
  },
  icon: {

    color: '#fff',
    margin: '10px'
  },
  infoCompact: {
    color: '#999'
  },
  mediaSmall: {
    width: 220,
    height: 124
  },
  mediaBig: {
    width: 220,
    height: 124
  }
};

function PlaylistThumb(props) {

  const {classes} = props;
  let thumbJSX;

  if (props.isSmall) {
    thumbJSX = <Card className={classes.card}></Card>;
  } else {
    thumbJSX = <Grid container="container" direction='row' className={classes.title}>
      <Card className={classes.card}>

        <CardActionArea>
          <CardMedia className={classes.mediaBig} image={props.image} title={props.title}/>

          <CardContent className={classes.contentAreaOverlay}>
            <Grid container="container" direction="column" justify="center" alignItems="center">
              <Grid container="container" direction='row'>
                <Grid className={classes.icon}><Schedule/></Grid>
                <Grid >
                  <Typography variant="body1">
                    3:20</Typography>
                </Grid>
              </Grid>
              <Grid item="item">
                <Typography variant="body1">11</Typography>

              </Grid>
              <Grid item="item">
                <PlaylistPlay style={{
                    fontSize: 40
                  }}/></Grid >
            </Grid>
          </CardContent>
        </CardActionArea>

      </Card>

    </Grid>
  }

  return (<div>{thumbJSX}</div>);
}

PlaylistThumb.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PlaylistThumb);
