import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import queryString from 'query-string'
import { Cache } from 'aws-amplify';
import * as utils from '../functions/util.js';
import CollectionBox from '../parts/collection/CollectionBox.js';
import CollectionIcon from '../images/iconJS/Collection.js';
import Footer from '../parts/Footer.js';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { ButtonGroup, Button } from '@material-ui/core';
import VideoCard from '../parts/VideoCard.js';
import Age from '../functions/Age.js';
import SortDateAlphaTrend from '../parts/widgets/SortDateAlphaTrend.js';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@material-ui/core/TextField';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const style = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: '5px',
        marginLeft: '8px;',
        flexDirecton: 'column',
    },
    gridList: {
        flexWrap: 'wrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        flexDirecton: 'rows',
    },
    yellowDivider: {
        backgroundColor: '#E20074',
        height: '2px',
        display: 'flex',
        minWidth: '100%'
    },
    title: {
        color: '#fff'
    },
    gridListTile: {
        maxWidth: '220px',
        marginTop: '5px',
        marginLeft: '4px;',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputRoot: {
        borderColor: '#E20074',
        color: 'inherit',
        width: '100%',
    },
    textField: {
        width: 240,
        height: 30,

        padding: '10px 20px 10px 10px',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 300,
        fontSize: 16,
        border: '1px solid #E20074',
        borderRadius: 4,
        input: {
            margin: 0,
            padding: 0,
            color: 'white',
            height: 300,
        }
    },



});
let collections

const CssTextField = withStyles({
    root: {
        heigth: 33,
        width: 300,

        padding: '10px 20px 10px 10px',
        '& .MuiInputBase-input': {
            fontColor: '#000',
        },
        '& label.Mui-focused': {
            //color: 'green',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: 'green',
        },
        '& .MuiInputLabel-outlined': {
            // borderBottomColor: 'green',
            fontColor: "#000",
            paddingLeft: 5
        },
        '& .MuiOutlinedInput-input': {
            // borderBottomColor: 'green',
            fontColor: "#000",
            padding: 10,
            paddingLeft: 5,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                fontColor: "#000",
                borderRadius: 4,
                borderColor: '#E20076',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            },
            '&:hover fieldset': {
                borderColor: '#E20076',
                // backgroundColor: '#000'
                //borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                color: '#ccc',
                borderColor: '#E20076',
                fontColor: "#000",
                //backgroundColor: '#000'
                //borderColor: 'green',
            },
        },
    },
})(TextField);

function CollectionPage(props) {
   // console.log(props)
    let values
    const [ID, setID] = useState();
    const { classes } = props;
    const [finishLoading, setFinish] = useState(false);
    const [collection, setCollection] = useState();
    const [playlist, setVideo] = useState();
    const [collections, setCollections] = useState();
    const [originalVideos, setOriginalVideos] = useState();
    const [videos, setVideos] = useState();
    const [refreshToggle, setRefresh] = useState(false);
    const [init, setInit] = useState(true);
    const [initSort, setInitSort] = useState(false);
    
    
  const copyVideoID = (id) => {
    navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
     
      navigator.clipboard.writeText(id)
      document.execCommand('copy');
    }

  }


    const sortAlpha = () => {
        console.log('sortAlpha')
        videos.sort((a, b) => a.title.localeCompare(b.title))
        console.log(playlist)
        setVideo(playlist)
        // setRefresh(!refreshToggle)
        //setPage(0)
        setRefreshToggle()
    }
    const setRefreshToggle = () => {
        setRefresh(!refreshToggle)
    }
    const sortDate = () => {
        console.log('sortDate')
        console.log(init,initSort)
        console.log('sort date')
        console.log(videos)
        videos.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        setVideo(videos)
        //setRefresh(!refreshToggle)
        //setPage(0)
        setFinish(false)
        setFinish(true)
        setRefreshToggle()
    }

    const sortTrend = () => {
        console.log('sortTrend')
        console.log(videos)
        videos.sort((a, b) => b.views-a.views)
        setVideo(videos)
        //setRefresh(!refreshToggle)
        //setPage(0)
        setFinish(false)
        setFinish(true)
        setRefreshToggle()
    }

    const handleCollection = (id) => {

        props.history.push('/CollectionPage?id=' + id)
        setRefreshToggle()
        setInit(true)
    }

    const handleSubCollection = (id) => {
        console.log(originalVideos)
        if (id == 0) setVideos(originalVideos)
        else setVideos(originalVideos.filter(c => c.subID == id))
    }

    const handleSearch = (keyword) => {
        console.log(keyword)
        let filter = 'title'
        let newVid = originalVideos.filter(function(obj) {
            return obj[filter].toLowerCase().includes(keyword.toLowerCase());
        });
        //console.log(newVid)
        setVideos(newVid)
    }

    const videoPage = (obj) => {

    }

    useEffect(() => {
        if (init) {
            let collectionArray = [];
            values = queryString.parse(props.location.search)
            setID(values.id)
            //console.log('collection page use effect')
            let i = 0;
            utils.getAllCollection().then(value => {
                let collections = value.data.searchCollections.items

                //console.log(collections)

                for (let value of collections) {
                    utils.searchCollection2(value.id).then(value => {

                        let c = {}
                        let videoArray = []
                        let subCollectionArray = []
                        var tmp = value.data.searchCollections.items[0]
                        c.info = tmp;

                        let subCollection = tmp.subCollection;
                       console.log(subCollection)
                        for (var sub of subCollection.items) {
                            subCollectionArray.push(sub)
                            console.log(sub)
                            for (var vid of sub.channelVideo.items) {
                                console.log(vid)
                                //if(vid.video.hasOwnProperty('videoViews'))  vid.video.videoViews = vid.video.videoViews | 0
                                //if(vid.video.hasOwnProperty('subID')) vid.video.subID = sub.id
                                // vid.video.allowTraine
                                if(vid.video!=null){
                                    vid.video.views = vid.video.videoViews | 0
                                     vid.video.subID = sub.id
                                    if (vid.video.audience == "Public"&&vid.video.videoState == "ACTIVE") videoArray.push(vid.video)
                                }
                            }
                             for (var audio of sub.podcastAudio.items) {
                                    if (audio.audio!=null){
                                        console.log(audio.audio)
                                        audio.audio.views = 0
                                        videoArray.push(audio.audio)
                                    } 
                                  
                                }
                        }
                        c.video = videoArray

                        //console.log(subCollection)
                        if (tmp.id != '001') {
                            //console.log(typeof(tmp))
                            if (videoArray.length > 0) {
                                //console.log(videoArray)
                                collectionArray.push(c)
                            }

                        }
                        console.log(c)
                        if (tmp.id == values.id) {
                            
                            setOriginalVideos(videoArray)
                            setVideos(videoArray)
                            console.log(c)
                            setCollection(c)
                        }
                        i++
                        if (i == collections.length) {
                            
                            setInit(false)
                            setCollections(collectionArray.sort((a, b) => a.info.title.localeCompare(b.info.title)))
                            console.log(collectionArray)
                           //sortDate()
                           setFinish(true)
                            
                            
                        }
                    })
                }
            });
        }
        else{
            if(!initSort){
               sortDate();
               setInitSort(true)
            } 
        }
    }, [refreshToggle,videos]);


    if (finishLoading) {
        return (

                <div style={{
        display:'flex', 
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        alignItems: 'flex-start',
        maxWidth: '1000px',
        width: '100%',
        padding: '8px',
        minHeight: '100%',
      }}>
      {console.log(collection)}
     
           <ButtonGroup size="small" color="secondary" aria-label="small outlined button group" style={{marginBottom:10}}>
            {collections.map(p =>
              <Button  startIcon={<img src={p.info.iconGraphic}/>}  onClick={()=>handleCollection(p.info.id)}>{p.info.title}</Button>
                )} 
            </ButtonGroup>
            <div>
            Subcollection <ArrowForwardIosIcon fontSize={'small'}/> 
            <ButtonGroup size="small" color="secondary" aria-label="small outlined button group" style={{marginBottom:10,marginLeft:10}}>
            
             <Button   onClick={()=>handleSubCollection(0)}>All</Button>
             {console.log(collections.find(c=>c.info.id ==ID))}
            {collections.find(c=>c.info.id ==ID).info.subCollection.items.sort((a, b) => a.title.localeCompare(b.title)).map(p =>
              <Button onClick={()=>handleSubCollection(p.id)}>{p.title}</Button>
                )} 
            </ButtonGroup>
            </div>
            
          
       
       
   {/*
         <GridListTile key={collection.info.bannerGraphic} >
         
            <img src={collection.info.bannerGraphic} alt={collection.info.title} />
            <GridListTileBar
              title={collection.info.title}
              subtitle={<span>{collection.video.length} videos</span>}
               actionPosition={"left"}
              actionIcon= {<IconButton>
                 <img src={collection.info.iconGraphic}/>
                </IconButton>}
            />
          </GridListTile>
          */}
          <div style={{height:120, width:1024,backgroundImage: `url(${collection.info.bannerGraphic})`}}>
          <Grid container spacing = {5} style={{height:50,marginTop:70,backgroundColor: 'rgba(0, 0, 0, 0.5)',alignContent:'center'}}>
           <Grid item sm={3}><SortDateAlphaTrend sortAlpha={sortAlpha} sortDate = {sortDate} sortTrend={sortTrend} /></Grid>
            <Grid item style={{marginTop:7}}> 
            <CssTextField
             onChange={(e) => handleSearch(e.target.value)} 
                className={classes.margin}
                label="Search this Collection"
                variant="outlined"
                id="custom-css-outlined-input"
                InputProps = {
                              {
                                  endAdornment: (
                                      <InputAdornment>
                                <IconButton>
                                  <SearchIcon style={{color:"#E20076"}}/>
                                </IconButton>
                              </InputAdornment>
                                  )
                              }
                  }
      />
            
              </Grid>
              <Grid style ={{marginTop:25,marginLeft:300}}item>{collection.video.length} videos</Grid>
              </Grid>
              </div>
    <Grid container sm={12} style={{marginTop:10}}>
    
    
     {videos.map(c=>
         <Grid item sm={3} style={{flexBasis:0}}>
           
            <VideoCard
                    activity = {c.createdAt}
                    image = {c.thumbnail}
                    title = {c.title}
                    views = {c.videoViews}
                    age = {Age(c.createdAt)}
                    allowTrainerDownload={c.allowTrainerDownload}
                    duration = {c.videoDuration}
                    //channel = {video.channel.channel_title}
                    id = {c.id||0}
                    videoMenu={[ 
                       {name:'Copy Video ID', func:copyVideoID},
                      ]}
                  />
             </Grid>
                )} 
            </Grid>   
            
        
        
      </div>
        );
    }
    else return null;
}
export default withRouter(withStyles(style)(CollectionPage));
//export default withRouter(connect()(withStyles(styles)(CollectionPage)));
