import React from 'react';
import {  Cache } from 'aws-amplify';
import Autosuggest from 'react-autosuggest';
import '../style/autocomplete.css'
import SearchIcon from '@material-ui/icons/Search';
import * as utils from '../functions/util.js';
import { withRouter } from "react-router";
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const names = [];
const userInfo = Cache.getItem('userInfo')
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

async function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }
  let s,t = []
  const regex = new RegExp('^' + escapedValue, 'i');
   await utils.searchVideo(value).then(function(result) {
                    //console.log(result.data.searchVideoByKeyword)
                      if (result.data !== undefined && result.data.searchVideoByKeyword !== undefined) {
                        var videos = result.data.searchVideoByKeyword.items;
                        if (JSON.stringify(videos) !== "[]") {
                            s = videos.sort().map(a => ({title: a.title, id:a.id, type:'video' }));
                        }
                        if(result.data.searchVideoByKeyword.items.length==0) s=[]
                    }

   })
 console.log(s)
 await utils.getSearchHistory(value).then(function(result) {
   console.log(result)
                       t= result.data.searchHistorys.items.filter(s => s.historyUserId==userInfo.id )
                       .filter((obj, pos, arr) => {
                            return arr.map(mapObj => mapObj["searchKeywords"]).indexOf(obj["searchKeywords"]) === pos;
                        })
                        .sort().map(a =>({title: a.searchKeywords, id:a.id, type:'history'}));
                    });
  let final = []
  if(t.length==0) final = s
  if(s.length == 0) final = t
  if(s.length>0&&t.length>0)final =  t.concat(s)


    console.log(final)
  return final
  //return suggestions.filter(name => regex.test(name));
}

const getSuggestionValue = suggestion => suggestion;
/*
const renderSuggestion = suggestion =>  suggestion;
*/

function renderSuggestion(suggestion, { query }) {
  const suggestionText = `${suggestion.title}`;

  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  if(suggestion.type=='history'){
    return (
      <span className={'suggestion-content ' } style={{fontWeight: 'bold'}}   >
          {suggestion.title}

          {/*
          <HighlightOffIcon onClick={()=>deleteHistory(suggestion.id)} style={{position:'absolute',right:'0px'}}/>
          */}
      </span>
    );
  }
  else{
    return(
      <span className={'suggestion-content ' }   >
          {suggestion.title}
      </span>
      )
  }
}
const renderInputComponent = inputProps => (
  <div className="inputContainer">
     <input {...inputProps} />
      <SearchIcon  className="icon"/>
  </div>
);

const deleteHistory =(id)=>{

  utils.deleteHistory(id)
}

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };
  }


  onChange = (event, { newValue, method }) => {

   let v
    if(typeof(newValue)==='object')v= newValue.title
    else v = newValue
  //  console.log(v)
    this.setState({
      value: v
    });
    //console.log(this.state.value)
  };

  onKeyPress =(e)=>{
   console.log( this.state.value)
     if(e.key=='Enter') this.search(this.state.value)
  }

  search =(keyword)=>{
    if(typeof(keyword)==='object')keyword = keyword.title
     this.setState({
        value: ''
      });
    this.props.history.push('Search?keyword=' + keyword);
  }

 onSuggestionsFetchRequested = ({ value }) => {
   let  newSuggestion = getSuggestions(value).then(

     function(value) {
       console.log(value)
         if(value!==undefined){
              this.setState({
                suggestions: value.slice(0,10)
              });
         }
        }.bind(this)
    )

  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionsSelected =(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>{
    this.search(suggestionValue)
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search",
      value,
      onChange: this.onChange,
      onKeyPress:this.onKeyPress
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionsSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
    );
  }
}

export default withRouter(App)
