import React,{useState,useEffect} from 'react';
import * as utils from '../../functions/util.js'; 
import More from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditChannelTile = (props) => {
    const { classes } = props;
    const id = props.id
    console.log(id)
    const [anchorEl, setAnchorEl] = React.useState(null);
 const [open, setOpen] = React.useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  
 const handleDelete = () =>{
 console.log(typeof(id))
    utils.deleteChannelbyID(id).then(result => {
        props.removeMe(id);
      })
      .catch(err => console.log(err));
        setOpen(false);
    handleCloseMenu()
  }
   const handleDialogClose = () => {
    setOpen(false);
    handleCloseMenu()
  };
    const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
   const handleCloseMenu=()=>{
     setAnchorEl(null);
  }
  
  const deleteChannel = () =>{
    setOpen(true);
     handleCloseMenu()
   /*
     utils.deleteChannelbyID(props).then(result => {
        props.removeMe(id);
      })
      .catch(err => console.log(err));
      */
  }
  const handleEdit =(id)=>{
    props.redirect('/editChannel?c=' + id)
  }
   const handleGoto =(id)=>{
    props.redirect('/Channel?id=' + id)
  }
  
  useEffect(() => {
     
   }, [props.id]);
   
    return (
        <div class="channel-container" style={{minWidth:'1052px',marginBottom:'24px'}} id={props.id}>
          <div class="channel-thumbnail" onClick={()=>handleGoto(props.id)}>
            <img  style={{width: '124px',height:'124px', display: 'block' }} src={`https://${server[2]}/fit-in/124x124/public/channel/${props.id}/icon.png`}/>
          </div>
          <div class="channel-text">
            <h3 class="channel-title">{props.title}</h3>
            {/*<h5>{props.videos} Videos • {props.playlist} Playlists • {props.subscribers} Subscribers • Updated&nbsp;{props.updated}</h5>*/}
            <p>{props.description}</p>
          </div>
          <div class="channel-button">
           <More onClick={handleClick}/>
          </div>
            <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={()=>handleEdit(props.id)}>
          <ListItemIcon>
              <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </StyledMenuItem>
        <StyledMenuItem onClick={deleteChannel}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </StyledMenuItem>
       
      </StyledMenu>
       <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete Video"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Deleting Channel is permanent! Please confirm this is what you want to do.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}  variant="contained"
              size="medium"
              color="secondary">
            Yes delete
          </Button>
          <Button onClick={handleDialogClose}  variant="contained"
              size="medium"
              color="secondary">
           Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
        </div>    
    )    
}

export default EditChannelTile;