import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import queryString from 'query-string'
import { Cache } from 'aws-amplify';
import * as utils from '../functions/util.js';
import CollectionBox from '../parts/collection/CollectionBox.js';
import CollectionIcon from '../images/iconJS/Collection.js';
import Footer from '../parts/Footer.js';
import GridList from '@material-ui/core/GridList';
import { ButtonGroup, Button } from '@material-ui/core';
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: '5px',
    marginLeft: '8px;',
    flexDirecton: 'column',
  },
  gridList: {
    flexWrap: 'wrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    flexDirecton: 'rows',
  },
  yellowDivider: {
    backgroundColor: '#E20074',
    height: '2px',
    display: 'flex',
    minWidth: '100%'
  },
  title: {
    color: '#fff'
  },
  gridListTile: {
    maxWidth: '220px',
    marginTop: '5px',
    marginLeft: '4px;',
  }

});
let collections

function Collection(props) {


  const { classes } = props;
  const [finishLoading, setFinish] = useState(false);
  const [collection, setCollection] = useState();

  let i = 0

  const handleCollection = (id) => {
    if (props.type != 'static') {
      props.history.push('/CollectionPage?id=' + id)
    }
  }



  useEffect(() => {
    // console.log('channel use effect')
    setFinish(false)
    let collectionArray = [];


    utils.getAllCollection().then(value => {
      
      collections = value.data.searchCollections.items
    
      for (let value of collections) {
        utils.searchCollection2(value.id).then(value => {
         // console.log(value)
       //  console.log('**')
         console.log(value.data)
      
          let c = {}
          let videoArray = []
          let subCollectionArray = []
          var tmp = value.data.searchCollections.items[0]
          c.info = tmp;

          let subCollection = tmp.subCollection;
        
          for (var sub of subCollection.items) {
            subCollectionArray.push(sub)
           //console.log('***')
           console.log(sub)
            if(sub.channelVideo.items.length>0){
              for (var vid of sub.channelVideo.items) {
                  //console.log(vid)
                  //console.log(vid.video.audience)
                
                  if(vid.video!==null&&(vid.video.id.length==12)){
                    if (vid.video.audience == "Public"&&vid.video.videoState == "ACTIVE") videoArray.push(vid.video)
                  }
                }
              for (var audio of sub.podcastAudio.items) {
                  console.log(audio)
                  //console.log(vid.video.audience)
                
                  
                    if (audio.audio!=null) videoArray.push(audio.audio)
                  
                }
            }
              
          }
          c.video = videoArray
          console.log(videoArray)
          //console.log(subCollection)
          if (tmp.id != '001') {
            //console.log(typeof(tmp))
            if (videoArray.length > 0) {
              
              collectionArray.push(c)
            }

          }
          i++;

          if (i == collections.length) {console.log('finish');setFinish(true)}
        })
      }
    
      // console.log("i=" +i + ' ' + collections.length)
      setCollection(collectionArray)



    }, [i]);
  }, []);


  if (finishLoading) {
    return (

      <div style={{
        display:'flex', 
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        alignItems: 'flex-start',
        maxWidth: '1150px',
        width: '100%',
        padding: '8px',
        minHeight: '100%',
      }}>
      <Grid container  xs={12}><CollectionIcon zoom={90}/> <Typography gutterBottom  variant="h5" >Collections</Typography> </Grid>
          <ButtonGroup size="small" color="secondary" aria-label="small outlined button group" style={{marginBottom:10}}>
                 {collection.map(p =>
                
              <Button startIcon={<img src={p.info.iconGraphic}/>} onClick={()=>handleCollection(p.info.id)}>{p.info.title}</Button>
                )} 
            </ButtonGroup>
        
          <GridList style={{width:1000,display:'flex',overflow:'hidden'}} spacing={20}>
          {console.log(collection)}
           {collection.sort((a, b) => a.info.title.localeCompare(b.info.title)).map(c=>
           <div style={{height:'400'}}>
              
              <CollectionBox obj={c} />
              </div>
                )} 
                </GridList>
        
      </div>
    );
  }
  else return null;
}

export default withRouter(Collection);
