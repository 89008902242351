import React from 'react';
import './VideoItem.css';
import Wrap from '../../functions/Wrap.js';
//import more_vert from '@materia-ui/icons/more_vert';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import More from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import * as utils from '../../functions/util.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';



const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditVideoItem(props) {
  const video = props;
  console.log(props)
  const id = props.id;
  const date = new Date(props.activity);
  const ActivityDate = (date.getMonth()+1)+'/' + date.getDate() + '/'+date.getFullYear(); 
   const [anchorEl, setAnchorEl] = React.useState(null);
 const [open, setOpen] = React.useState(false);

  const handleDialogClose = () => {
    setOpen(false);
    handleCloseMenu()
  };
  
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  
  const deleteVideo = () =>{
    console.log(props)
    setOpen(true);
     handleCloseMenu()
   // utils.DeleteVideoByID({props})
  }
  const handleDelete = () =>{
    utils.DeleteVideoByID(props).then(result => {
       setOpen(false);
        props.removeMe(id);
      })
      .catch(err => console.log(err));
  }
  
  const handleEdit =()=>{
   props.redirect('/editVideo?v=' + id)
  }
  const handleCloseMenu=()=>{
     setAnchorEl(null);
  }
  const handleWatch =()=>{
   props.redirect('/Watch?v=' + id)
  }
  return ( 
    <div>
    <div style={{marginRight: '6px'}}>    
        {/*<div class="activity-date">{ActivityDate}</div>*/}
        <div class="videoCard">
              <div class="thumbWrapper" onClick={handleWatch}>
                <img src={video.image} class="thumbnailImg" alt={props.title}/>
                <span class="thumbVideoDuration">{video.duration}</span>          
              </div>
              <div class="videoCardMetadata">
              
               <Grid container> <div class="thumbnailTitle ">{props.title}</div>       <More onClick={handleClick}/></Grid>
                <div class="metaDateAge"><span>{video.views||0} Views</span> • <span>{video.age}</span></div>
               {/*
                <div class="channelLink">
                  <svg class="channelIcon" viewBox="0 0 24 24"><use href="../../images/icons/icons.svg#channel" /></svg><span class="channelTitle">{video.channel}</span>
                </div>
                */}
              </div>
        </div>
        
          <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleEdit}>
          <ListItemIcon>
              <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </StyledMenuItem>
        <StyledMenuItem onClick={deleteVideo}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </StyledMenuItem>
       
      </StyledMenu>
     
    </div>
     <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete Video"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Deleting Videos is permanent! Please confirm this is what you want to do.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}  variant="contained"
              size="medium"
              color="secondary">
            Yes delete
          </Button>
          <Button onClick={handleDialogClose}  variant="contained"
              size="medium"
              color="secondary">
           Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  )
}

export default EditVideoItem;