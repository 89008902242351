import React ,{useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    color:'#ccc',
    width: 300,
    height: 330,
    overflow: 'auto',
     backgroundColor:'#272727;',
    border: '1px solid #ccc',
  },
  button: {
       border: '1px solid #ccc',
      color:'#ccc',
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  
  let rightArray = []
  if(props.selectedAudiences!=null)rightArray =props.selectedAudiences
  let leftArray = props.audiences;
  console.log(leftArray)
  console.log(typeof(leftArray))
   leftArray =  leftArray.filter( function( el ) {
      return !rightArray.includes( el );
    } );
    
 
  const [left, setLeft] = React.useState(leftArray);
  const [right, setRight] = React.useState(rightArray);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
   
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setParam ()
  };

  const handleCheckedRight = () => {
     setChecked(not(checked, leftChecked));
     setLeft(not(left, leftChecked));
    setRight( right.concat(leftChecked));
    setParam ()
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  
  const setParam = () => {
    props.onChange(right)
  };
  useEffect(() => {
    // console.log(props.audiences)
    setLeft(props.audiences)
    setParam()
}, [right , props.audiences]);
  const customList = items => (
    
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
         
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
               <ListItemIcon>
             
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                
              </ListItemIcon>
             
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={3} justify="left" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}
