/* eslint-disable */
import React, { useState , useEffect } from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import { Grid, Card, CardMedia, CardHeader, Button, Fab, Typography } from '@material-ui/core';
//import { ThumbUp, StarRate } from '@material-ui/icons';
// playlist - sample json code
//import playlist from '../sampleData/playlist.js';
import './History/HistoryItem.css';
import Wrap from '../functions/Wrap.js';
import HistoryItem from './History/HistoryItem.js';
import SortDateAlphaLikeFav from './widgets/SortDateAlphaLikeFav.js';
import { Link } from 'react-router-dom';
import getUserProfile from '../functions/getUserProfile.js';
import * as utils from '../functions/util.js';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';



function History(props) {
//  console.log(props)
  const [playlist, setVideos] = useState([]);
   const [init, setInit] = useState(false);
   const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

 const [refreshToggle, setRefresh] = useState(false);
  let data

    const sortAlpha=()=>{
      console.log(playlist)
        playlist.sort((a, b) => a.video_title.localeCompare(b.video_title))
        console.log(playlist)
        setVideos(playlist)
        setRefresh(!refreshToggle)
         setPage(0)
      }

  const sortDate=()=>{
    console.log(playlist)

     const sortByValue = playlist.sort((a,b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
    setVideos(sortByValue)
    setRefresh(!refreshToggle)
     setPage(0)
  }

  const sortLike=()=>{
    playlist.sort((a, b) => (a.like < b.like) ? 1 : -1)
    setVideos(playlist)
    setRefresh(!refreshToggle)
    setPage(0)
  }
  const sortFav=()=>{
    playlist.sort((a, b) => (a.favourite< b.favourite) ? 1 : -1)
    setVideos(playlist)
    setRefresh(!refreshToggle)
     setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: value => value.toLocaleString(),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: value => value.toLocaleString(),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: value => value.toFixed(2),
  },
];
  useEffect(() => {

     utils.getHistory().then(function(result) {

          data =result.data.searchHistorys.items
          data = data.filter(item => item.title !='undefined');
          //data.map(item =>{item.like==null?item.like=false:item.like=true})
         data.map(item =>{item.favourite==null?item.favourite==false:item.favourite==true})
          let VideosArray = [];
          let playlistItem = data

          for (var value of playlistItem) {
            var obj = {};
            const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
            obj.id = value.videoId;
            console.log(value)
          const extension = value.thumbnail.split('.')
           const fitThumbURL = `https://${server[2]}/fit-in/82x46/public/vco/${value.videoId}/poster.${extension[extension.length-1]}`
            obj.poster_image_loc = fitThumbURL
            
           // const getImg = async() => await utils.getImageURL(value.thumbnail).then( (data)=>{obj.poster_image_loc = data; })
            console.log(value)
         
            obj.video_title = value.title||'';
            obj.createdAt = utils.formatDate(value.createdAt);
            obj.like = value.like
            obj.favourite = value.favourite
            VideosArray.push(obj)

          }
        setVideos(VideosArray)
        setInit(true)
        sortDate();
        });
        if(init){
         // console.log('set init')
         sortDate();
        }
   }, [init]);

  return (
      <div className="history-wrapper">
        <SortDateAlphaLikeFav sortAlpha={sortAlpha} sortDate = {sortDate} sortLike ={sortLike} sortFav={sortFav}/>


        <div >
          {playlist.map(item =>
         <HistoryItem
              id={item.id}
              thumbnail={item.poster_image_loc}
              title={item.video_title}
              date={item.createdAt}
              like={item.like}
              favorite={item.favourite}
              redirect = {props.redirect}
              refresh={refreshToggle}
            />
            
            )}
            </div>
      {/*  
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {playlist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => {
              return (
               <Wrap>
            <HistoryItem
              id={item.id}
              thumbnail={item.poster_image_loc}
              title={item.video_title}
              date={item.createdAt}
              like={item.like}
              favorite={item.favourite}
              redirect = {props.redirect}
              refresh={refreshToggle}
            />
            <div class="history-divider"></div>
          </Wrap>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[ 20]}
        component="div"
        count={playlist.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
*/}

      </div>
  );
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default  withStyles(style)(History);
export default History;