const Age = (created) => {
    const date1 = new Date(created);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    if (diffDays >= 2) {
      //console.log(diffDays, " Days old");
      return(diffDays + "\u00A0Days\u00A0ago")
    } else {
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      if (diffHours >= 2){
        //console.log(diffHours, "Hours old");
        return (diffHours + "\u00A0Hours\u00A0ago")
      } else {
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        //console.log(diffMinutes, "Minutes old");
        return(diffMinutes + "\u00A0Minutes\u00A0ago");
      }     
    }
  }
  
  export default Age;
  