import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';


async function getUserProfile() {

  const  user = Cache.getItem('userInfo')
  const userID = user.id
  const searchUserProfile = `query GetUserProfile {
  getUserProfile(id:"` + userID + `"){
    id
    company
    givenName
    familyName
    email
    employeeType
    activity {
      subscriptions
      playlists
      favorites
      likes
      shares
      videosWatched
    }
    
    playlist{
      items{
        id
        title
        description
        videoArray
        createdAt
        videos{
          items{
            videoId
            title
            description
            thumbnail
            videoDuration
        createdAt
          }
        
        }
        audios {

          items {
            allowTrainerDownload
            audioId

            thumbnail

            title

            description

            audioDuration

          }
        }

      }
    }
    favourites{
      items{
        videoId
        title
        description
        thumbnail
        createdAt
        videoDuration
      }
    }
    likes{
      items{
        videoId
        title
        description
         thumbnail
        createdAt
        videoDuration
      }
    }
    shares{
      items{
        videoId
        title
        description
         thumbnail
        createdAt
      }
    }
    history{
      items{
        videoId
        title
        description
        thumbnail
        
      }
    }
  }
}`;

  //const userProfile = Cache.getItem('userProfile')
  //console.log(userProfile)
  //if (userProfile == null) {
    //console.log('user id ' + user.id)
    const searchResult = await API.graphql(
      graphqlOperation(searchUserProfile, { "userID": userID })
    )
    // console.log('is null ' + searchResult)
    //Cache.setItem('userProfile', searchResult);
    
    console.log(searchResult)
    return searchResult
 // }
 /* 
  else {
    console.log('not null ' + userProfile)
    return userProfile;
  }
*/
}

export default getUserProfile;
