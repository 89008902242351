import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LiveTv from '@material-ui/icons/LiveTv';
import { 
  Card, 
  CardMedia, 
  CardActions, 
  CardContent, 
  CardActionArea, 
  Button, 
  Typography, 
  Grid, 
  GridList, 
  GridListTile, 
  GridListTileBar, 
  IconButton 
} from '@material-ui/core';

const styles = {
  card: {
    backgroundColor:'transparent',
    borderRadius:'0px',
    borderTop: '2px solid #000',
    '&:hover': {
      borderTop: '2px solid #E20074',
    },
  },
  contentArea:{
    marginLeft:'0px',
    //marginTop:'-16px',
    padding:'0px'
  },
  duration: {
      backgroundColor:'rgba(0, 0, 0, 0.6)',
      display:'inline-block',
      padding:'2px 8px',
      position: 'relative',
      fontSize: '12px',
      height: '22px',
      top:'-22px',
      float: 'right',
  },
  contentAreaOverlay:{
    width:'440px',
    position: 'absolute',
    top: '180px',
    left: '0px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  title:{
    color: '#fff',
    fontSize: '15px',
    lineHeight: '18px',
    margin: '4px 0 8px 0',
  },
  info:{
    color: '#999',
    marginTop:'0px',
    fontSize: '13px',
  },
  infoCompact:{
    color: '#999',
  },
  mediaSmall: {
    width:220,
    height: 124,
  },
  mediaBig: {
    width:440,
    height: 248,
  },
  channel: {
    fontSize: '14px',  
  }
};

function VideoThumb(props) {

  const { classes } = props;
  let thumbJSX;

  if (props.isSmall) {
       thumbJSX = <Card className={classes.card} >
         <CardActionArea>
           <CardMedia
             className={classes.mediaSmall}
             image={props.image}
             title={props.title}
           />
           <CardContent className={classes.duration} style={{color:'white'}}>
             {props.duration}
           </CardContent>
           <CardContent className={classes.contentArea}>
             <Typography gutterBottom className={classes.title} variant="subtitle1" >
               {props.title}
             </Typography>
             <Typography  className = {classes.info} component="p">
             {props.views} Views • {props.age}
             </Typography>
             <p>
             <Grid container
               spacing={0}
               className={classes.info}
               alignItems='center'
               direction='row'>
                   <Grid item>
                     <LiveTv />
                   </Grid >
                   <Grid item className = {classes.channel} style={{marginTop:'4px', marginLeft:'8px'}}>
                     {props.channel}
                   </Grid>
             </Grid>
             </p>
           </CardContent>
         </CardActionArea>
       </Card>;
     } else {
       thumbJSX = <Card className={classes.card}>
         <CardActionArea>
           <CardMedia
             className={classes.mediaBig}
             image={props.image}
             title={props.title}
             alt={props.title}
           />
           <CardContent className={classes.duration}>
             {props.duration}
           </CardContent>
           <CardContent className={classes.contentAreaOverlay}>
             <Typography gutterBottom className={classes.title} variant="subtitle1" >
               {props.title}
             </Typography>
             <Typography  className = {classes.infoCompact} component="p">
             {props.views} Views • {props.age}
             </Typography>
             <p>
             <Grid container
               className={classes.info}
               alignItems='center'
               direction='row'>
               <Grid item>
                 <LiveTv />
               </Grid >
               <Grid item>
                 {props.channel}
               </Grid>
             </Grid>
             </p>
           </CardContent>
         </CardActionArea>
       </Card>;
     }

  return (
    <div>{thumbJSX}</div>
  );
}

VideoThumb.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoThumb);
