import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import UserProfileCard from '../parts/UserProfileCard'
import UserPageNavigator from '../parts/UserPageNavigator'

function Recommended(props) {
  const { classes } = props;

  return (
      <div>
      
      </div>


  );
}



export default Recommended;
