import React, { Component } from 'react';
import './VideoCard.css';
//import more_vert from '@materia-ui/icons/more_vert';

function Card(props) {
  const video = props;
  let Channel = (<div style={{height: '8px'}}></div>);
  if (video.channel != null) {
    Channel = (
      <div class="channelLink">
        <svg class="channelIcon" viewBox="0 0 24 24"><use href="./images/icons/icons.svg#channel" /></svg><span class="channelTitle">{video.channel}</span>
      </div>
    )
  }
 
  
  return (
    <div class="videoCard">
      <div class="thumbWrapper">
        <img src={video.image} class="thumbnailImg" alt={props.title}/>
        <span class="thumbVideoDuration">{props.duration}</span>          
      </div>
      <div class="videoCardMetadata">
        <svg class="videoThumbnailMenu" viewBox="-6 0 24 24"><use href="./images/icons/icons.svg#dots-vertical" /></svg>
        <div class="thumbnailTitle ">{props.title}</div>  
        <div>  {Channel}</div>
        <div class="metaDateAge">
        {video.views!=null && <span>{video.views} Views • </span>  }
        {video.age!=null && <span>{video.age}</span>}
        </div>
      
      </div>
    </div>
  )
}

export default Card;
