import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import * as utils from '../functions/util.js';

function Dashboard(props) {
  const { classes } = props;
   const [content, setContent] = React.useState('');
 useEffect(() => {
  
     utils.getDashboard().then(function(result) {
      setContent(result)
     });
    
       
     
 },[]);
  return (
    <div dangerouslySetInnerHTML={{__html: content}} />
  );
}



export default Dashboard;
