/* eslint-disable */
import React, { useState, useEffect } from 'react';
import EditVideoitem from '../parts/videopage/EditVideoitem.js';
import Typography from '@material-ui/core/Typography';
import Age from '../functions/Age.js';
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as utils from '../functions/util.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ChannelTile from '../parts/Subscriptions/EditChannelTile.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { refresh } from '../action/refresh'
import { withRouter } from 'react-router-dom'

const mapDispatchToProps = dispatch => bindActionCreators({
  refreshMenu: () => dispatch(refresh),
}, dispatch)

const styles = {
  form: {
    margin: 30,
    color: '#ffce0a'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
    // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 300,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
async function fetchData(searchQuery, paramKey, param) {
  const searchResult = await API.graphql(
    graphqlOperation(searchQuery, { paramKey: param })
  );
  return searchResult;
}

function authorChannel(props) {
  const [Videos, setVideos] = useState([]);
  const [finishLoading, setFinish] = useState(false);
  let data, func
  console.log(props)
  const removeVideo = (id) => {
    setVideos(Videos.filter(video => video.id != id)); // remove from display
    props.refreshMenu();
  }
  const addChannel = () => {
    props.history.push('/editChannel')
  }
  const gotoChannel = (id) => {
    props.history.push('/Channel?id=' + id)
  }
  const getChannel = () => {
    setFinish(false)
    utils.getChannelbyOwner().then(function(result) {
      console.log(result)
      data = result.data.searchChannels.items
      console.log(data)
      let VideosArray = [];
      let playlistItem = data
      let numloaded = 0;
      for (var value of playlistItem) {

        var obj = {};
        obj.id = value.id;
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.poster_image_loc = value.iconImage

        VideosArray.push(obj)
        setVideos(VideosArray)

      }
      utils.getChannelbySecondaryAuthor().then(function(result) {
        data = result.data.searchChannels.items
        console.log(data)

        let playlistItem = data
        let numloaded = 0;
        for (var value of playlistItem) {

          var obj = {};
          obj.id = value.id;
          obj.video_title = value.title;
          obj.video_desc = value.description;
          obj.createdAt = value.createdAt;
          obj.poster_image_loc = value.iconImage

          VideosArray.push(obj)
          setVideos(VideosArray)

        }
        setFinish(true)

      });

    });
  }
  useEffect(() => {

    getChannel();
    setTimeout(function() {
      getChannel();
      props.refreshMenu();
    }.bind(this), 2000);


  }, []);

  if (finishLoading) {
    return (
      <div class="thumbnailContentBlock">
         <Typography variant="h5" gutterBottom>My Channels</Typography>
         <Button
            variant="contained"
            color="primary"
            onClick={()=>addChannel()}>Add New Channel</Button>
          <Grid container direction='row' sm={6}>
          {console.log(Videos)}
            {Videos.map(subscription =>
                <Grid item  alignItems="center" > 
                  <ChannelTile 
                    id = {subscription.id}
                    thumbnail = {subscription.poster_image_loc}
                    title = {subscription.video_title}
                    description = {subscription.video_desc}
                     removeMe = {removeVideo}
                     redirect = {props.history.push}
                    updated="date"
                    videos="count"
                    playlists="count"
                    subscribers="count"
                    />
                  </Grid>
            )}
                
          </Grid>
        </div>
    )
  }
  else return null;
}

export default connect(null, mapDispatchToProps)(withRouter(authorChannel));
