import React ,{useEffect,useState}from 'react';
import './PlayerPlaylist.css';
import Amplify, { API, graphqlOperation, Cache } from 'aws-amplify';
import getUserProfile from '../../functions/getUserProfile.js';
import * as utils from '../../functions/util.js'; 
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux' 
import { videoEnded } from '../../action/video' 
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import JSZip from "jszip"
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import DownloadIcon from '../../images/iconJS/Download.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import More from '../buttons/MoreHorizontalButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SharePlaylist from '../PlaylistShare.js';
let userInfo = Cache.getItem('userInfo')
const mapStateToProps = state => ({ 
  videoState: state.videoState, 
 
}) 

function PlayerPlaylist(props) {
  const [Videos, setVideos] = useState([]);
  const [index, setIndex] = useState(parseInt(props.index));
  const[autoplay, setAutoplay]= useState(props.autoplay);
  const [finishLoading, setFinish] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAlert, setAlertOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [data, setData] = React.useState({title:''});
  const [showPlaylistDownload, setShowPlaylistDownload] = React.useState(false);

    const gotoVideo=(i)=>{
     props.history.push(`/Watch?p=${props.playlistID}&index=${i}&autoplay=${autoplay}`)
     //console.log('index: ' +i)
     setIndex(i)
     props.refresh();
    }
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleShareOpen = () => {
    setAnchorEl(null);
    setShareOpen(true)
  };
   const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
    const handleClickAlertOpen = () => {
      setAlertOpen(true);
    };
  
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    const playNextVideo=()=>{
       
    console.log('playnextVideo() ' + index)
     if(autoplay){
     
      if(index<Videos.length){
        //console.log('should go next, currently at ' + index)
        gotoVideo(parseInt(index)+1);
       
      }
     }
    }
    
   const handleChange = name => event => {
       setAutoplay(event.target.checked)
      };
      
   const closeShare = (id) => {
      setShareOpen(false)
    }
    const copyIDtoClipboard = () => {
        navigator.clipboard.writeText(props.playlistID)
        if (isChrome || isFirefox) navigator.clipboard.writeText(props.playlistID)
        else if (isIE) {
          navigator.clipboard.writeText(content)
    
          window.clipboardData.setData('Text', props.playlistID);
          document.execCommand('copy');
        }
        handleMenuClose();
      }
  
  
    const downloadPlaylist=()=>{
     setAlertOpen(true)
      var count = 0;
      let totalFiles = 0;
      var zip = new JSZip();
     
      var zipFilename = `clips_playlist_${props.playlistID}.zip`;
     
      for (var value of Videos){ 
        console.log(value)
       
       if(value.id.length>13 && value.allowTrainerDownload == true){//audios
        utils.searchAudioByID(value.id).then(result => {
          console.log(result)
          let videoJS = JSON.parse(result.data.searchForAudio)
        console.log(videoJS)
          
           var urls = [

          `${videoJS.sourceURL}`,
          `${videoJS.thumbnail}`,
      ];
      if (videoJS.hasOwnProperty('captions') ) {
        if( videoJS.captions.length>0){
          videoJS.captions.map(c =>
              urls.push(c.src||c.file)
          )
        }
      }
      totalFiles = totalFiles + urls.length
     
      urls.forEach(function(url) { 
        { console.log(url) }
          var filename = url;
          
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function(err, data) {
              if (err) {
                  // console.log(err)
                  //setAnchorEl(null);
                  //handleDownloadClose(false)
                 // throw err; // or handle the error
              }
              let flattenName = filename.split('/')
              //console.log(filename)
              let ext = flattenName[flattenName.length - 1].split('.')
              if (ext[ext.length-1]!='vtt'){
               zip.file(videoJS.id+'/'+flattenName[flattenName.length - 1], data, { binary: true });
              }
              else{
                zip.file(videoJS.id+'/'+flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
              }
              
              count++;
              console.log('count = ' + count )
              console.log('total ='+ totalFiles)
              if(count == totalFiles){
              zip.generateAsync({ type: 'blob' }).then(function(content) {
                //saveAs(content, zipFilename);
              });
            }
          });
      });

        }).catch(err => console.log(err));
       }
       else{//videos
        //console.log(value.id)
        if(value.allowTrainerDownload == true){
        utils.searchVideoByID2(value.id).then(result => {

          let videoJS = JSON.parse(result.data.searchForAVideo)
        console.log(videoJS)
          
           var urls = [

          `${videoJS.videoURL}`,
          `${videoJS.thumbnail}`,
      ];
      if (videoJS.hasOwnProperty('captions') ) {
        if( videoJS.captions.length>0){
          videoJS.captions.map(c =>
              urls.push(c.src||c.file)
          )
        }
      }
      totalFiles = totalFiles + urls.length
     
      urls.forEach(function(url) { 
        { console.log(url) }
          var filename = url;
          
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function(err, data) {
              if (err) {
                  // console.log(err)
                  //setAnchorEl(null);
                  //handleDownloadClose(false)
                  //throw err; // or handle the error
              }
              let flattenName = filename.split('/')
              //console.log(filename)
              let ext = flattenName[flattenName.length - 1].split('.')
              if (ext[ext.length-1]!='vtt'){
               zip.file(videoJS.id+'/'+flattenName[flattenName.length - 1], data, { binary: true });
              }
              else{
                zip.file(videoJS.id+'/'+flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
              }
              
              count++;
              console.log('count = ' + count )
              console.log('total ='+ totalFiles)
              if(count == totalFiles) {
                  zip.generateAsync({ type: 'blob' }).then(function(content) {
                  saveAs(content, zipFilename);
                  });
            }
          });
      });

        }).catch(err => console.log(err));
          }//end allowTrainerDownload
       }// end if videos
      }
    
    }
    const getVideoAdded=(video,index)=> {
        let thisClass
        //console.log(video)
        //console.log(props.index + ' '+ index)
        props.index != (index+1) ? thisClass = `playlist-item-order` : thisClass= `playlist-item-order-active`                                                         
       
        return  <div class="player-playlist-item" onClick={()=>gotoVideo(index+1)}>
            <div class="playlist-item-thumb-row">
              <div class="playlist-item-thumb">
              {(video.allowTrainerDownload)&& <div class="videoDownload"><DownloadIcon style={{margin:4}} /></div>}
                <img src={video.thumbnail} alt="" />
                <div class="playlist-item-duration">
                {video.duration}
                </div>
              </div>
              
              <div class={thisClass}>
                <span>{index+1}</span>
              </div>
            </div>
            <div class="playlist-item-title">
              <h5>
               {video.title}
              </h5>
            </div>
          </div>
    
  }
  
    useEffect(() => {
      console.log('player playlist')
        utils.searchPlaylistbyID (props.playlistID).then(function(result) {
           // console.log(result)
         let  thisPlaylist =result.data.searchPlaylists.items[0]
          console.log(props) // let thisPlaylist = data.find(x => x.id === props.playlistID)
          let VideosArray = [];
          let playlistItem = thisPlaylist
          console.log(thisPlaylist)
          setData(thisPlaylist)
          let vArray=thisPlaylist.videos.items
          let aArray = thisPlaylist.audios.items
          playlistItem = vArray.concat(aArray)
        
           if( thisPlaylist.videoArray){
              playlistItem = utils.reorderVideo (playlistItem, thisPlaylist.videoArray,'videoId'||'audioId')
            }
           else{
            //playlistItem = thisPlaylist.videos.items
           }
            
          console.log(playlistItem)
          for (var value of playlistItem) {
            if(value.allowTrainerDownload==true) setShowPlaylistDownload(true)
            console.log(value)
            var obj = {};
            obj.allowTrainerDownload = value.allowTrainerDownload
             obj.id = value.videoId||value.audioId ;
             obj.thumbnail = value.thumbnail
             obj.title = value.title
             obj.duration = utils.formatDuration(value.videoDuration||value.audioDuration)
             console.log(obj)
            VideosArray.push(obj)
          }
           setVideos(VideosArray) 
         setFinish(true)
        });
        /*
     getUserProfile("").then(function(result) {
         // console.log(result)
          data =result.data.getUserProfile.playlist.items
          console.log(props)
          let thisPlaylist = data.find(x => x.id === props.playlistID)
          let VideosArray = [];
          let playlistItem = thisPlaylist
           if( thisPlaylist.videoArray ){
              playlistItem = utils.reorderVideo (thisPlaylist.videos.items, thisPlaylist.videoArray,'videoId')
            }
           else{
            playlistItem = thisPlaylist.videos.items
           }
            
        
          for (var value of playlistItem) {
            console.log(value)
            var obj = {};
             obj.id = value.videoId ;
             obj.thumbnail = value.thumbnail
             obj.title = value.title
             obj.duration = utils.formatDuration(value.videoDuration)
            VideosArray.push(obj)
          }
           setVideos(VideosArray) 
         setFinish(true)
        });
        */
      if(props.videoState =='ended') {
        playNextVideo()
        console.log('play next')
      }
      console.log(props.videoState)
   }, [props.videoState]);
   
    return (
        <div class="player-playlist">
        <div class="autoplay-controller-wrapper">
        <Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle color="secondary" id="alert-dialog-title">{"Download Playlist"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This might take a couple of minutes to complete!<br/><br/>

Clips is zipping all the files with Trainer Download enabled in this playlist into one downloadable package.<br/><br/>

Navigating away from this page before the download is complete will cancel the download request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} variant="contained" color="secondary">
            Close
          </Button>
        
        </DialogActions>
    
      </Dialog>
          <Grid container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={9}><h4>Playlist</h4></Grid>
            <Grid item xs={3}><More  menuFunc={handleMenuClick}/></Grid>
          </Grid>
          <p>{data.title}</p>
         
         
        
      
            <FormControlLabel
              control={
                <Switch checked={autoplay} onChange={handleChange()}  />
              }
              label="Autoplay"
            />
      
        <div class="player-playlist-items">
         {Videos.map((video,index) => getVideoAdded(video,index))}
    
        </div>
        </Grid>
      </div>
         <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        
        <MenuItem onClick={copyIDtoClipboard }>Copy Playlist ID to Clipboard</MenuItem>
        <MenuItem onClick={handleShareOpen }>Share</MenuItem>
        {(props.userRole !='Viewer' && showPlaylistDownload==true) && <MenuItem onClick={downloadPlaylist }>Trainer Download</MenuItem> }
        
      </Menu>
        <SharePlaylist open={shareOpen} close={closeShare} id={props.playlistID} title={data.title} />
      </div>
        )
}

export default connect(mapStateToProps, null)(withRouter(PlayerPlaylist));