import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loggedReducer from './login'
import menuReducer from './menu'
import refreshReducer from './refresh'
import  profilePicReducer from './profile'
import  videoReducer from './video'

const rootReducer = (history) => combineReducers({
  isLogged: loggedReducer,
  isMenuOpen: menuReducer,
  refresh:refreshReducer,
  profilePicRefresh: profilePicReducer,
  videoState: videoReducer,
  
  router: connectRouter(history)
})

export default rootReducer

