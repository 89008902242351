import React, { useState, Fragment } from "react";
import ReactDOM from "react-dom";
import Grid from '@material-ui/core/Grid';

import ThumbUp from '@material-ui/icons/ThumbUp';
import * as utils from '../../functions/util.js'; 
import { withStyles } from '@material-ui/core/styles';

 const styles = {
    unlike:{
       color:'none',
       cursor:'pointer', 
       "&:hover": {
         textDecoration:'underline'
        },
    },
    like:{
      color:'#E20074',
      fontWeight: '900', 
       cursor:'pointer',
        "&:hover": {
         textDecoration:'underline'
        },
    }
  }
  
function Like(props) {
  const { classes } = props;
  console.log(props)
  const [like, setLike] = useState(props.obj.like);
  
  const vidID = props.vidID;
 
  const toggleLike =() =>{
    setLike(!like)
  }
  
  const likeHandle = (id)=>{
    !like? likeThisVideo(id):unlikeThisVideo(id)
    toggleLike();
  }
  
   const likeThisVideo = (id)=>{
    utils.likeVideo(props.obj)
  }
  
  const unlikeThisVideo = (id)=>{
    utils.unlikeVideo(props.obj.id)
  }
  if(props){
    return (
        <Grid container alignItems="center" style={{flexWrap: 'nowrap', marginLeft: '1em'}} onClick={()=>{likeHandle(props.id)}} className={like?classes.like:classes.unlike}>
          <ThumbUp/><span style={{marginLeft: '0.5em'}}>Like</span>
        </Grid>
    );
  }
}

export default  withStyles(styles)(Like);
