import React, { useState, Fragment } from "react";
import ReactDOM from "react-dom";
import Grid from '@material-ui/core/Grid';

import Star from '@material-ui/icons/Star';
import * as utils from '../../functions/util.js'; 
import { withStyles } from '@material-ui/core/styles';

 const styles = {
    off:{
       color:'none',
       cursor:'pointer',
       "&:hover": {
         textDecoration:'underline'
    },
    },
    on:{
      color:'#E20074',
        fontWeight: '900', 
       cursor:'pointer',
       "&:hover": {
         textDecoration:'underline'
    },
    }
  }
  
function Favorite(props) {
  console.log(props)
  const { classes } = props;
 const [favorite, setLike] = useState(props.obj.favourite);
  
  console.log(props)
  const vidID = props.obj.id;
 
  const toggle =() =>{
    setLike(!favorite)
  }
  
  const clickHandle = (id)=>{
    console.log(id)
    !favorite? favoriteThisVideo (id):unfavoriteeThisVideo(id)
    toggle();
  }
  
   const favoriteThisVideo = (id)=>{
     utils.favoriteVideo(props.obj)
  }
  
  const unfavoriteeThisVideo = (id)=>{
    utils.unfavoriteVideo(props.obj.id)
  }
  if(props){
  return (
      <Grid container alignItems="center" style={{flexWrap: 'nowrap', marginLeft: '1em'}} onClick={()=>{clickHandle(props.id)}} className={favorite?classes.on:classes.off}>
        <Star  /><span style={{marginLeft: '0.5em'}}>Favorite</span>
      </Grid>


  );
  }
}



export default withStyles(styles)(Favorite);
