import React, { useState } from 'react';
import dateSelected from '../../images/icons/sort/DateAlphaLikeFav/dateSelected.svg';
import date from '../../images/icons/sort/DateAlphaLikeFav/date.svg';
import alphaSelected from '../../images/icons/sort/DateAlphaLikeFav/alphaSelected.svg';
import alpha from '../../images/icons/sort/DateAlphaLikeFav/alpha.svg';
import likeSelected from '../../images/icons/sort/DateAlphaLikeFav/likeSelected.svg';
import like from '../../images/icons/sort/DateAlphaLikeFav/like.svg';
import favSelected from '../../images/icons/sort/DateAlphaLikeFav/favSelected.svg';
import fav from '../../images/icons/sort/DateAlphaLikeFav/fav.svg';
import './Sort.css';

const SortDateAlphaLikeFav = (props) => {
  const [sort, setSort] = useState('date');
  function handleClick(sortBy) {
    setSort(sortBy);
    //console.log('change to', sort);
    const widget = document.getElementById("sortDateAlphaLikeFav").getElementsByTagName('img');
    new Image().src = date;
    new Image().src = alphaSelected;
    new Image().src = likeSelected;
    new Image().src = favSelected;
    function resetSort(){
      widget[0].src = date;
      widget[1].src = alpha;
      widget[2].src = like;
      widget[3].src = fav;
    }
    switch(sortBy) {
      case 'date':
        props.sortDate()
        resetSort();
        widget[0].src = dateSelected;
        break;
      case 'alpha':
        props.sortAlpha()
        resetSort();
        widget[1].src = alphaSelected;
        break;
      case 'like':
        props.sortLike();
        resetSort();
        widget[2].src = likeSelected;
        break;
      case 'fav':
        props.sortFav();
        resetSort();
        widget[3].src = favSelected;
        break;
      default:
        resetSort();
        widget[0].src = dateSelected;
        break;
    }
  }
  return (
    <div class="sort-wrapper" id="sortDateAlphaLikeFav">
      <span class="sort">Sort</span>
      <img 
        id="dateImg"
        src={dateSelected}
        alt="Sort by Date"
        height="33px"
        width="45px" 
        class="sort-date-alpha-like-fav"
        onClick={() => handleClick("date")}
      />
      <img 
        id="alphaImg"
        src={alpha}
        alt="Sort by Date"
        height="33px"
        width="45px" 
        class="sort-date-alpha-like-fav"
        onClick={() => handleClick("alpha")}
      />
      <img 
        id="likeImg"
        src={like}
        alt="Sort by Likes"
        height="33px"
        width="45px" 
        class="sort-date-alpha-like-fav"
        onClick={() => handleClick("like")}
      />
      <img 
        id="favImg"
        src={fav}
        alt="Sort by Favorites"
        height="33px"
        width="45px" 
        class="sort-date-alpha-like-fav"
        onClick={() => handleClick("fav")}
      />
    </div>
  )
}

export default SortDateAlphaLikeFav;