import * as React from "react";

function SvgDownload(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="56" height="56" viewBox="0 0 56 56">
  <defs>
    <filter id="Rectangle_981" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse">
      <feOffset dx="2" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.576"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_1501" data-name="Group 1501" transform="translate(-859 -170)">
    <g transform="matrix(1, 0, 0, 1, 859, 170)" filter="url(#Rectangle_981)">
      <rect id="Rectangle_981-2" data-name="Rectangle 981" width="38" height="38" rx="5" transform="translate(7 7)" fill="#e11274"/>
    </g>
    <path id="Path_345" data-name="Path 345" d="M22.256,18.829A2.244,2.244,0,0,0,24.5,16.585V4.244A2.244,2.244,0,0,0,22.256,2H10.431a4.518,4.518,0,0,1,.606,2.244h11.22V16.585h-10.1v2.244M16.646,7.61V9.854H9.915V24.439H7.671V17.707H5.427v6.732H3.183V15.463H1.5V9.854A2.244,2.244,0,0,1,3.744,7.61h12.9M8.793,4.244A2.244,2.244,0,1,1,6.549,2,2.244,2.244,0,0,1,8.793,4.244Z" transform="translate(872.5 183)" fill="#fff"/>
  </g>
</svg>
  );
}

export default SvgDownload;
