import React, { useState, useEffect, Component } from 'react';
import Amplify, { API, graphqlOperation, Auth, Hub, Logger, Cache } from 'aws-amplify';
import queryString from 'query-string'
import Splash from './Splash.js';
import App from './App.js'
import Dashboard from './Dashboard.js'
import * as utils from '../functions/util.js';
//eric 
//import '../index.css'; 

function Gateway(props) {

    const [finish, setFinish] = useState(false);
    const [thisUser, setUser] = useState({})
    const [isLogged, setLogged] = useState(false);
    const [gotoDashboard, setGotoDashboard] = useState(false);
    useEffect(() => {
     
       // console.log(window.location.href)
        var urlParams = new URLSearchParams(window.location.search);
        var gotoDash = urlParams.get('dashboard');
        console.log(gotoDash)
       if(gotoDash == 'true') setGotoDashboard(true)

        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.log(event)
            switch (event) {
                case "signIn":
                    console.log('Signin')

                    getUser(data.username)
                    setLogged(true)
                    break;
                case "signOut":
                    // this.setState({ isLogged: false }); 
                    console.log('sign out ')
                    break;
            }
        });
        
        Auth.currentAuthenticatedUser({

        }).then(user => {
            console.log('signin')
            getUser(user.username)
            setLogged(true)


        }).catch(e => {
            //this.setState({ isLogged: false }); 
            setLogged(false)

            let url = window.location.href
            // let path = url.split('/')
            if (localStorage.getItem("path") == null || localStorage.getItem("path") == '') localStorage.setItem("path", url);

            //document.cookie = path + "=" + url  + ";"

        });

    }, []);



    const getUser = async(userID) => {
        console.log('getUser')
        const searchUsers = `query searchUsers($userID:String) { 
        searchUsers(filter:{id:{matchPhrasePrefix:$userID}}) { 
          items{ 
            id 
            employeeType
             profilePhotoURL 
               familyName 
              givenName 
              company 
              department 
              groupName 
              groups 
              jobTitle 
              biography 
              interests 
              managerName
              managerEmail 
              role 
              name 
              authors 
              lastLogin 
              email 
          } 
        } 
      }`;
        const searchResult = await API.graphql(
            graphqlOperation(searchUsers, { "userID": userID })
        );


        const user = searchResult.data.searchUsers.items[0]
        Cache.setItem('userInfo', user)
        console.log(user)
        setUser(user)
    }

    //profileData = getUserProfile(user.id) 
    // if(user.groupName !=='Not Selected') this.setState({finish:true }) 
   // console.log(thisUser)
    let objIsEmpty = Object.keys(thisUser).length === 0 && thisUser.constructor === Object
    //console.log(objIsEmpty)
    // console.log(isLogged) 
    if (!isLogged) return <Splash isLogged={isLogged}/>
    else if (isLogged) {
        
      
            
            if (thisUser.biography == null) return <Splash isLogged={isLogged} obj={objIsEmpty}/>
            else if(!objIsEmpty && gotoDashboard) return <Dashboard/>
            else if (!objIsEmpty) return <App/>
       
        else return <App/>
    }





}


export default Gateway;
