import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 660,
    backgroundColor: '#000',
  },
}));



function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();
  const onDownload = (file) => {
    const link = document.createElement("a");
    link.download = file;
    link.href = file;
    link.click();
  };
  let cdn = process.env.REACT_APP_CLOUDFRONT_URL.split('public')
  let cdnPath= `${cdn[0]}Clips-Reports/`

  let dataDownload = [
    {label:'User Table [Trainers]',url:`${cdnPath}userTable.csv`},
    {label:'User Table [Administrator, Author, Channel-Author, Podcast-Author',url:`${cdnPath}userTableContributors.csv`},
    {label:'Video Table',url:`${cdnPath}videoTable.csv`},
    {label:'Audio Table',url:`${cdnPath}audioTable.csv`},
    {label:'Channel Table',url:`${cdnPath}channelTable.csv`},
    {label:'ChannelVideo Table',url:`${cdnPath}channelvideoTable.csv`},
    {label:'Collections Table',url:`${cdnPath}collectionTable.csv`},
    {label:'SubCollections Table',url:`${cdnPath}subcollectionTable.csv`},
    {label:'Download All',url:`${cdnPath}reports.zip`}
]

  console.log(cdnPath)
  return (
    <div className={classes.root}>
        
    
       
      <List component="nav" aria-label="main mailbox folders">
      {dataDownload.map((data)=>(
              <ListItem    onClick={event => onDownload(data.url)}  button> 
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary={data.label} />
            </ListItem>
        ))}
      </List>
     
    </div>
  );
}