import React from 'react';
import './VideoCard.css';
import { withRouter } from "react-router";

function VideoCardRowHeader(props) {
    let dest = props.dest
    const style = {
     cursor:'pointer'
    }
    return (
      <div class="contentHeader" style={style} onClick={()=>props.history.push(dest)}>
        <h2 class="contentHeader contentHeaderDivider" >{props.title}</h2>
      </div> 
    )
}

export default withRouter(VideoCardRowHeader);