import React, { useState, useEffect } from 'react';
import '../style/VideoCard.css';
import { Cache } from 'aws-amplify';
import Wrap from '../functions/Wrap.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoInfo from './videoInfo.js';
import DownloadIcon from '../images/iconJS/Download.js';
import * as utils from '../functions/util.js';
import ShareVideo from '../parts/videoShare.js';
import JSZip from "jszip"
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


function AudioCard(props) {
  console.log(props)
  const user = Cache.getItem('userInfo')
  
  const id = props.id;
  const date = new Date(props.activity);
  const ActivityDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const thisClass = props.type!='static'? 'vcard':'vcardStatic'
  const [cAnchorEl, setcAnchorEl] = React.useState(null);
  const [copen, setcOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
   const [infoOpen, setInfoOpen] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [video, setVideo] = React.useState(props);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [menuitem, setMenu] = React.useState(props.scheduledArchived?props.videoMenu.filter(menu => menu.name != "Archive"):props.videoMenu);
  const [fitThumbURL, setFitThumbnail] = React.useState(props);
 // if(props.scheduledArchived) setMenu(props.videoMenu.filter(menu => menu.name == "Archive"))
 
  const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
  let extension=['']
  //console.log(server)
  //const extension = video.image.split('.')
  //const fitThumbURL = `https://${server[2]}/fit-in/220x124/public/vco/${id}/poster.${extension[extension.length-1]}`
 
  useEffect(() => {
    console.log(props)
    //console.log('use effect nav')

    const userInfo = Cache.getItem('userInfo')
    // console.log(userInfo)
    if (props.hasOwnProperty('thumbnail')){console.log('thumnail'); extension = props.thumbnail.split('.')}
    else if(props.hasOwnProperty('image')) extension = props.image.split('.')
  
   setFitThumbnail(`https://${server[2]}/fit-in/220x124/public/vco/${id}/poster.${extension[extension.length-1]}`)

    utils.searchAudioByID(id).then(value => {
       // console.log(value.data.searchForAVideo)
        let vid = {}
      
        vid = JSON.parse(value.data.searchForAudio)
        console.log(vid)
        if(vid!='No video found'){
            if(props.hasOwnProperty('age')) vid.age = props.age
            setVideo(vid)
           
        }
       // console.log(vid)
    });
    //console.log(categories)
}, [props]);


  const handleWatch = () => {
   if( props.type!='static'){
    props.history.push('/Watch?v=' + id)
   }
  }
  const handleDownloadToggle = () => {
    setDownloading(!downloading);
  };
  function sourceFileExists(id) {
    let sourceURL = `${process.env.REACT_APP_CLOUDFRONT_URL}vco/${id}/sources/video.mp4`
    var http = new XMLHttpRequest();
    http.open('HEAD', sourceURL, false);
    http.send();
    return [http.status != 403, sourceURL];
}
  const handleSourceDownload = () => {
    console.log(video)
    handleDownloadToggle()
    setAnchorEl(null);
    
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `clips_source_${video.id}.zip`;
    var urls = [

        `${video.source}`,
        `${video.image||video.thumbnail}`,
    ];
    if (video.captions !== null&&video.captions!==undefined) {
        video.captions.map(c =>
            urls.push(c.file)
        )
    }
    if (video.hasOwnProperty('transcripts')) {
        video.transcripts.map(c =>
            urls.push(c.url)
        )
    }

    urls.forEach(function(url) { { console.log(url) }
        var filename = url;
        // loading a file and add it in a zip fileff
        JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
                 console.log(data)
                setSnackOpen(true)
                //throw err; // or handle the error
            }
            let flattenName = filename.split('/')
            //console.log(filename)
            zip.file(flattenName[flattenName.length - 1], data, { binary: true });
            count++;

            if (count == urls.length) {
                console.log('download')
                handleDownloadClose(false)
                zip.generateAsync({ type: 'blob' }).then(function(content) {
                    saveAs(content, zipFilename);
                   
                });
            }

        });

    });

};

  const handleDownload = () => {
    
    handleDownloadToggle()
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `clips_${video.id}.zip`;
    var urls = [

        `${video.audioURL}`,
        `${video.thumbnail}`
    ];
    if (video.captions !== null) {
        video.captions.map(c =>
            urls.push(c.file)
        )
    }
    if (video.hasOwnProperty('transcripts')) {
        video.transcripts.map(c =>
            urls.push(c.url)
        )
    }
    urls.forEach(function(url) { { console.log(url) }
        var filename = url;
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
                // console.log(err)
               // handleDownloadClose(false)
                throw err; // or handle the error
            }
            let flattenName = filename.split('/')
            //console.log(filename)
            zip.file(flattenName[flattenName.length - 1], data, { binary: true });
            count++;

            if (count == urls.length) {
                console.log('download')
                handleDownloadClose(false)
                zip.generateAsync({ type: 'blob' }).then(function(content) {
                    saveAs(content, zipFilename);
                    setAnchorEl(null);
                });
            }
        });
    });
};
  
   const openInfoDiaglog = (id) => {
    setInfoOpen(true);
    setAnchorEl(null);
  }
  const handleInfoClose = () => {
     setOpen(false)
    setInfoOpen(false);
  };
  
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
   const handleChannelMenuClick = event => {
    setcAnchorEl(event.currentTarget);
  };
   const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  
   const handleChannelClose= () => {
    setcAnchorEl(null);
    setcOpen(false)
  };
  
   const handleShareOpen= () => {
    setAnchorEl(null);
    setShareOpen(true)
  };
  const handleDownloadClose = () => {
    setDownloading(false);
  };
 
   const gotoChannel = (id) => {
    if( props.type!='static'){
     props.history.push('/Channel?id=' +id)
    }
  }
  const openShare=(id)=>{
      setShareOpen(true)
  }
  
   const closeShare=(id)=>{
      setShareOpen(false)
  }
 const videoMenu = ()=> {
   return(
     
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      
       {menuitem.map(menu =>
       
          
                    <StyledMenuItem onClick={()=>{
                      
                        menu.func(props.id);
                        setAnchorEl(null);
                    }}>
                       <ListItemText primary={menu.name} />
                    </StyledMenuItem>
               
             
        )}
         {(user.role=="Administrator" || user.role=="Podcast-Author"||user.role=="Channel-Author"||user.role=="Author")&&
          <StyledMenuItem onClick={()=>handleSourceDownload()}>
            <ListItemText primary={"Download Source"} />
        </StyledMenuItem>
        }
         {(user.role!="Viewer" && props.allowTrainerDownload)&&
          <StyledMenuItem onClick={()=>handleDownload()}>
            <ListItemText primary={"Trainer Download"} />
        </StyledMenuItem>  
        }
        
          <StyledMenuItem onClick={()=>handleShareOpen()}>
            <ListItemText primary={"Share"} />
        </StyledMenuItem>
          <StyledMenuItem onClick={()=>openInfoDiaglog()}>
            <ListItemText primary={"Properties"} />
        </StyledMenuItem>
    
       
      </StyledMenu>
     )
 }

 
 const channelMenu = ()=> {
   return(
     
      <StyledMenu
        id="customized-menu"
        anchorEl={cAnchorEl}
        keepMounted
        open={Boolean(cAnchorEl)}
        onClose={handleChannelClose}
      >
       {props.channel.items.map(menu =>
        <StyledMenuItem onClick={()=>{
           gotoChannel(menu.channel.id)
            setcAnchorEl(null);
        }}>
          <ListItemText primary={menu.channel.title} />
        </StyledMenuItem>
        
        )}
          
          {(user.role!="Viewer" && props.allowTrainerDownload)&&
          <StyledMenuItem onClick={()=>handleDownload()}>
            <ListItemText primary={"Trainer Download"} />
        </StyledMenuItem>  
        }
       
      </StyledMenu>
     )
 }

  return (
     <div class={thisClass} id="49mwdxLb">
  <div class="topHover"></div>
  <div class="vcardThumb" onClick={handleWatch}>
  {(user.role!="Viewer" && props.allowTrainerDownload)&& <div class="videoDownload"><DownloadIcon style={{margin:4}} onClick={()=>handleDownload()}/></div>}
    <LazyLoadImage src={fitThumbURL} alt="video thumbnail" alt={props.title}/>
    <div class="videoDuration">{props.duration}</div>
  </div>
  <div class="vcardMetadataWrapper">
    <div class="videoProgressBar videoProgressBarNone"><div class="videoProgress videoProgressNone"></div></div>
    <div class="vcardMetadata">
    {props.type!='static' &&
      <div class="vcardMoreButton" onClick={handleClick}>
        <svg style={{width:24+'px',height:24+'px'}} viewBox="0 0 18 24">
        <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
    </svg>
      </div>
    }
      <h1 class="videoTitle">
      {props.title}
      </h1>
      {/*console.log(props.channel)*/}
    {props.channel!==undefined &&
    <div>
      {props.channel.items.length>0 &&
      <div class="channelRow">
        <div class="videoChannel" onClick={()=>gotoChannel(props.channel.items[0].channel.id)}>
          <span class="channelTitle">{props.channel.items[0].channel.title} </span><span>{'  '}</span>
          <span class="channelIcon">
            <svg style={{width:16, height:16, verticalAlign:'top',leftPadding:3 }} viewBox="0 0 24 19">
               <path d="M2.5,4.5H21.5C22.34,4.5 23,5.15 23,6V17.5C23,18.35 22.34,19 21.5,19H2.5C1.65,19 1,18.35 1,17.5V6C1,5.15 1.65,4.5 2.5,4.5M9.71,8.5V15L15.42,11.7L9.71,8.5M17.25,21H6.65C6.35,21 6.15,20.8 6.15,20.5C6.15,20.2 6.35,20 6.65,20H17.35C17.65,20 17.85,20.2 17.85,20.5C17.85,20.8 17.55,21 17.25,21Z" />
            </svg>
          </span>
        </div>     
        {props.channel.items.length>1 &&
        <span class="moreChannels" onClick={handleChannelMenuClick}>
          <svg style={{width:24,height:24}} viewBox="0 0 24 20">
              <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
          </svg>
        </span>
        }
      </div>   
        
      }
      </div>
       }
      <span class="videoViewsAge">
       {!props.noDate  &&<span> {video.audioViews||'0'} Plays • </span>}
         {video.age}</span>
    </div>
    <ShareVideo open={shareOpen} close={closeShare} id={id} />
     <VideoInfo open={infoOpen} close={handleInfoClose} id={id} />
  </div>
  {props.videoMenu!=null &&
   videoMenu()
  }
  
  {props.channel!==undefined &&
   channelMenu()
  }
  
 
  
</div>
  )
  
}

export default withRouter(AudioCard);
