const videoReducer = (state = 'null', action) => {
    switch(action.type){
        case 'VIDEO_ENDED':
            return 'ended';
        case 'VIDEO_STARTED':
            return 'started';
        default:
            return state;
    }
};

export default videoReducer;
