import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import VideoCard from '../VideoCard.js';
import './Channel.css';
import SortDateAlpha from '../widgets/SortDateAlpha';

import Wrap from '../../functions/Wrap.js';
import Age from '../../functions/Age.js';
import * as utils from '../../functions/util.js';

//col channel-no-padding m-0
var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';
const copyVideoID = (id) => {
  navigator.clipboard.writeText(id)
    if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(id)

        window.clipboardData.setData('Text', id);
        document.execCommand('copy');
    }

}



function ChannelAudios(props) {
    console.log(props)
    const [playlist, setVideos] = useState([]);
    let data
    const [finishLoading, setFinish] = useState(false);

    useEffect(() => {
        setFinish(false)
       // console.log(props)
        data = props.audios.items
        console.log(data)
        let VideosArray = [];
        let playlistItem = data
        let numloaded = 0;
        playlistItem = playlistItem.filter(video => video != null)
        console.log(playlistItem)
        for (var value of playlistItem) {
            var obj = {};
            console.log(value)
            obj.id = value.audio.id;
            obj.video_title = value.audio.title;
            // obj.video_desc = value.description;
            obj.poster_image_loc = value.audio.thumbnail
           // obj.views = value.audio.videoViews
            // obj.duration = value.videoDuration
            obj.duration = utils.formatDuration(value.audio.audioDuration)
            const date = new Date(value.audio.createdAt)
            obj.createdAt = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
            VideosArray.push(obj)



        }
        setFinish(true)
        setVideos(VideosArray)

        //  console.log(playlist)
    }, [props]);
    if (finishLoading) {
        return (
            <Wrap>  
      <div class="d-flex flex-row align-items-center mt-1 mb-1">
        <svg class="channel-videos-title-icon" viewBox="0 2 24 24"><use href="images/icons/icons.svg#videos" /></svg>
        <h1 class="SprintSansWeb-Light video-playlist-header">Audio</h1>
        {/*<SortDateAlpha style={{margin: '0'}}/>*/}
      </div>
      <div id="videoItems" class="d-flex flex-row flex-wrap mb-1 max-w" >
        {playlist.map(item =>
           
           
              <VideoCard
            id={item.id}
            image={item.poster_image_loc}
            title={item.video_title}
           
            views={item.views}
              age = {Age(item.createdAt)}
          
            duration = {item.duration}
             videoMenu={[ 
                      {name:'Copy Video ID', func:copyVideoID},
                     
                      
                      ]}
          />
          
        )}
      </div>
    </Wrap>
        )
    }
    else return null;
}

export default ChannelAudios;
