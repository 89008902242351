export const videoEnded = () => ({
  type: 'VIDEO_ENDED',
})

export const videoStarted = () => ({
  type: 'VIDEO_STARTED',
})

export const toggleAutoplay = () => ({
  type: 'TOGGLE_AUTOPLAY',
})