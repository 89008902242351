import React, {Component} from 'react';
import footer, {ReactComponent as VcmsFooter} from  "../images/brand/footer-logo.svg"

const footerImgStyle = {
  width:'306px',
  height:'69px',
  margin:'20px',
  marginBottom: '0',
}
const copyrightStyle = {
  color:'#ccc',
  fontSize:'12px',
  margin:'20px',
  marginTop: 'auto',
  flex: '0 1 auto',
  alignSelf: 'auto',
}
const footerBlock = {
  display: 'flex',
  flex: '1',
  flexWrap: 'no-wrap',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignContent: 'flex-end',
  alignItems: 'flex-start',
  flexBasis: '100%',
  paddingTop: '20px',
}
const footerSpacer = {
  flex: '1 0 auto',
  alignSelf: 'auto',
}
const year = new Date().getFullYear();

class Footer extends Component {
  render() {
    return (
      <div style={footerBlock}>
        <div style={footerSpacer}></div>
        {/*
        <img src={footer} style={footerImgStyle} alt="T-Mobile"/>
        */}
        <div style={copyrightStyle}>
          {'Clips \u00A9 '+year+', T-Mobile'}
        </div>
      </div>
    );
  }
}
export default Footer;
