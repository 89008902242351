import * as React from "react";

function SvgSadVid(props) {
  return (
    <svg viewBox="0 0 268 268" {...props}>
      <defs>
        <style>{".sadVid_svg__cls-6{fill:#e20074}"}</style>
      </defs>
      <g id="sadVid_svg__Layer_2" data-name="Layer 2">
        <g id="sadVid_svg__Layer_1-2" data-name="Layer 1">
          <g id="sadVid_svg__Group_1235" data-name="Group 1235" fill="#fff">
            <path
              id="sadVid_svg__ic_slow_motion_video_24px"
              data-name="ic slow motion video 24px"
              d="M39.35 39.35A134.59 134.59 0 01219 29.84L199.76 49A107.5 107.5 0 0049 199.76L29.84 219a134.59 134.59 0 019.51-179.65z"
            />
            <path
              id="sadVid_svg__ic_slow_motion_video_24px-2"
              data-name="ic slow motion video 24px-2"
              d="M268 120.89a134 134 0 00-29.56-71.56l-19.19 19.19A106.83 106.83 0 01240.92 121z"
              opacity={0.85}
            />
            <path
              id="sadVid_svg__ic_slow_motion_video_24px-3"
              data-name="ic slow motion video 24px-3"
              d="M219.25 200.24l19.2 19.2A133.77 133.77 0 00268 147.88h-27.18a105.67 105.67 0 01-21.57 52.36z"
              opacity={0.65}
            />
            <path
              id="sadVid_svg__ic_slow_motion_video_24px-4"
              data-name="ic slow motion video 24px-4"
              d="M147.78 240.92l.1 27.08a133.7 133.7 0 0071.56-29.56l-19.2-19.19a106.83 106.83 0 01-52.46 21.67z"
              opacity={0.45}
            />
            <path
              id="sadVid_svg__ic_slow_motion_video_24px-5"
              data-name="ic slow motion video 24px-5"
              d="M68.52 219.25l-19.19 19.19A133.64 133.64 0 00120.89 268l.09-27.08a106.83 106.83 0 01-52.46-21.67z"
              opacity={0.25}
            />
          </g>
          <g id="sadVid_svg__Group_1333" data-name="Group 1333">
            <ellipse
              id="sadVid_svg__Ellipse_4"
              data-name="Ellipse 4"
              className="sadVid_svg__cls-6"
              cx={98.74}
              cy={95.13}
              rx={20}
              ry={19.5}
            />
            <circle
              id="sadVid_svg__Ellipse_5"
              data-name="Ellipse 5"
              className="sadVid_svg__cls-6"
              cx={166.24}
              cy={95.13}
              r={19.5}
            />
            <rect
              id="sadVid_svg__Rectangle_860"
              data-name="Rectangle 860"
              className="sadVid_svg__cls-6"
              x={73.91}
              y={154.42}
              width={126.69}
              height={18.1}
              rx={1}
              transform="rotate(-19.99 137.28 163.492)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSadVid;
