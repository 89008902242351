import React, { component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import Amplify, { API, Storage, graphqlOperation, Auth, Hub, Logger, Cache } from 'aws-amplify';
import { toggleMenu } from '../action/menu'
import compose from 'recompose/compose';
import * as Queries from '../graphql/queries.js'
import * as utils from '../functions/util.js';
import { connect } from 'react-redux'

import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { logIn, logOut } from '../action/login'
import logo from '../images/brand/clips-logo-main.svg'
import AutoComplete from './AutoCompleteSerch.js';
import getUserProfile from '../functions/getUserProfile.js';

let login_button

const mapStateToProps = state => ({
  isLogged: state.isLogged,
  isMenuOpen: state.isMenuOpen,
  refreshPic: state.profilePicRefresh,
  routes: state.router,
  store: state.store
})
const mapDispatchToProps = dispatch => bindActionCreators({
  logIn: () => dispatch(logIn()),
  logOut: () => dispatch(logOut()),
  toggleMenu: () => dispatch(toggleMenu),
  //search: (keyword) => push('#/Search?keyword=' + keyword)
}, dispatch)

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    flex: 1,
    width: '100%',
  },
  grow: {
    width: '100%', //eric
    flexGrow: 0, //eric
    zIndex: 2,
  },
  toolBar: {
    maxWidth: '1360px',
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 12,
  },
  logo: {
    height: '38px', //eric
    //display: 'none', //eric
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      filter: 'drop-shadow(2px 3px 3px black)',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    flexShrink: '0', //eric
    position: 'relative',
    border: '1',
    borderRadius: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    //backgroundColor: fade(theme.palette.common.black, 0.25),
    '&:hover': {
      //color: '#000',
      //backgroundColor: fade(theme.palette.common.white, .7),
      borderColor: "#E20074",
      border: 10,

    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    /*width: '50%',*/
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit * 3,
      /*width: '460px',*/
    },
  },
  searchIcon: {
    fill: '#ccc',
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    right: theme.spacing.unit * 2,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputRoot: {
    borderColor: '#E20074',
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    border: 'rgba(255, 206, 10, 0.5) 1px solid',
    borderRadius: '5px',
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  avatar: {
    margin: 0,
    width: 38,
    height: 38,
  },
});
let profileData
class PrimarySearchAppBar extends React.Component {

  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      profilePhoto: null,
      refreshPic: props.refreshPic
    };
  }

  componentDidMount() {

    const props = this.props;
    // console.log(props)
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          props.logIn();
          //  console.log(data)
          this.getUser(data.username)
          props.hideSplash()

          this.setState({ user: data });
          break;
        case "signOut":
          this.setState({ user: null });
          break;
      }
    });

    Auth.currentAuthenticatedUser({

    }).then(user => {
      props.logIn();
      this.getUser(user.username)

      // this.setState({ authState: 'signedIn' });
      //console.log(props.isLogged)
    }).catch(e => {
      console.log('header error')
      props.showSplash()
      props.logOut();
      //this.setState({ authState: 'signIn' });
    });
  }
  imageExists = (url, callback) => {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;

  }
  async getUser(userID) {

    const searchUsers = `query searchUsers($userID:String) {
        searchUsers(filter:{id:{matchPhrasePrefix:$userID}}) {
          items{
            id
             profilePhotoURL
             employeeType
              familyName
              givenName
              company
              department
              groupName
              groups
              jobTitle
              biography
              interests
              managerName
              managerEmail
              role
              name
              authors
              lastLogin
              email
          }
        }
      }`;

    const searchResult = await API.graphql(
      graphqlOperation(searchUsers, { "userID": userID })
    );

    let user = {}
    user = searchResult.data.searchUsers.items[0]


    var profileImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}${searchResult.data.searchUsers.items[0].profilePhotoURL}?${utils.generateID(12)}`
    this.imageExists(profileImageUrl, function(exists) {
      if (exists) { this.setState({ profilePhoto: profileImageUrl }) }
    }.bind(this));


    Cache.setItem('userInfo', user)
    console.log(user)
    //profileData = getUserProfile(user.id)
  }

  search = (keyword) => {
    this.props.history.push('Search?keyword=' + keyword)
  }

  flipOpen = () => this.setState({ ...this.state, open: !this.state.open });

  handleProfileMenuOpen = event => {
    this.flipOpen();
    this.setState({ anchorEl: event.currentTarget }, () => {
      //console.log(this.state.anchorEl, 'dealersOverallTotal1');
    });
  }
  handleMenuClose = event => {
    this.setState({ anchorEl: null }, () => {
      // console.log(this.state.anchorEl, 'dealersOverallTotal1');
    });
    this.flipOpen();
  }

  handleSearch = (e) => {

    if (e.key === 'Enter') {
      this.search(e.target.value)
      e.target.value = ''
      e.preventDefault();
    }
  }
  handleToggle = () => {
    console.log('toggle')
    console.log(this.props)
    this.props.toggleMenu();
  }

  render() {
    const { classes } = this.props;
    let isLogged = this.props.isLogged;
    let isMenuOpen = this.props.isMenuOpen;
    //const [anchorEl, setAnchorEl] = React.useState(null);
    //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);


    const logIn = () => {
      window.location.replace(process.env.REACT_APP_CALLBACK_URL);
    }
    const logOut = () => {
      Auth.signOut({ global: true })
        .then(data => console.log(data))
        .catch(err => console.log(err));
    }

    //console.log('isLogged ' + isLogged)
    if (!isLogged) {
      login_button = <MenuItem onClick={logIn}>Log In</MenuItem>;
    }
    else {
      login_button = <MenuItem onClick={logOut}>Log Out</MenuItem>;
    }

    const menuId = 'primary-search-account-menu';
    //console.log(this.state.anchorEl)
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.open}
        onClose={this.handleMenuClose}
      >
      {/*
      <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
      */}
      {login_button}
    </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
      anchorEl={this.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      //open={isMobileMenuOpen}
       onClose={this.handleMobileMenuClose}
    >
      {/*<MenuItem>
        <IconButton aria-label="Show 11 new notifications" color="inherit">
          <Badge badgeContent={1} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>*/}
      <MenuItem onClick={this.handleProfileMenuOpen}>
        <IconButton
          aria-label="Account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
    );

    return (
      <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleToggle}
          >
            <MenuIcon />
          </IconButton>
          <a href="/#">
            <img className={classes.logo} src={ logo } />
          </a>
          <div className={classes.grow} />

          <div className={classes.search}>
            <AutoComplete classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }} />
            {/*
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{background: 'none',}}
              inputProps={{ 'aria-label': 'Search' }}
              onKeyPress={this.handleSearch}
            />
          */}


          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/*<IconButton aria-label="Show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>*/}
            <IconButton
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >

            {this.state.profilePhoto!==null?

            <Avatar key={utils.generateID(6)} alt="" src= {this.state.profilePhoto} refresh={this.state.refreshPic} className={classes.avatar} />
            :
              <AccountCircle />
            }
            </IconButton>

          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
             // onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(PrimarySearchAppBar);

export default compose(
  withStyles(styles, {
    name: 'PrimarySearchAppBar',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(PrimarySearchAppBar));
