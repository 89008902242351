import React,{ useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as utils from '../functions/util.js';
import { withStyles, makeStyles  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Slide from '@material-ui/core/Slide';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import TagIcon from '@material-ui/icons/LocalOffer';
import TvIcon from '@material-ui/icons/Tv';
const style = {
  root:{
    flexGrow: 1,
    backgroundColor:'black',
    color:'white',
    //maxWidth: '854px',
    padding: '12px',
    paddingTop: '0px',
  },
  gridContainer:{
    marginTop: '12px',
  },
  videoDescription:{
    maxWidth: '854px',
  },
  videocontainer:{
    //width:'2000px',
  }
}

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    float: "left",
    marginRight: 5,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default withStyles(style)(function AlertDialog(props) {
    const classes = useStyles();
    
  const [open, setOpen] = React.useState(props.when);
  const [obj, setObj] = React.useState({
    thumbnail:"s",
    title:'',
    id:''
  });
   const [authorList, setAuthorList] = useState([]);
 

    useEffect(() => {

    
    // console.log(data)
    let author = []
     
     utils.listUser().then(function(result) {
        const getAuthor = result.data.searchUsers.items
        let obj
        author.length = 0;
        let num = 0;

        for (let value of getAuthor) {
          obj = {}
          num++;
          obj.label = value.givenName + ' ' + value.familyName
          obj.value = value.id
          obj.thumbnail = value.profilePhotoURL
          author.push(obj)
        }
        setAuthorList(author)
        console.log('id '+props.id)
        if(props.id!=null){
            if(props.id.length==16){
                utils.searchAudioByID(props.id).then(result => {
                      // console.log(result.data.searchAudios.items[0])
                        setObj(JSON.parse(result.data.searchForAudio))
                       
                      })
              .catch(err => console.log(err));
            }
            else if(props.id.length==12){
                utils.searchVideoByID (props.id).then(result => {
                       console.log(result)
                        setObj(result.data.searchVideos.items[0])
                       
                      }).catch(err => console.log(err));
            }
     }
      
    console.log(author)
      });
   
  }, [props.id]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
   const copyVideoID=(id)=>{
    navigator.clipboard.writeText(id)
   if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }
  const handleInfoClose = () => {
  //  setInfoOpen(false);
  };
  const handleWatch = (id) => {
    //props.history.push('/watch?v=' + id)
  }
  const getUserInfo=(id)=>{
   // console.log(id)
    let obj =  {}
    if(id!==undefined) obj = authorList.find(o => o.value==id);
    
    return(obj)
  }
  console.log(obj)
  if(props.id){
  return (
    <div>
       <Dialog
              open={props.when}
            
              keepMounted
              onClose={()=>props.setInfoClose()}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Properties"}</DialogTitle>
              <DialogContent>
              <div style={{minWidth:800}}>
                <DialogContentText id="alert-dialog-slide-description">
                <Grid container xs={12} sm={12}spacing={10}>
                    <Grid item xs={4}>
                      <div class="vcardThumb" onClick={handleWatch}>
                        <img src={obj.thumbnail} alt="video thumbnail" alt={obj.title}/>
                        <div class="videoDuration">{}</div>
                      </div>
                    </Grid>
                     <Grid item xs={6}>
                       <h6 >{obj.title} </h6>
                        <p>{obj.description} </p>
                        <p>Created at:{utils.formatDate(obj.createdAt)} </p>
                        <p>Schedule archived  :{utils.formatDate(obj.expirationDate)} </p>
                    </Grid>
                </Grid>
                 <Grid container xs={12} spacing={20}><p>ID: {obj.id} <FileCopyIcon onClick={()=>copyVideoID(obj.id)}/></p></Grid>
                 <hr/>
               {  getUserInfo(obj.primaryAuthor) !==undefined &&
                <Grid container xs={12} spacing={2}>
                   <Grid item xs={6} >
                   <h6>Primary Author</h6>
                   <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.primaryAuthor).thumbnail}`} />   {getUserInfo(obj.primaryAuthor).label}</span>
                   </Grid>
                   </Grid>
                   }
             
                {(obj.secondaryAuthor != 'undefined' && getUserInfo(obj.secondaryAuthor) !==undefined) &&
                 <Grid container xs={12} spacing={2}>
                  <Grid item xs={6} >
                   <h6>Secondary Author</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.secondaryAuthor).thumbnail}`} /> {getUserInfo(obj.secondaryAuthor).label}</span>
                   </Grid>
                   </Grid>
                }   
               
               
                
                  {(obj.writer !== undefined && obj.writer != "none" && getUserInfo(obj.writer)!==undefined) &&
                   <Grid container xs={12} spacing={2}>
                   <Grid item xs={4} space={2}>
                   <h6>Writer/Producer</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.writer).thumbnail}`} /> {getUserInfo(obj.writer).label}</span>
                   </Grid>
                   </Grid>
                  }
                   {(obj.creative !== undefined && obj.creative != 'none'  && getUserInfo(obj.creative)!==undefined) &&
                    <Grid item xs={4} space={2}>
                   <h6>Creative</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.creative).thumbnail}`} /> {getUserInfo(obj.creative).label}</span>
                   </Grid>
                   }
                  {obj.businessPartner !== undefined &&
                    <Grid item xs={4} space={2}>
                    <h6>Business Contact</h6>
                    <p>{obj.businessPartner}</p>
                    </Grid>
                   }
              
                 
                 <hr/>
                  {obj.audienceList!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><PersonIcon/></Grid>
                    <Grid item sm={10}>
                    <span><h6>Recommended audiences</h6>{obj.audienceList}</span>
                    </Grid>
                  </Grid>
                  }
                  
                   {obj.groupsCanAccess!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><GroupIcon/></Grid>
                    <Grid item sm={10}>
                    <span><h6>Access groups</h6>{obj.groupsCanAccess}</span>
                    </Grid>
                  </Grid>
                  }
                   {obj.keywords!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><TagIcon/></Grid>
                    <Grid item sm={10}>
                    <span><h6>Tags</h6>{obj.keywords}</span>
                    </Grid>
                  </Grid>
                  }
                
                  
                
                </DialogContentText>
                 </div>
              </DialogContent>
            
            </Dialog>
    </div>
  );
  }
  else return null;
})

