import * as React from "react";

function SvgCollection(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} viewBox="0 0 32 32"  {...props}>
      <path id="Path_303" data-name="Path 303" d="M12.6,15.8v9.6H3V15.8h9.6m-2.4,2.4H5.4V23h4.8V18.2M13.8,2l6.6,10.8H7.2L13.8,2m0,4.632L11.5,10.4H16.1L13.8,6.632M20.4,15.2A5.4,5.4,0,1,1,15,20.6a5.378,5.378,0,0,1,5.4-5.4m0,2.4a3,3,0,1,0,3,3A3,3,0,0,0,20.4,17.6Z" transform="translate(-3 -2)" fill="#ccc"/>
    </svg>
  );
}

export default SvgCollection;