import * as React from "react";

function SvgData(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
    <path id="Path_3" data-name="Path 3" d="M20,13.09V7c0-2.21-3.58-4-8-4S4,4.79,4,7V17c0,2.21,3.59,4,8,4a9.649,9.649,0,0,0,1.33-.06A5.941,5.941,0,0,1,13,19v-.05A6.5,6.5,0,0,1,12,19c-3.87,0-6-1.5-6-2V14.77A14.032,14.032,0,0,0,12,16a16.38,16.38,0,0,0,1.88-.11A5.986,5.986,0,0,1,19,13a6.638,6.638,0,0,1,1,.09m-2-.64A10.786,10.786,0,0,1,12,14a10.786,10.786,0,0,1-6-1.55V9.64A12.555,12.555,0,0,0,12,11a12.555,12.555,0,0,0,6-1.36v2.81M12,9C8.13,9,6,7.5,6,7s2.13-2,6-2,6,1.5,6,2-2.13,2-6,2M22,20l-3,3-3-3h2V16h2v4Z" transform="translate(-4 -3)"  fill="#ccc" />
  </svg>
  );
}

export default SvgData;