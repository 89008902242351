import * as React from "react";

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 42.55 37">
    <path id="Path_423" data-name="Path 423" d="M8.4,20.5a3.7,3.7,0,1,1-3.7-3.7,3.687,3.687,0,0,1,3.7,3.7M6.55,2V13.1H2.85V2h3.7M2.85,39V27.9h3.7V39H2.85M43.55,9.4V31.6a3.687,3.687,0,0,1-3.7,3.7H17.65a3.7,3.7,0,0,1-3.7-3.7V24.2l-3.7-3.7,3.7-3.7V9.4a3.7,3.7,0,0,1,3.7-3.7h22.2a3.687,3.687,0,0,1,3.7,3.7m-3.7,0H17.65v8.935L15.486,20.5l2.164,2.164V31.6h22.2V9.4m-18.5,5.55h14.8v3.7H21.35v-3.7m0,7.4h11.1v3.7H21.35Z" transform="translate(-1 -2)" fill="#de1173"/>
  </svg>
  );
}

export default Svg;
