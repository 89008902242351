import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as utils from '../functions/util.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
//import ClipIcon from '/images/icons/clips_icon.svg';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropsIcon from '../images/iconJS/Props.js';
//import AppBar from '@material-ui/core/AppBar';
//import Tabs from '@material-ui/core/Tabs';
//import Tab from '@material-ui/core/Tab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DialogContentText from '@material-ui/core/DialogContentText';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import TagIcon from '@material-ui/icons/LocalOffer';
import PlayerKeywords from './videoPlayer/PlayerKeywords.js';

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';
const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
const serverType = server[2].split('.')

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    float: "left",
    marginRight: 5,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  descHTML: {
    '& a': {
      // jss-plugin-nested applies this to a child span
      fontWeight: 'bold', // jss-plugin-camel-case turns this into 'font-weight',
      textDecoration: 'underline'
    },
    '& a:hover': {
      color: '#E20074'
    }
  }
}));

function SimpleTabs(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.when);
  const [obj, setObj] = React.useState({
    thumbnail: "s",
    title: '',
    id: ''
  });
  const [authorList, setAuthorList] = useState([]);


  useEffect(() => {
    let author = []

    utils.listChannelAuthors().then(function(result) {
      const getAuthor = result.data.SearchForUsers.items
      let obj
      author.length = 0;
      let num = 0;

      for (let value of getAuthor) {
        obj = {}
        num++;
        obj.label = value.givenName + ' ' + value.familyName
        obj.value = value.id
        obj.thumbnail = value.profilePhotoURL
        author.push(obj)
      }
      setAuthorList(author)
      console.log('id ' + props.id)
      if (props.id != null) {
        if (props.id.length == 16) {
          utils.searchAudioByID(props.id).then(result => {
              //console.log(result.data.searchAudios.items[0])
              let tmp = JSON.parse(result.data.searchForAudio)
              tmp.description = tmp.description.replace(/<a /g, "<a target=_blank ");
                while(utils.testDecode( obj.description)){
            try {
              obj.description =decodeURIComponent( obj.description);
              } 
              catch (ex) {
                
                obj.descriptionc = obj.description
              }
             }
             // console.log(tmp)
              const obj = setObj(tmp)
              //console.log(obj)
              // setObj(obj)

            })
            .catch(err => console.log(err));
        }
        else if (props.id.length == 12 || props.id.length == 13) {
          utils.searchVideoByID2(props.id).then(result => {

            let obj = JSON.parse(result.data.searchForAVideo)
            //console.log(obj)
            obj.description = obj.description.replace(/<a /g, "<a target=_blank ");
             while(utils.testDecode( obj.description)){
            try {
              obj.description =decodeURIComponent( obj.description);
              } 
              catch (ex) {
                
                obj.descriptionc = obj.description
              }
             }
            const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
            const extension = obj.thumbnail.split('.')
            const fitThumbURL = `https://${server[2]}/fit-in/220x124/public/vco/${obj.id}/poster.${extension[extension.length-1]}`
            // console.log(fitThumbURL)
            obj.thumbnail = fitThumbURL;
            if (obj !== undefined) setObj(obj)

          }).catch(err => console.log(err));
        }
      }

      console.log(author)
    });

  }, [props.id]);




  const handleWatch = () => {
    console.log(props)
    props.router.push('/watch?v=' + props.id)
  }
  const handleSearch = () => {
    console.log(props)
    props.router.push('/watch?v=' + props.id)
  }
  const getUserInfo = (id) => {
    // console.log(id)
    let obj = {}
    if (id !== undefined) obj = authorList.find(o => o.value == id);
    return (obj)
  }

  const copyVideoLink = () => {
    navigator.clipboard.writeText(props.id)
    if (isChrome || isFirefox) navigator.clipboard.writeText((props.id))
    else if (isIE) {
      
      navigator.clipboard.writeText(props.id)

      window.clipboardData.setData('Text', props.id);
      document.execCommand('copy');
    }

  }


  return (
    <div>
             
                <DialogContentText id="alert-dialog-slide-description">
                <Grid container spacing={2} >
                    <Grid item xs >
                      <div class="" style={{cursor:'pointer'}} onClick={handleWatch}>
                        <img src={obj.thumbnail} alt="video thumbnail" alt={obj.title}/>
                        <div class="videoDuration">{}</div>
                      </div>
                    </Grid>
                     <Grid item xs>
                       <h6 >{obj.title} </h6>
                        <p className={classes.descHTML}> <span dangerouslySetInnerHTML={{__html: obj.description,}} />     </p>
                        <p>Created at:{utils.formatDate(obj.createdAt)} </p>
                      {obj.expirationDate!=null &&  <p>Schedule archived  :{utils.formatDate(obj.expirationDate)} </p>}
                    </Grid>
                </Grid>
                 <Grid container xs={12} spacing={20}><p>ID: {obj.id} <FileCopyIcon style={{cursor:'pointer'}}onClick={copyVideoLink}/></p></Grid>
               
                  <Grid container xs={12} spacing={2}>
               {  getUserInfo(obj.primaryAuthor) !==undefined &&
               
                   <Grid item  xs>
                   <h6>Primary Author</h6>
                   <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.primaryAuthor).thumbnail}`} />   {getUserInfo(obj.primaryAuthor).label}</span>
                   </Grid>
                   
                   }
             
                {(obj.secondaryAuthor != 'undefined' && getUserInfo(obj.secondaryAuthor) !==undefined) &&
                
                  <Grid item xs>
                   <h6>Secondary Author</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.secondaryAuthor).thumbnail}`} /> {getUserInfo(obj.secondaryAuthor).label}</span>
                   </Grid>
                  
                }   
                </Grid>
               
                 <Grid container xs >
                  {(obj.writer !== undefined && obj.writer != "none" && getUserInfo(obj.writer)!==undefined) &&
                  
                   <Grid item xs>
                   <h6>Writer/Producer</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.writer).thumbnail}`} /> {getUserInfo(obj.writer).label}</span>
                   </Grid>
                  
                  }
                   {(obj.creative !== undefined && obj.creative != 'none'  && getUserInfo(obj.creative)!==undefined) &&
                    <Grid item xs>
                   <h6>Creative</h6>
                     <span > <Avatar className={classes.small} src= {`${process.env.REACT_APP_CLOUDFRONT_URL}${getUserInfo(obj.creative).thumbnail}`} /> {getUserInfo(obj.creative).label}</span>
                   </Grid>
                   }
                  {(obj.businessPartner !== undefined &&  obj.businessPartner!=null) &&
                    <Grid item xs>
                    <h6>Business Contact</h6>
                    <p>{obj.businessPartner}</p>
                    </Grid>
                   }
               </Grid>
                 
                 <hr/>
                  {obj.audienceList!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><PersonIcon/></Grid>
                    <Grid item sm={10}>
                    <div style={{marginBottom:10}}><h6  style={{marginBottom:-5}}>Recommended audiences</h6>
                    {obj.audienceList.map(obj=>
                    <li>
                     {obj}
                    </li>
                    )}
                    </div>
                    </Grid>
                  </Grid>
                  }
                  
                   {obj.groupsCanAccess!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><GroupIcon/></Grid>
                    <Grid item sm={10}>
                    <div style={{marginBottom:10}}><h6 style={{marginBottom:-5}}>Access groups</h6>
                        {obj.groupsCanAccess.map(obj=>
                          <li>
                           {obj}
                          </li>
                          )}
                    </div>
                    </Grid>
                  </Grid>
                  }
                   {obj.keywords!==undefined &&
                  <Grid container sm={12}>
                    <Grid item sm={1}><TagIcon/></Grid>
                    <Grid item sm={10}>
                     <div style={{marginBottom:10}}><h6 style={{marginBottom:-5}}>Tags</h6>
                    <PlayerKeywords data={obj.keywords}/>
                    </div>
                    </Grid>
                  </Grid>
                  }
                </DialogContentText>
             
             
    </div>
  );
}




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#E20074',
    color: theme.palette.common.white,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {

  const [open, setOpen] = React.useState(props.open)
  const [toggle, setToggle] = React.useState(false);
  let id = props.id

  const handleClose = () => {
    setOpen(false);
    props.close()
  };

  const handleOpen = () => {
    setOpen(true);

  };

  useEffect(() => {
    console.log(props.open)
    setOpen(props.open)
  }, [props.open]);


  return (
    <div >
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} style={{color: "white",backgroundColor:"black"}}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         <PropsIcon/>Properties
        </DialogTitle>
        <DialogContent dividers>
              <SimpleTabs id={id} router={props.router}/>
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}
