import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as utils from '../functions/util.js';
import { withStyles } from '@material-ui/core/styles';

const style = {
  root:{
    flexGrow: 1,
    backgroundColor:'black',
    color:'white',
    //maxWidth: '854px',
    padding: '12px',
    paddingTop: '0px',
  },
  gridContainer:{
    marginTop: '12px',
  },
  videoDescription:{
    maxWidth: '854px',
  },
  videocontainer:{
    //width:'2000px',
  }
}


export default withStyles(style)(function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.when);
  const { classes } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNo = () => {
    props.onCancel()
    setOpen(false);
  };
  const handleYes = () => {
    props.onConfirm()
    utils.deleteFolderFromS3(props.folderID)
    setOpen(false);
  };

  return (
    <div>
    
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">{`You have an unsaved ${props.type}, are you sure you want to leave?`}</DialogTitle>
        <DialogContent>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} 
            variant="contained"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.margin}>
             No, finish creating the {props.type}.
          </Button>
          <Button onClick={handleYes} 
            variant="contained"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.margin}
             >
           Yes, discard the {props.type}.
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})

