import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Wrap from '../functions/Wrap.js';

import UserProfileCard from '../parts/UserProfileCard'
import UserPageNavigator from '../parts/UserPageNavigator'
import Footer from '../parts/Footer.js';

function UserSubscriptions(props) {
  const { classes } = props;
  return (
      <div style={{
        display:'flex', 
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        alignItems: 'flex-start',
        maxWidth: '1150px',
        width: '100%',
        padding: '8px',
        minHeight: '100%',
      }}>
        <UserProfileCard/>
        <UserPageNavigator tab='Subscriptions'/>
       
      </div>
  );
}

export default UserSubscriptions;
