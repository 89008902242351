import React from 'react';
import ThumbUp from '@material-ui/icons/ThumbUp';
import * as utils from '../../functions/util.js';
import { withStyles } from '@material-ui/core/styles';
import defaultImage from '../../images/icons/deleted.jpg'
import ReactImageFallback from "react-image-fallback";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Favorite = () =>
  <svg 
    class="history-svg-icon"
    viewBox="0 0 24 24">
      <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
  </svg>;

const Like = () =>
  <svg 
    class="history-svg-icon"
    viewBox="0 0 24 24">
      <path d="M5,9V21H1V9H5M9,21A2,2 0 0,1 7,19V9C7,8.45 7.22,7.95 7.59,7.59L14.17,1L15.23,2.06C15.5,2.33 15.67,2.7 15.67,3.11L15.64,3.43L14.69,8H21C22.11,8 23,8.9 23,10V12C23,12.26 22.95,12.5 22.86,12.73L19.84,19.78C19.54,20.5 18.83,21 18,21H9M9,19H18.03L21,12V10H12.21L13.34,4.68L9,9.03V19Z" />
  </svg>;

const styles = {
  card: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
 
};

const HistoryItem = (props) => {
 // console.log(props)
 const { classes } = props;
  const handleClick = () => {
    props.redirect('/watch?v=' + props.id)
  }
  return (
    <ul class="history-row" >
          <li>
           <LazyLoadImage src={props.thumbnail} alt="video thumbnail" alt={props.title} onClick={handleClick} className = {classes.card} placeholderSrc={defaultImage}/>
             {/*
           <ReactImageFallback src={props.thumbnail} fallbackImage={defaultImage} alt={props.title} onClick={handleClick} className = {classes.card}/>
            
          <img src={props.thumbnail} alt={props.title} onClick={handleClick} ref={img => this.img = img} onError={() => this.img.src={defaultImage}}/>*/}
          </li>
          <li onClick={handleClick} className = {classes.card}>
            {props.title}
          </li>
          <li>
            {props.date}
          </li>
          <li>
           {props.like ? <Like/> : ''}
           {props.favorite ? <Favorite/> : ''}
          </li>
        </ul>
  )
}

export default withStyles(styles)(HistoryItem);
