/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth, Storage } from 'aws-amplify';
import './editPlaylist.css';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { DropzoneArea } from 'material-ui-dropzone'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as utils from '../functions/util.js';
import TransferList from '../parts/transferList.js'
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import SnackMessage from '../parts/SnackMessage';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VideoCard from '../parts/videoCard/Card.js';
import Age from '../functions/Age.js';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import queryString from 'query-string'
import getUserProfile from '../functions/getUserProfile.js';
import {SortableContainer, SortableElement, sortableHandle,} from 'react-sortable-hoc';
//import arrayMove from 'array-move';

const styles = {
  form: {
    margin: 30,
    color: '#E20074'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
      color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
   // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 400,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
const CssTextField = withStyles({
  root: {

    '& label': {
      color: '#E20074',

    },
    '& label.Mui-focused': {
      color: '#E20074',
    },
    '& .MuiInput-underline:before': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiInput-underline:after': {
      //borderBottom:'5px solid #E20074'
      borderBottomColor: '#E20074',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#E20074',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E20074',
      },
    },
   
  },
  input: {
    color: '#fff',
  },
  OutlinedInput: {
    mulitiline: {
      height: 142,
    }
  }
})(TextField);

const author = [];


function editPlaylist(props) {
  // need to update "activity = {video.activityDate} or what ever once available.
  
  const [Videos, setVideos] = useState([]);
  const { classes } = props;

  let data, init=true, thisID='', thisPlaylist, renderNum =0;
  const [finishLoading, setFinish] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openSnack, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [channelExist, setChannelExist] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [videos, setFoundVideos] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [videoID, setVideo] = React.useState('');
  const [instuction , setInstructionText]= React.useState("Upload your video assets");
  const [pageTitle , setPageTitle]= React.useState("Create Playlist");
  const [values, setValues] = React.useState({
    id: '',
    title: '',
    description: "",
    videos:[],
    audios:[]
  });
   let aArray=[], vArray=[]
  
  const initFunc = ()=>{
  
     renderNum++
     setEditMode(true)
     setChannelExist(true)
     setPageTitle('Edit Playlist');
     setInstructionText('')
     utils.searchPlaylistbyID(thisID).then(function(result) {
       console.log(result)
       let thisPlaylist= result.data.searchPlaylists.items[0]
        vArray = thisPlaylist.videos.items
        aArray = thisPlaylist.audios.items
        
        vArray = vArray.concat(aArray);
       // utils.reorderVideo (vArray, thisPlaylist.videoArray,'videoId'||'audioID')
      
            /*
        if( thisPlaylist.videoArray ){
             vArray = utils.reorderVideo (thisPlaylist.videos.items, thisPlaylist.videoArray,'videoId')
            }
          
         if( thisPlaylist.audioArray ){
              aArray = utils.reorderVideo (thisPlaylist.audios.items, thisPlaylist.audioArray,'audioId')
            }
            console.log(vArray)
            console.log(aArray)
     setFullPlaylist(vArray.concat(aArray))
        console.log(fullPlaylist)
        */
        console.log(vArray)
        setValues({ ...values, 
                      id: thisPlaylist.id,  
                      title: thisPlaylist.title,  
                      description: decodeURIComponent(thisPlaylist.description),  
                      videos:   utils.reorderVideo (vArray, thisPlaylist.videoArray,'videoId'||'audioID'),  
                      audios: thisPlaylist.audios.items,
                      videoArray: thisPlaylist.videoArray,
                     
            }); 
     });
  }
  useEffect(() => {
    if(!finishLoading) {
      const thisquery = queryString.parse(props.location.search)
      thisID = thisquery.p
      if(thisID) initFunc()
      else{
        thisID = utils.generateID(10)
        setValues({ ...values,id: thisID});
        setFinish(true)
      }
    }
 
    if(renderNum==1) setFinish(true)
  }, [values]);
 
  const handleChange = name => event => {
    let val
    name == 'includeInClipsSearch' || name == 'allowDownload' ? val = event.target.checked :val =  event.target.value
    setValues({ ...values, [name]: val });
    console.log(name + " : " + val)
    console.log(values)
  };
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenError(false);
  }

const handleModalClose = () => {
    setModalOpen(false);
  };
const handleKeyPress = (e)=>{
   setVideo(e.target.value)
}
const handleSearch = () => {
   if(videoID.length == 12 || videoID.length==13 ){
        utils.searchVideoByID(videoID).then(function(result) {
          console.log(result)
          let VideosArray = [];
          let playlistItem = result.data.searchVideos.items
            
          for (var value of playlistItem) {
            var obj = {};
            obj.id = value.id;
            obj.video_title = value.title;
            obj.video_desc = decodeURIComponent(value.description);
            obj.createdAt = value.createdAt;
            obj.videoDuration = value.videoDuration;
            obj.poster_image_loc = value.thumbnail
            obj.allowTrainerDownload = value.allowTrainerDownload
            VideosArray.push(obj)
          }
          setFoundVideos(VideosArray)
          setVideo('');
           if(VideosArray.length==0){ setErrorMsg('Video not found') ; setOpenError(true) }
        });
   }
   else if(videoID.length==16){
      utils.searchAudioByID(videoID).then(function(result) {
          console.log(result)
          let audios = JSON.parse(result.data.searchForAudio)
          console.log(audios)
          let VideosArray = [];
          let playlistItem = audios
            
       
            var obj = {};
            obj.id = audios.id;
            obj.video_title = audios.title;
            obj.video_desc = decodeURIComponent(audios.description);
            obj.createdAt = audios.createdAt;
            obj.videoDuration = audios.audioDuration;
            obj.poster_image_loc = audios.thumbnail
            obj.allowTrainerDownload = audios.allowTrainerDownload
            VideosArray.push(obj)
          
          setFoundVideos(VideosArray)
          setVideo('');
           if(VideosArray.length==0){ setErrorMsg('Audio not found') ; setOpenError(true) }
        });
   }
  }
  const arrayMoveMutate = (array, from, to) => {
  	array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };
  
  const arrayMove = (array, from, to) => {
  	array = array.slice();
  	arrayMoveMutate(array, from, to);
  	return array;
  };

 const addVideoToPlaylist = () =>{
     console.log(values.videos)
    console.log(videos[0].id)
    if(values.videos.findIndex(x => (x.audioId||x.videoId) === videos[0].id) === -1){
      console.log(videos)
      let newObj = videos[0];
      console.log(newObj)
      if(!videos.videoId){
          newObj ={
          allowTrainerDownload: videos[0].allowTrainerDownload,
          videoId: videos[0].id,
          title: videos[0].video_title,
          description: videos[0].video_desc,
          thumbnail:videos[0].poster_image_loc,
          videoDuration: videos[0].videoDuration,
        }
        
      }
      setValues({ ...values, videos: values.videos.concat(newObj) }); //add
      // setFullPlaylist(fullPlaylist.concat(newObj))
      console.log(videos[0].id)
      utils.addVideoToPlaylist(videos[0], values.id)
    
      setFoundVideos([]) //reset 
      handleModalClose(); //close
    }
    else{
         setErrorMsg('This media already exist in playlist') ; setOpenError(true) 
    }
  }
  const addAudioToPlaylist = () =>{
     console.log(values.videos)
    
    if(values.videos.findIndex(x => (x.audioId||x.videoId) === videos[0].id) === -1){
      console.log(videos)
      let newObj = videos[0];
      if(!videos.videoId){
          newObj ={
            allowTrainerDownload: videos[0].allowTrainerDownload,
          videoId: videos[0].id,
          title: videos[0].video_title,
          description: videos[0].video_desc,
          thumbnail:videos[0].poster_image_loc,
          videoDuration: videos[0].videoDuration,
        }
        
      }
      setValues({ ...values, audios: values.audios.concat(newObj) }); //add
     setValues({ ...values, videos: values.videos.concat(newObj) }); //add
      console.log(videos[0].id)
       utils.addAudioToPlaylist(videos[0], values.id)
       
      setFoundVideos([]) //reset 
      handleModalClose(); //close
    }
    else{
         setErrorMsg('This media already exist in playlist') ; setOpenError(true) 
    }
  }
  
 const removeVideoFromPlaylist = (id) =>{
    //const id = e.target.id 
    console.log(id)
    console.log(values)
     setValues({ ...values, videos: values.videos.filter(video => (video.audioId||video.videoId) != id) }); //remove
    if(id.length<14){
    //  setValues({ ...values, videos: values.videos.filter(video => video.videoId != id) }); //remove
      utils.removeVideoFromPlaylist(id, values.id)
    }
    else{
     
      utils.removeAudioFromPlaylist(id, values.id)
    }
  }
  const getVideo=(video)=> {
        return <Grid container direction='row' alignItems="center" > 
                <VideoCard 
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                
                    id = {video.id}
                  />
                   <Fab color="secondary" aria-label="add" onClick={video.id.length<14?addVideoToPlaylist:addAudioToPlaylist} className={classes.fab}>
                        <AddIcon />
                  </Fab>
              </Grid>
  }
  
 const onSortEnd = ({oldIndex, newIndex}) => {
   console.log(oldIndex, newIndex)
   	let tmp =arrayMove(values.videos, oldIndex, newIndex)
   	console.log(tmp)
    setValues({ ...values, videos: tmp }); 
    
  };

const SortableItem = SortableElement((props) => 
        <ul class="history-row">
       
          <li>
          <Typography variant='h6'>{props.thisIndex + 1}</Typography>
          </li>
          <li>
           <img src={props.img}/>
          </li>
          <li >
            {props.value}
          </li>
      
          <li>
           <RemoveIcon onClick={()=>removeVideoFromPlaylist(props.id)}/>
          </li>
        </ul>
/*
 <div class="videoCard">
    <Grid container direction='row' sm={12} justify="center" alignItems="center">
      <Grid item sm={1}><Typography variant='h4'>{props.thisIndex + 1}</Typography></Grid>
        <Grid item sm={11}>
              <div class="thumbWrapper" >
                <img src={props.img} class="thumbnailImg2" alt={props.value}/>
              </div>
              <div class="videoCardMetadata">
               <Grid container> <div class="thumbnailTitle ">{props.value}</div>  <RemoveIcon onClick={()=>removeVideoFromPlaylist(props.id)}/>  </Grid>
              </div>
        </Grid>
      </Grid>
  </div>
  */
);

const SortableList = SortableContainer(({items}) => {
  //console.log(items)
 if(items.length>0){
  return (
    <ul>
      {items.map((value, index) => (
      <div>
       
        <SortableItem key={`item-${value.id}`} index={index} value={value.title} img={value.thumbnail} id={value.videoId||value.audioId} thisIndex={index}></SortableItem>
      </div>
      ))}
    </ul>
  );
 }
 else return null
});

const getVideoAdded=(video)=> {
   console.log(video)
        return <Grid container direction='row' alignItems="center" > 
                
                <VideoCard 
                    image = {video.poster_image_loc ||video.thumbnail}
                    title = {video.video_title||video.title}
                 
                    id = {video.videoId}
                  />
                  
                   <Fab color="secondary" aria-label="add"  id={video.videoId} onClick={removeVideoFromPlaylist(video.videoId)} className={classes.fab}>
                        <RemoveIcon id={video.videoId}/>
                  </Fab>
                  
              </Grid>
   
  }

   
   const handleSave=()=>{
     if(!channelExist){ //create channel
        if(values.title==''|| values.description==''){
          setErrorMsg('Oops, please make sure all the fields are filled out') ;
          setOpenError(true)
        }
        else{
         utils.createPlaylist(values).then(value => {
             setOpen(true); //if save succesfully, then show message
             setModalOpen(true) 
             setChannelExist(true)
          }, reason => {
           
           console.log(reason)
          });
        }
     }
     else{ //add video to playlist
        //let videoArray =values.videos.map(x => `"${x.videoId}"` );
        //let videoArray =values.videos.map(x => (`"${x.audioId}"`||`"${x.videoId}"`) );

       // let videoArray =values.videos.map(x => (`"${x.audioId}"`||`"${x.videoId}"`) );
         let videoArray =values.videos.map(x => x.hasOwnProperty('audioId')?`"${x.audioId}"`:`"${x.videoId}"` );

        //videoArray = videoArray.forEach(element => element.replace('"',''));
            console.log(values.videos)
            console.log(videoArray)
         utils.updatePlaylist(values,videoArray ).then(value => {
            props.history.push('/authorPlaylist')
          }, reason => {
            //setErrorMsg('Oops, please make sure all the fields are filled out') ;
           
          });
     }
   }
   
if(finishLoading){
    return (
      <div className={classes.form} style={{maxWidth: '1024px', margin: '12px',}} > 
     <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={10000}
            onClose={handleClose}
          >
         <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="success"
            message={"Your new playlist have been created. (" + values.id  +")" }
          />
      </Snackbar>
      <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
      <SnackMessage
            //action= {action}
            onClose={handleClose}
            variant="warning"
            message={errorMsg}
          />
      </Snackbar>
  
      
      <Typography variant="h4" gutterBottom>
       {pageTitle}
      </Typography>
       <Typography variant="p" gutterBottom>{instuction}</Typography>
      
      <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
          <CssTextField
            required
            id="country"
             fullWidth
            label="Playlist Title"
           
            value={values.title}
            onChange={handleChange('title')}
             margin="normal"
             variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="p" gutterBottom>Playlist Description*</Typography>
          <TextareaAutosize required className={classes.descBox} aria-label="minimum height" rows={5} value ={values.description} onChange={handleChange('description')} placeholder="" />
        </Grid>
       </Grid>
        <Grid item xs={6} sm={8}>
       
          <SortableList   distance={3} items={values.videos} onSortEnd={onSortEnd} />
         
        </Grid>
       
       <Grid item xs={12} sm={12} justify="flex-end" >
           <Button
              variant="contained"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.margin}
              onClick= {()=>handleSave()}
            >
              <Save className={classes.extendedIcon} />
              Save and Continue
            </Button>
             <Button
              variant="contained"
              size="medium"
              color="secondary"
              disabled={!channelExist}
              aria-label="add"
              className={classes.margin}
              onClick= {()=>setModalOpen(true) }
            >
            <AddIcon />
              Add Media
            </Button>
        </Grid>
        
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={modalOpen}>
              <div className={classes.paper} >
                <h2 id="transition-modal-title">Add video or audio to playlist</h2>
                <Grid xs={12} container direction='row' spacing={10}>
                <Grid item xs={12} sm={12}>
                     <CssTextField
                        id="filled-business-partner-email"
                        fullWidth
                        label="Find media by ID"
                        value={videoID}
                        onChange={handleKeyPress}
                          margin="normal"
                         
                           variant="outlined">
                      </CssTextField>
                   
                         <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            className={classes.margin}
                            disabled={!(videoID.length==12||videoID.length==13||videoID.length==16)}
                            onClick= {handleSearch}
                          >Find</Button>
                </Grid>
                <Grid item xs={6} sm={8}>
                     {videos.map(video => getVideo(video))}
                </Grid>
                </Grid>
              </div>
            </Fade>
          </Modal>
      
    </div>


    )
  }
  else return null;
}

export default withStyles(styles)(editPlaylist);
