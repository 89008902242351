import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import Grid from '@material-ui/core/Grid';

import ShareIcon from '@material-ui/icons/Share';

function Share(props) {
  const { classes } = props;
  return (
      <Grid container alignItems="center" style={{flexWrap: 'nowrap', marginLeft: '1em'}}>
        <ShareIcon/><span style={{marginLeft: '0.5em'}}>Share</span>
      </Grid>
  );
}

export default Share;
