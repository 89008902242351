import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import  VideoThumb from './VideoThumb'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',

  },
  gridList: {
    width: 240,
  },
  yellowDivider:{
    backgroundColor:'#E20074',
    height: '2px',
    display:'flex',
    minWidth:'100%'
  },
  title:{
    color:'#fff'
  }

});

const playlist = [
  
]

function VerticalPlaylist(props) {
  const { classes } = props;
  const isCondensed = (props.type == "condensed");
  let vidPerCol = 4
  if(isCondensed ) vidPerCol = 6;
  return (

    <div className={classes.root}>


      <GridList className={classes.gridList}   cols={1}>
          {playlist.map(p =>
            <GridListTile >
              <VideoThumb image ={p.poster_image_loc}
                          title ={p.video_title}
                          isSmall = {!isCondensed}
                          />

            </GridListTile>
            )}

      </GridList>
      <div className={classes.yellowDivider}></div>
    </div>

  );

}

VerticalPlaylist.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VerticalPlaylist);
